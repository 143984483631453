import { markRaw, ref } from 'vue';
import { defineStore } from 'pinia';

import type { ModalOptions } from 'learning/common/store-modules/modal.types.ts';
import type { ComponentProps } from 'sharedApp/types.ts';
import type { Component } from 'vue';

export default defineStore('modal', () => {
  const component = ref<Component | null>(null);
  const props = ref<ComponentProps>({});
  const options = ref<Partial<ModalOptions>>({});
  const displayingModal = ref(false);

  const setModal = (
    modalComponentInstance: Component,
    modalComponentProperties: ComponentProps,
    modalComponentOptions: Partial<ModalOptions>,
  ) => {
    component.value = markRaw(modalComponentInstance);
    props.value = modalComponentProperties;
    options.value = modalComponentOptions;
    displayingModal.value = true;
  };

  const removeModal = (params: Record<string, unknown> = {}) => {
    if ('onModalClose' in props.value && props.value.onModalClose instanceof Function) {
      props.value.onModalClose(params);
    }
    displayingModal.value = false;
    component.value = null;
    options.value = {};
    props.value = {};
  };

  return {
    component,
    props,
    options,
    displayingModal,
    setModal,
    removeModal,
  };
});
