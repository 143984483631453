import axios from 'sharedApp/vue-utils/kog-axios.ts';

const notificationsUrl = '/api/study/notifications/';
const notificationsSeenUrl = '/api/study/notifications/seen/';

export async function fetchNotifications() {
  const response = await axios.get(notificationsUrl);
  return response.data;
}

export async function markNotificationsAsSeen(ids) {
  const response = await axios.post(notificationsSeenUrl, { ids });
  return response.data;
}
