import * as Sentry from '@sentry/vue';

import type { AxiosError } from 'axios';

import {
  API_ERROR_EVENT_NAME,
  ApiErrorEvent,
} from 'sharedApp/vue-utils/interceptors/api-error-dispatcher.ts';

export function registerApiErrorHandler() {
  function handleApiErrorEvent(e: Event) {
    if (!(e instanceof ApiErrorEvent)) return;
    const error = e.detail as AxiosError<unknown & { detail: string }>;
    const messagesRequiringPageReload = ['Authentication credentials were not provided.'];

    switch (error.response?.status) {
      case 401:
        if (messagesRequiringPageReload.includes(error.response.data?.detail)) {
          window.location.reload();
        } else {
          Sentry.captureMessage(
            `401 Unauthorized error detected: ${JSON.stringify(error.response.data)}`,
          );
        }
        break;
      default:
        break;
    }
  }

  document.addEventListener(API_ERROR_EVENT_NAME, handleApiErrorEvent);

  const unregisterApiErrorHandler = () => {
    document.removeEventListener(API_ERROR_EVENT_NAME, handleApiErrorEvent);
  };

  return unregisterApiErrorHandler;
}
