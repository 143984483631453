import * as Sentry from '@sentry/vue';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const EVENTS_URL = '/api/events/';

const trackEvent = async (name, properties) => {
  try {
    return await axios.post(EVENTS_URL, {
      event_name: name,
      properties,
    });
  } catch (error) {
    Sentry.captureException(error);
    return 'Failed to track event';
  }
};

export { trackEvent };
