// eslint-disable-next-line import/no-named-default
import { default as mixpanelModule } from 'sharedApp/libs/mixpanel.js';

const MixpanelPlugin = {
  install(app, options) {
    if (options) {
      mixpanelModule.init(options.authToken, options.userConstants);
    }

    const mixpanel = {
      trackEventViaBackend: mixpanelModule.trackEventViaBackend,
      trackEvent: mixpanelModule.trackEvent,
      trackLinks: mixpanelModule.trackLinks,
      registerDefaultPropertiesForEvents: mixpanelModule.registerDefaultPropertiesForEvents,
      setupUserProperties: mixpanelModule.setupPeopleTracking,
      incrementCounterProperty: mixpanelModule.incrementCounterProperty,
      registerCounterProperty: mixpanelModule.registerCounterProperty,
      init: mixpanelModule.init,
      resetDefaultPropertiesForEvents: mixpanelModule.resetDefaultPropertiesForEvents,
    };

    /* Plugin Composition API */
    app.provide('mixpanel', mixpanel);

    /* Plugin Options API */
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$mixpanel = mixpanel;
  },
};

export default MixpanelPlugin;
