if (!('randomUUID' in crypto)) {
  crypto.randomUUID = function randomUUID() {
    // borrowed from https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid#8809472
    let d = new Date().getTime(); // eslint-disable-line unicorn/prefer-date-now
    let d2 =
      (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
    // eslint-disable-next-line func-names
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;
      if (d > 0) {
        // Use timestamp until depleted
        r = (d + r) % 16 | 0; // eslint-disable-line no-bitwise
        d = Math.floor(d / 16);
      } else {
        // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0; // eslint-disable-line no-bitwise
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16); // eslint-disable-line no-bitwise
    });
  };
}
