export const CLASSIC_PAGE = 'classic-login-page';
export const LOGIN_METHOD_PASSWORD = 'Password';
export const LOGIN_METHOD_GOOGLE = 'Google SSO';
export const LOGIN_METHOD_MANAGEBAC = 'Managebac SSO';
export const LOGIN_METHOD_MANAGEBAC_CHINA = 'Managebac China SSO';
export default class ClassicLoginLocalStorage {
  #loginPageKey;

  #classicLoginPage;

  constructor(storage) {
    this.storage = storage;

    this.#loginPageKey = 'loginPage';
    this.#classicLoginPage = CLASSIC_PAGE;
  }

  setFlag(loginMethod) {
    this.storage.setItem(
      this.#loginPageKey,
      JSON.stringify({ login_method: loginMethod || LOGIN_METHOD_PASSWORD, page: CLASSIC_PAGE }),
    );
  }

  classicLoginDetails() {
    const data = JSON.parse(this.storage.getItem(this.#loginPageKey));
    if (data?.page === CLASSIC_PAGE) {
      return data;
    }

    return null;
  }

  removeFlag() {
    this.storage.removeItem(this.#loginPageKey);
  }
}

export const sendClassicLoginTracking = mixpanel => {
  const classicLoginLocalStorage = new ClassicLoginLocalStorage(localStorage);
  const classicLoginTrackingDetails = classicLoginLocalStorage.classicLoginDetails();
  if (classicLoginTrackingDetails) {
    mixpanel.trackEvent('Login from page', classicLoginTrackingDetails);
    classicLoginLocalStorage.removeFlag();
  }
};
