import KogPulsingDot from 'learning/common/components/kog-pulsing-dot.vue';

import mountComponent from 'sharedApp/utils/mount-component.ts';

function getClickListener(dot, key) {
  return () => {
    dot.classList.add('visibilityHidden');
    localStorage.setItem(key, true);
  };
}

function doesDotExists(dotId) {
  return Boolean(document.getElementById(dotId));
}

const dots = new Map();

function createDot(el, binding) {
  const props = { ...binding.value };
  if (!props.key || ('isHidden' in props && props.isHidden)) {
    return;
  }
  if (!localStorage.getItem(props.key) && !doesDotExists(props.key)) {
    const { el: dotContainer, destroy } = mountComponent({
      component: KogPulsingDot,
      props: {
        dotId: props.key,
        tooltipText: props.tooltipText,
      },
      elementCssClasses: ['positionRelative'],
    });

    dots.set(props.key, { destroy });

    if (props.horizontalOffset) {
      dotContainer.style.cssText += `left: ${props.horizontalOffset}px`;
    }
    if (props.verticalOffset) {
      dotContainer.style.cssText += `top: ${props.verticalOffset}px`;
    }
    if (props.clickListeners) {
      props.clickListeners.forEach(selector => {
        const clickables = document.querySelectorAll(selector) || [];
        clickables.forEach(listenerEl => {
          listenerEl.addEventListener('click', getClickListener(dotContainer, props.key));
        });
      });
    }

    el.insertBefore(dotContainer, el.firstChild);
    el.addEventListener('click', getClickListener(dotContainer, props.key));
  }
}

function removeDot(el, binding) {
  const props = { ...binding.value };
  if (!props.key || ('isHidden' in props && props.isHidden)) {
    return;
  }
  if (localStorage.getItem(props.key) && doesDotExists(props.key)) {
    dots.get(props.key).destroy();
  }
}

// KogNewDot takes an object as property with the following fields:
//    * key: an id that identifies the dot uniquely for localStorage and for click handling
//    * offset: number of pixels the dot should be offset horizontally, can be positive or negative
//    * clickListeners: an array of strings that correspond to css selectors, the directive will add
//        an click listener to each element that matches each one of the selectors.
//
// The click listener will be shown once per browser session, it uses the localStorage to store the
// visibilityInformation, right now it does not distinguish between users.
//
export default {
  updated: createDot,
  mounted: createDot,
  beforeUnmount: removeDot,
};
