import { isEmpty } from 'lodash';

import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';

const { getDateAsDateObject } = require('sharedApp/utils/time-utils.js');

export const ASSIGNMENT_TYPES = {
  QUESTION: 'QUESTION',
  READING: 'READING',
  EXAMSTYLE: 'EXAMSTYLE',
};

export const PRACTICE_OCCASION_TYPES = {
  ASSIGNMENT: 'ASSIGNMENT',
  EXERCISE: 'EXERCISE',
  REFLECTION: 'REFLECTION',
  SECTION_CHECKPOINT: 'SECTION_CHECKPOINT',
  STRENGTH_BATTLE: 'STRENGTH_BATTLE',
  STRENGTH_TEST: 'STRENGTH_TEST',
};

const ICON_MAP = {
  [ASSIGNMENT_TYPES.READING]: {
    iconClass: 'fa-bookmark',
    tooltip: 'Book assignment',
    shortLabel: 'Book',
    style: 'regular',
    isSVG: false,
  },
  [ASSIGNMENT_TYPES.QUESTION]: {
    iconClass: 'fa-pencil-alt',
    tooltip: 'Question assignment',
    shortLabel: 'Question',
    style: 'regular',
    isSVG: false,
  },
  [ASSIGNMENT_TYPES.EXAMSTYLE]: {
    iconClass: 'LTQ',
    tooltip: 'Exam-style question assignment',
    shortLabel: 'Exam-style',
    style: 'regular',
    isSVG: true,
  },
};

export function isReadingAssignmentOnTime(nodeProgress, assignment) {
  if (!nodeProgress || !nodeProgress.first_completed_at || !assignment) {
    return false;
  }
  const deadlineDate = new Date(assignment.deadline);
  const completionDate = new Date(nodeProgress.first_completed_at);
  return completionDate <= deadlineDate;
}

export function isReadingAssignmentLate(nodeProgress, assignment) {
  return !isReadingAssignmentOnTime(nodeProgress, assignment) && nodeProgress.first_completed_at;
}
export function isReadingAssignmentCompleted(nodeProgress) {
  return !!nodeProgress?.first_completed_at;
}

export function isReadingAssignmentDone(studentProgress) {
  if (!studentProgress || isEmpty(studentProgress)) {
    return false;
  }
  return Object.values(studentProgress).every(nodeProgress =>
    isReadingAssignmentCompleted(nodeProgress),
  );
}

export function hasAssignmentEnded(assignment) {
  const deadlineDate = new Date(assignment.deadline);
  return Date.now() > deadlineDate.getTime();
}

export function isQuestionType(assignmentType) {
  return assignmentType === ASSIGNMENT_TYPES.QUESTION;
}

export function isReadingType(assignmentType) {
  return assignmentType === ASSIGNMENT_TYPES.READING;
}

export function isExamStyleType(assignmentType) {
  return assignmentType === ASSIGNMENT_TYPES.EXAMSTYLE;
}

export function isExamStyleAssignmentDone(assignment) {
  const esqOccasionStatus = assignment?.status;
  return esqOccasionStatus === 'COMPLETED';
}

export function getIconDetails(assignmentType) {
  return ICON_MAP[assignmentType] || ICON_MAP[ASSIGNMENT_TYPES.EXAMSTYLE];
}

export function getAssignmentTypeDisplayName(assignmentType, educationSystem) {
  switch (assignmentType) {
    case ASSIGNMENT_TYPES.READING:
      return 'Book assignment';
    case ASSIGNMENT_TYPES.QUESTION:
      return 'Question assignment';
    case ASSIGNMENT_TYPES.EXAMSTYLE:
      return `${getTerm('exam-style', educationSystem)} assignment`;
    default:
      return 'Assignment';
  }
}

export function getTakeAssignmentRoute({
  assignment,
  subjectId: sid,
  classId: cid,
  classSlug,
  source = '',
}) {
  const { assignment_type: type, id: assignmentId } = assignment;
  const params = {
    classSlug,
    sid,
    cid,
    assignmentId,
  };

  return {
    [ASSIGNMENT_TYPES.READING]: {
      name: 'classReadingAssignmentTake',
      params,
      query: { source },
    },
    [ASSIGNMENT_TYPES.QUESTION]: {
      name: 'classQuestionAssignmentTake',
      params,
      query: { source },
    },
    [ASSIGNMENT_TYPES.EXAMSTYLE]: {
      name: 'classEsqAssignmentOverview',
      params,
      query: { source },
    },
  }[type];
}

export const getQuestionAssignmentResultRoute = ({
  assignment,
  subjectId: sid,
  classId: cid,
  classSlug,
}) => ({
  name: 'classQuestionAssignmentResult',
  params: {
    classSlug,
    sid,
    cid,
    assignmentId: assignment.id,
  },
  query: { source: 'assignment_list' },
});

export const getTeacherAssignmentsRoute = ({
  assignment,
  classSlug,
  subjectId: sid,
  classId: cid,
  source = '',
}) => ({
  name: 'teacherAssignmentDetails',
  params: {
    classSlug,
    sid,
    cid,
    assignmentId: assignment.id,
  },
  query: { source },
});

export function formatTopicNumber(number) {
  return number.split('.').slice(0, 2).join('.');
}

export function getQuestionSubtopics(question) {
  const subTopicsSet = new Set();
  question.subjectnode_mappings.forEach(map => {
    subTopicsSet.add(formatTopicNumber(map.number_including_ancestors));
  });
  return Array.from(subTopicsSet);
}

export function formatAssignmentSentMessage(googleClasses) {
  let message = 'Assignment sent!';
  if (!googleClasses || googleClasses.length === 0) {
    return message;
  }

  const numClasses = googleClasses.length;
  message += ' It was also sent to';
  for (let i = 0; i < numClasses; i += 1) {
    const className = googleClasses[i].name;
    message += ` <b>${className}</b>`;
    if (i === numClasses - 1) {
      message += '.';
    } else if (i === numClasses - 2) {
      message += ' and';
    } else {
      message += ',';
    }
  }

  return message;
}

export function isNumberPaperType(paperType) {
  return /^\d+$/.test(paperType);
}

export function getAssignmentDetailsTrackingProps(
  assignment,
  numOfStudents,
  numberOfStudentsCompleted,
  source,
) {
  const now = new Date();
  const deadline = getDateAsDateObject(assignment.deadline);
  const state = deadline > now ? 'ongoing' : 'past';
  return {
    assignment_type: assignment.assignment_type.toLowerCase(),
    assignment_state: state,
    num_subject_classes: assignment.subject_classes.length,
    num_students: numOfStudents,
    num_students_completed: numberOfStudentsCompleted,
    source: source || 'not_declared',
  };
}
