import axios from 'sharedApp/vue-utils/kog-axios.ts';

export const mixpanelUrl = '/api/mixpanel/track/';

export async function trackMixpanelEventViaBackend(
  event: string,
  properties: Record<string, unknown>,
) {
  const data = {
    event,
    properties,
  };
  await axios.post(mixpanelUrl, data);
}
