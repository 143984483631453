import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const schoolstaffApiBaseUrl = '/api/schoolstaff/';
const subtopicStatisticsBaseUrl = `${schoolstaffApiBaseUrl}subject/:subjectId/subtopic/:subtopicId/`;

const SCHOOLSTAFF_API_URLS = {
  'subtopic-statistics': compile(`${subtopicStatisticsBaseUrl}statistics/`),
  'students-subtopic-progress': compile(`${subtopicStatisticsBaseUrl}students_subtopic_progress/`),
};

export async function fetchSubtopicStatistics(subjectId, subtopicId) {
  const path = SCHOOLSTAFF_API_URLS['subtopic-statistics']({ subjectId, subtopicId });
  const response = await axios.get(path, { cache: true });
  return response.data;
}

export async function fetchStudentsSubtopicProgress(subjectId, subtopicId) {
  const path = SCHOOLSTAFF_API_URLS['students-subtopic-progress']({ subjectId, subtopicId });
  const response = await axios.get(path, { cache: true });
  return response.data;
}
