<template>
  <i
    :class="iconClass"
    :style="iconStyle"
  >
    <span
      v-if="ariaLabel"
      class="screenreader-only"
    >
      {{ ariaLabel }}
    </span>
  </i>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
export default {
  name: 'KogFontAwesomeIcon',
  props: {
    faClass: {
      type: String,
      required: true,
    },
    faStyle: {
      type: String,
      default: 'solid',
      validator: prop => ['solid', 'regular', 'light'].includes(prop),
    },
    size: {
      type: String,
      default: 'm',
      validator: prop => ['xs', 's', 'm', 'l', 'xl', 'm-touch', 's-touch', 'custom'].includes(prop),
    },
    customSize: {
      type: Number,
      default: 0,
    },
    ariaLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconClass() {
      const styleMap = {
        solid: 'fas',
        regular: 'far',
        light: 'fal',
      };
      return `${styleMap[this.faStyle]} ${this.faClass} KogFontAwesomeIcon--${this.size}`;
    },
    iconStyle() {
      if (this.size === 'custom') {
        return `font-size: ${this.customSize}px`;
      }
      return '';
    },
  },
};
</script>

<style scoped>
.KogFontAwesomeIcon--xs {
  font-size: 10px;
}

.KogFontAwesomeIcon--s {
  font-size: 14px;
}

.KogFontAwesomeIcon--m {
  font-size: 20px;
}

.KogFontAwesomeIcon--s-touch {
  font-size: 14px;
}

.KogFontAwesomeIcon--m-touch {
  font-size: 20px;
}

.KogFontAwesomeIcon--l {
  font-size: 28px;
}

.KogFontAwesomeIcon--xl {
  font-size: 38px;
}
</style>
