import 'sharedApp/polyfills.js';

import * as Sentry from '@sentry/vue';

import { fetchActivity, fetchAggregatedActivity } from 'learning/common/api/activity.js';

const namespaced = true;

const mutations = {
  setAggregatedActivity(state, aggregatedActivityObj) {
    state.aggregatedActivity = aggregatedActivityObj;
  },
  setActivity(state, activity) {
    state.activity = activity;
  },
};

const actions = {
  async fetchAggregatedActivity({ commit }) {
    try {
      const data = await fetchAggregatedActivity();
      commit('setAggregatedActivity', data);
    } catch (error) {
      Sentry.captureMessage(`fetchAggregatedActivity: ${error}`);
      throw error;
    }
  },

  async fetchActivity({ commit }) {
    try {
      const data = await fetchActivity();
      commit('setActivity', data);
    } catch (error) {
      Sentry.captureMessage(`fetchActivity: ${error}`);
    }
  },
};

const state = {
  aggregatedActivity: {
    current_day_streak: 0,
    best_day_streak: 0,
    number_unique_days: 0,
  },
  activity: [],
};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
