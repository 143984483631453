import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

export const API_PATHS = {
  'student-annotations-list': compile('/api/study/subject_node/:subjectNodeId/annotations/'),
  'student-annotations-details': compile(
    '/api/study/subject_node/:subjectNodeId/annotations/:annotationId/',
  ),
  'student-subject-annotations-count': compile('/api/study/subjects/:subjectId/annotations/count/'),
};

export const fetchAnnotations = async subjectNodeId => {
  const url = API_PATHS['student-annotations-list']({ subjectNodeId });
  const response = await axios.get(url);
  return response.data;
};

export const createAnnotation = async (subjectNodeId, payload) => {
  const url = API_PATHS['student-annotations-list']({ subjectNodeId });
  const response = await axios.post(url, payload);
  return response.data;
};

export const updateAnnotation = async (subjectNodeId, annotationId, payload) => {
  const url = API_PATHS['student-annotations-details']({ subjectNodeId, annotationId });
  const response = await axios.put(url, payload);
  return response.data;
};

export const deleteAnnotation = async (subjectNodeId, annotationId) => {
  const url = API_PATHS['student-annotations-details']({ subjectNodeId, annotationId });
  await axios.delete(url);
};

export const fetchAnnotationsCount = async subjectId => {
  const url = API_PATHS['student-subject-annotations-count']({ subjectId });
  const response = await axios.get(url);
  return response.data;
};
