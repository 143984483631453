/**
 * https://floating-vue.starpad.dev/guide/themes
 */
const themes = {
  'kog-tooltip': {
    $extend: 'tooltip',
    arrowPadding: 5,
    popperTriggers: ['hover'],
    delay: {
      show: 300,
      hide: 300,
    },
    html: true,
  },
  'kog-dropdown': {
    $extend: 'dropdown',
    distance: 0,
    disposeTimeout: 0,
  },
  'kog-popover': {
    $extend: 'dropdown',
  },
  'kog-promotional-popover': {
    $extend: 'popover',
    delay: {
      show: 300,
    },
  },
  'kog-promotional-popover-s': {
    $extend: 'kog-promotional-popover',
    delay: {
      show: 300,
    },
  },
  'kog-promotional-popover-m': {
    $extend: 'kog-promotional-popover',
    delay: {
      show: 300,
    },
  },
  'kog-promotional-popover-l': {
    $extend: 'kog-promotional-popover',
    delay: {
      show: 300,
    },
  },
} as const;

export default themes;
