import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const subjectsBaseUrl = '/api/subjects/:subjectId/';
const API_URLS = {
  'subject-total-sections-completed': compile(
    `${subjectsBaseUrl}users/:userId/sections_completed/`,
  ),
  'subject-user-section-activity': compile(`${subjectsBaseUrl}users/:userId/section-activity/`),
  'subject-user-last-activity': compile(`${subjectsBaseUrl}users/:studentUserId/last-activity/`),
  'subject-reflection-question-nodes': compile(`${subjectsBaseUrl}reflection-question-nodes/`),
};
const SCHOOLSTAFF_API_URLS = {
  'subject-details': compile('/api/schoolstaff/staff/subject/:subjectId/'),
  'subject-list': '/api/schoolstaff/subjects/',
};
const CONTENT_API_URLS = {
  'node-detail': compile('/api/content/subjectnodes/:subjectNodeId/'),
  'section-detailed-list': compile('/api/content/subjectnodes/:subjectNodeId/detailed_sections/'),
};

export async function fetchSubjectDetails(subjectId, useCache = false) {
  const url = SCHOOLSTAFF_API_URLS['subject-details']({ subjectId });
  const result = await axios.get(url, { cache: useCache });
  return result.data;
}

export async function fetchSubjectList() {
  const url = SCHOOLSTAFF_API_URLS['subject-list'];
  const result = await axios.get(url, { cache: true });
  return result.data;
}

export async function fetchSubjectNode(subjectNodeId) {
  const url = CONTENT_API_URLS['node-detail']({ subjectNodeId });
  const result = await axios.get(url);
  return result.data;
}

export async function fetchDetailedSectionList(subjectNodeId) {
  const url = CONTENT_API_URLS['section-detailed-list']({ subjectNodeId });
  const result = await axios.get(url);
  return result.data;
}

export async function fetchSubjectReflectionSections(subjectId) {
  const url = API_URLS['subject-reflection-question-nodes']({ subjectId });
  const result = await axios.get(url, {
    cache: true,
  });
  return result.data;
}

export async function fetchSubjectUserLastActivity(subjectId, studentUserId) {
  const url = API_URLS['subject-user-last-activity']({ subjectId, studentUserId });
  const result = await axios.get(url);
  return result.data;
}

export async function fetchSubjectUserSectionActivity({ subjectId, userId, params }) {
  const url = API_URLS['subject-user-section-activity']({ subjectId, userId });
  const response = await axios.get(url, { params });
  return response.data;
}

export async function fetchSubjectSectionsCompleted({ subjectId, userId }) {
  const url = API_URLS['subject-total-sections-completed']({ subjectId, userId });
  const response = await axios.get(url);
  return response.data;
}
