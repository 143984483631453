import * as Sentry from '@sentry/vue';

import { fetchSubjectClass } from '@apis/subject-class.js';

const namespaced = true;

const mutations = {
  setSubjectClass(state, subjectClassObj) {
    state.subjectClass = subjectClassObj;
  },
  setStudentsForSubjectClass(state, students) {
    state.students = students;
  },
};

const actions = {
  async fetchSubjectClass({ commit }, { subjectClassId, useCache = true }) {
    try {
      const response = await fetchSubjectClass(subjectClassId, useCache);
      commit('setSubjectClass', response);
    } catch (error) {
      Sentry.captureMessage(`fetchSubjectClass: ${error}`);
    }
  },
};

const state = {
  subjectClass: null,
  students: [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
};
