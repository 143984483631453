import isNil from 'lodash/isNil.js';
import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

export const API_PATHS = {
  'node-progress-practice-item-list': compile(
    '/api/study/subjectnode/:subjectNodeId/practice_items/',
  ),
  'subject-last-section-opened': compile('/api/study/subjects/:subjectId/last_section_opened/'),
  'subject-sections': compile('/api/study/:sectionUrl/sections/'),
  subject: compile('/api/study/:subjectSlug/'),
};
const apiBaseUrl = '/api/subjects/';

export async function fetchSubject(subjectSlug, excludeHiddenNodesForSubjectClassId = undefined) {
  const path = API_PATHS.subject({ subjectSlug });
  const params = !isNil(excludeHiddenNodesForSubjectClassId)
    ? { 'exclude-hidden-nodes-for-subject-class-id': excludeHiddenNodesForSubjectClassId }
    : {};
  const response = await axios.get(path, { params });
  return response.data;
}

export async function fetchSubjectDetails(id) {
  const response = await axios.get(`${apiBaseUrl}${id}/`);
  return response.data;
}

export async function fetchAllSubjects() {
  const response = await axios.get(apiBaseUrl, {
    params: {
      all: 'True',
    },
  });
  return response.data;
}

export async function fetchSubjectList(subjectIds = []) {
  const params = {
    all: 'True',
    is_depricated: false,
    is_active: true,
  };
  if (subjectIds.length > 0) {
    params.id__in = subjectIds.join(',');
  }
  const response = await axios.get(apiBaseUrl, { params });
  return response.data;
}

export async function fetchLastSectionOpened(subjectId) {
  const path = API_PATHS['subject-last-section-opened']({ subjectId });
  const response = await axios.get(path);
  return response.data;
}

export async function fetchSectionNodes(subjectSlug, topicSlug, subtopicSlug, sectionSlug) {
  const sectionPath = sectionSlug ? `/${sectionSlug}` : '';
  const sectionUrl = `${subjectSlug}/${topicSlug}/${subtopicSlug}${sectionPath}`;
  const path = API_PATHS['subject-sections']({ sectionUrl });
  const response = await axios.get(path);
  return response.data;
}

export async function fetchSectionQuestionPracticeItems(subjectNodeId) {
  const path = API_PATHS['node-progress-practice-item-list']({ subjectNodeId });
  const response = await axios.get(path);
  return response.data;
}

export async function addAnswer(subjectNodeId, practiceItemId, questionId, answer) {
  const path = API_PATHS['node-progress-practice-item-list']({ subjectNodeId });
  const response = await axios.post(path, [
    { id: practiceItemId, question: questionId, user_answer: answer },
  ]);
  return response.data;
}
