import { updateSubjectClassStudent } from '@apis/students';

const setClassStudentLevel = (schoolId, subjectClassId, studentId, levelId) => {
  const data = {
    level: {
      id: levelId,
    },
  };

  return updateSubjectClassStudent(schoolId, subjectClassId, studentId, data);
};

export default setClassStudentLevel;
