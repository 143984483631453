import { readonly } from 'vue';

import type { AssignmentDetails } from '@apis/generated';

import { DisplayAnswersSettingEnumEnum } from '@apis/generated';

export default function useAssignmentReadableSettings(assignment: AssignmentDetails) {
  return readonly({
    deadline: {
      isUpcoming: assignment.has_deadline_passed === false,
      hasPassed: assignment.has_deadline_passed === true,
      allowLateSubmission: assignment.soft_deadline,
    },
    answers: {
      displayAfterDeadline:
        assignment.display_answers_setting === DisplayAnswersSettingEnumEnum.AFTER_DEADLINE,
      displayAfterSubmission:
        assignment.display_answers_setting === DisplayAnswersSettingEnumEnum.AFTER_SUBMITTING,
      displayImmediately:
        assignment.display_answers_setting === DisplayAnswersSettingEnumEnum.IMMEDIATELY,
    },
  });
}
