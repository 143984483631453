const mutations = {
  setStudentsList(state, studentsList) {
    state.studentsList = studentsList;
  },
  setStudentDetails(state, studentDetails) {
    state.studentDetails = studentDetails;
  },
  setStudentLevel(state, studentLevel) {
    state.studentLevel = studentLevel;
  },
  setStudentLastActivity(state, { userId, lastActivity }) {
    state.studentLastActivity[userId] = lastActivity;
  },
  setQuestionProgress(state, questionProgress) {
    state.questionProgress = questionProgress;
  },
  setQuestionTabParams(state, params) {
    state.questionTabParams = params;
  },
  setTextbookTabParams(state, params) {
    state.textbookTabParams = params;
  },
  setSubjectTree(state, subjectTree) {
    state.subjectTree = subjectTree;
  },
  setSubjectNodeMap(state, subjectNodeMap) {
    state.subjectNodeMap = subjectNodeMap;
  },
  setEducationSystem(state, educationSystem) {
    state.educationSystem = educationSystem;
  },
  setSubjectClass(state, subjectClass) {
    state.subjectClass = subjectClass;
  },
  setPracticeItems(state, practiceItems) {
    state.practiceItems = practiceItems;
  },
  setBookProgress(state, bookProgress) {
    state.bookProgress = bookProgress;
  },
  setCurrentNodeSectionQuestions(state, sectionQuestions) {
    state.currentNodeSectionQuestions = sectionQuestions;
  },
  setSectionQuestionPracticeItems(state, practiceItems) {
    state.sectionPracticeItems = practiceItems;
  },
  setReflectionQuestionProgress(state, reflectionQuestionPracticeItems) {
    state.reflectionQuestionPracticeItems = reflectionQuestionPracticeItems;
  },
  setUseReflectionQuestionProgressCache(state, useCache) {
    state.useReflectionQuestionProgressCache = useCache;
  },
  setSubjectReflectionSections(state, subjectReflectionSections) {
    state.subjectReflectionSections = subjectReflectionSections;
  },
  setStudentLastOpenSections(state, { userId, lastOpenSections }) {
    state.studentLastOpenSections[userId] = lastOpenSections;
  },
  setStudentSections(state, sectionsData) {
    state.studentSections = sectionsData.results;
    state.studentSectionsPagination = {
      currentPage: sectionsData.page,
      pageSize: sectionsData.page_size,
      totalItems: sectionsData.count,
    };
  },
  setStudentSectionsCompleted(state, sectionsCompleted) {
    state.studentSectionsCompleted = sectionsCompleted;
  },
  setStudentLatestAssignments(state, { userId, latestAssignments }) {
    state.studentLatestAssignments[userId] = latestAssignments;
  },
  setStudentAssignments(state, assignmentsData) {
    state.studentAssignments = assignmentsData.results;
    state.studentCompletedAssignmentsCount = assignmentsData.completed_assignments_count;
    state.studentAssignmentsPagination = {
      currentPage: assignmentsData.page,
      pageSize: assignmentsData.page_size,
      totalItems: assignmentsData.count,
    };
  },
  setStudentBattlesCount(state, studentBattlesCount) {
    state.studentBattlesCount = studentBattlesCount;
  },
  setStudentBattles(state, studentBattlesData) {
    state.studentBattles = studentBattlesData.results;
    state.studentBattlesPagination = {
      currentPage: studentBattlesData.page,
      pageSize: studentBattlesData.page_size,
      totalItems: studentBattlesData.count,
    };
  },
  setStudentStrengthTestsCount(state, studentStrengthTestsCount) {
    state.studentStrengthTestsCount = studentStrengthTestsCount;
  },
  setStudentStrengthTests(state, studentStrengthTestsData) {
    state.studentStrengthTests = studentStrengthTestsData.results;
    state.studentStrengthTestsPagination = {
      currentPage: studentStrengthTestsData.page,
      pageSize: studentStrengthTestsData.page_size,
      totalItems: studentStrengthTestsData.count,
    };
  },
};

export default mutations;
