import initHelpHero from 'helphero';

const APP_ID = window.KOG?.DJANGO_SETTINGS?.HELP_HERO.APP_ID;

function createHelpHeroInstance(appId) {
  if (appId) {
    return initHelpHero(appId);
  }
  return new Proxy(
    {},
    {
      get: () => () => {},
    },
  );
}

export default createHelpHeroInstance(APP_ID);
