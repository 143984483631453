/* global MathJax:false */

function waitForMathJax(retryTime = 0) {
  return new Promise(resolve => {
    if (
      window.MathJax &&
      window.MathJax.typesetPromise &&
      typeof window.MathJax.typesetPromise === 'function' &&
      window.MathJax.typesetClear &&
      typeof window.MathJax.typesetClear === 'function'
    ) {
      resolve();
    } else {
      setTimeout(() => {
        waitForMathJax(50).then(resolve);
      }, retryTime);
    }
  });
}

async function cleanUpMathJax(el) {
  await waitForMathJax();
  window.MathJax.typesetClear([el]);
}

async function addMathJaxTo(el, binding) {
  const config = binding.value || {};
  const callback = config.doneCallback || (() => {});

  await waitForMathJax();

  window.MathJax.typesetPromise([el]).then(() => {
    callback();
  });
}

export default {
  beforeMount: addMathJaxTo,
  beforeUpdate: cleanUpMathJax,
  updated: addMathJaxTo,
  beforeUnmount: cleanUpMathJax,
};
