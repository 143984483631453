import type { AxiosResponse } from 'axios';

export const BACKEND_VERSION_LABEL_EVENT_NAME = 'backendversionlabel';

export class BackendVersionLabelEvent extends CustomEvent<string> {
  constructor(version: string) {
    super(BACKEND_VERSION_LABEL_EVENT_NAME, {
      detail: version,
    });
  }
}

export function dispatchBackendVersionLabelInterceptor(response: AxiosResponse): AxiosResponse {
  const VERSION_HEADER_NAME = 'kog-version-label';
  const backendVersionLabel = response.headers[VERSION_HEADER_NAME];

  if (backendVersionLabel && typeof backendVersionLabel === 'string') {
    const event = new BackendVersionLabelEvent(backendVersionLabel);
    document.dispatchEvent(event);
  }

  return response;
}
