import { trackEvent } from 'sharedApp/apis/event.js';

const EventPlugin = {
  install(app) {
    let user;
    let eventQueue = [];
    const eventSystem = {
      track: (name, properties) => {
        if (!user) {
          eventQueue.push({ name, properties });
        }
        trackEvent(name, properties);
      },
      handleQueuedEvents() {
        eventQueue.forEach(event => {
          this.track(event.name, event.properties);
        });
        eventQueue = [];
      },
      setUser(currentUser) {
        user = currentUser;
        this.handleQueuedEvents();
      },
    };

    /* Plugin Composition API */
    app.provide('event', eventSystem);

    /* Plugin Options API */
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$event = eventSystem;
  },
};

export default EventPlugin;
