import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import type { ActionEnum } from '@apis/generated';
import type { Component } from 'vue';
import type { LocationQueryValue } from 'vue-router';

import { ActionEnumEnum, OnboardingService } from '@apis/generated';

import { initHelpHero } from 'learning/common/services/helphero/help-hero-service.js';

import useModal from 'sharedApp/composables/use-modal.ts';
import OnboardingModal from 'studyApp/components/onboarding-modal/onboarding-modal.vue';

type ModalActionHandler = {
  type: 'modal';
  component: Component;
};

type TourActionHandler = {
  type: 'tour';
  tourId: string;
};

type ActionHandler = ModalActionHandler | TourActionHandler;

const actionHandlers: Record<ActionEnum, ActionHandler> = {
  [ActionEnumEnum.WELCOME_VIDEO]: {
    type: 'modal',
    component: OnboardingModal,
  },
  [ActionEnumEnum.ASSIGNMENT_TOUR]: {
    type: 'tour',
    tourId: 'jv9cLat6zD',
  },
  [ActionEnumEnum.BOOK_TOUR]: {
    type: 'tour',
    tourId: 'TRcKFSFo6I',
  },
};

const isActionEnum = (value?: LocationQueryValue): value is ActionEnum => {
  return Object.values(ActionEnumEnum).includes(value as ActionEnum);
};

export default function useOnboardingTours() {
  const route = useRoute();
  const store = useStore();
  const { openModal } = useModal();
  const userId = computed(() => store.state.userModule.user?.id);

  watch(
    () => [route.query.onboardingAction, userId.value],
    ([action, newUserId]) => {
      if (!newUserId) {
        return;
      }

      const onboardingAction = Array.isArray(action) ? action[0] : action;

      if (!isActionEnum(onboardingAction)) {
        return;
      }
      const actionHandler = actionHandlers[onboardingAction];

      if (actionHandler.type === 'modal') {
        openModal(actionHandler.component, {}, { closeOnClickOutside: false });
      } else if (actionHandler.type === 'tour') {
        const helpHero = initHelpHero(newUserId, {
          role: 'teacher',
        }) as { startTour: (tourId: string) => void };
        helpHero.startTour(actionHandler.tourId);
      }
      OnboardingService.onboardingActionCreate({
        requestBody: {
          action: onboardingAction,
        },
      });
    },
    { immediate: true },
  );
}
