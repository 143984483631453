import { getTerm } from 'sharedApp/services/educationSystem/education-system-service.js';

const EducationSystemTermPlugin = {
  install(app) {
    let educationSystem;

    // eslint-disable-next-line no-underscore-dangle
    function _term(key) {
      const term = getTerm(key, educationSystem);
      if (!term) {
        // eslint-disable-next-line no-console
        console.warn(`Cannot find term for '${key}'`);
        return key;
      }
      return term;
    }

    _term.setEducationSystem = edSystem => {
      educationSystem = edSystem;
    };

    /* Plugin Composition API */
    app.provide('term', _term);

    /* Plugin Options API */
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$term = _term;
  },
};

export default EducationSystemTermPlugin;
