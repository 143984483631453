import * as Sentry from '@sentry/vue';
import keyBy from 'lodash/keyBy.js';

import * as questionsApi from '@apis/questions.js';

export default {
  namespaced: true,
  state: {
    questions: [],
    reflectionQuestions: [],
  },
  getters: {
    questionsById(state) {
      return keyBy(state.questions, 'id');
    },
    getQuestionById(state, getters) {
      return questionId => getters.questionsById[questionId];
    },
  },
  mutations: {
    setQuestions(state, questions) {
      state.questions = questions;
    },
    setReflectionQuestions(state, reflectionQuestions) {
      state.reflectionQuestions = reflectionQuestions;
    },
  },
  actions: {
    async fetchQuestions({ commit }, { subjectNodeIds, questionIds, params = {} }) {
      const questions = await questionsApi.getQuestionsById(subjectNodeIds, questionIds, {
        all: 'True',
        ...params,
      });
      commit('setQuestions', questions);
    },
    async fetchReflectionQuestions({ commit }, subjectNodeId) {
      try {
        const reflectionQuestions = await questionsApi.fetchReflectionQuestions(subjectNodeId);
        commit('setReflectionQuestions', reflectionQuestions);
      } catch (error) {
        Sentry.captureMessage(`fetchReflectionQuestions: ${error}`);
      }
    },
    clearReflectionQuestions({ commit }) {
      commit('setReflectionQuestions', []);
    },
  },
};
