import * as Sentry from '@sentry/vue';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

import AsyncFetchStatus from './async-status.js';

const service = {
  /**
   * Calls the provided @axiosOperation and polls it using the returned task_id,
   * until the operation is reported completed
   *
   * @param method A string representing the axios api to call
   * @param url The url of the api call
   * @param data The data param of the api method (in case of POST)
   * @param config The config param of the api method
   * @param pollingIntervalMs Time to wait between polling the function for its result
   * @param maxPollCount Maximum polling iterations
   * @param timeoutMessage Custom message to log to Sentry if the underlying task did not
   * complete within the specified time
   * @param progressCallback A function used for providing progress (if it exists) and the
   * current status, as well as any returned data (in case it completes successfuly)
   */
  async callAsyncOp(
    { method, url, data, config, pollingIntervalMs, maxPollCount, timeoutMessage },
    progressCallback,
  ) {
    const axiosConfig = {
      ...config,
      params: {
        ...config?.params,
      },
    };
    let pollCount = 0;

    for (; pollCount < maxPollCount; pollCount += 1) {
      // eslint-disable-next-line no-await-in-loop
      const result = await this.callApi(method, url, data, axiosConfig);

      if (result.status === 202) {
        progressCallback({
          status: AsyncFetchStatus.pending,
        });
        axiosConfig.params.task_id = result.data.task_id;
      } else {
        progressCallback({
          status: AsyncFetchStatus.done,
          result,
        });
        break;
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => setTimeout(resolve, pollingIntervalMs));
    }

    if (pollCount === maxPollCount) {
      progressCallback({
        status: AsyncFetchStatus.timedout,
      });
      const sentryMessage =
        timeoutMessage || `Async operation at ${url} did not complete within the expected time`;
      Sentry.captureException({
        message: sentryMessage,
        taskId: axiosConfig.params.task_id,
      });
    }
  },
  callApi(method, url, data, axiosConfig) {
    let axiosOperation;
    if (method === 'get') {
      axiosOperation = axios.get.bind(null, url, axiosConfig);
    } else {
      axiosOperation = axios.post.bind(null, url, data, axiosConfig);
    }

    return axiosOperation.call();
  },
};

export default service;
