/* eslint max-classes-per-file: ["error", 2] */

/* eslint class-methods-use-this: ["error", { "exceptMethods": ["isAnonymous"] }] */

export class User {
  constructor(user) {
    Object.assign(this, user);
  }

  isStudent() {
    return this.group_names.includes('Student');
  }

  isTeacher() {
    return this.group_names.includes('SchoolTeacher');
  }

  canAnnotate() {
    return this.isStudent() || this.isTeacher();
  }

  isContentEditor() {
    return this.group_names.includes('ContentEditor');
  }

  isContentAdmin() {
    return this.group_names.includes('ContentAdmin');
  }

  isSchoolAdmin() {
    return this.group_names.includes('SchoolAdmin');
  }

  isKogAdmin() {
    return this.group_names.includes('KogAdmin');
  }

  isSupport() {
    return this.group_names.includes('Support');
  }

  hasEditContentPermission() {
    return this.isContentEditor() || this.isContentAdmin() || this.isKogAdmin();
  }

  isInternalUser() {
    return this.isKogAdmin() || this.isSupport() || this.isContentAdmin();
  }

  isAnonymous() {
    return false;
  }

  isDemo() {
    return !!this.school?.is_demo;
  }
}

export class AnonymousUser extends User {
  constructor() {
    const user = {
      first_name: '',
      profile: {
        is_managebac_user: false,
      },
      school: {
        name: '',
      },
      group_names: [],
    };
    super(user);
  }

  isAnonymous() {
    return true;
  }
}
