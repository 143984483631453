import { User } from 'learning/common/api/user.js';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

export const ACCOUNTS_ME_URL = '/api/accounts/me';
export const ACCOUNTS_NOTIFICATION_SETTINGS_URL = '/api/accounts/me/notification-settings';

export async function fetchTimezones() {
  const response = await axios.get('/api/accounts/timezones');
  return response.data;
}

export async function fetchMe() {
  const response = await axios.get(ACCOUNTS_ME_URL);
  const userDetails = response.data;
  return new User(userDetails);
}

export async function updateMe(user) {
  const response = await axios.put(ACCOUNTS_ME_URL, user);
  const userDetails = response.data;
  return new User(userDetails);
}

export async function fetchNotificationSettings() {
  const response = await axios.get(ACCOUNTS_NOTIFICATION_SETTINGS_URL);
  return response.data;
}

export async function saveNotificationSettings(data) {
  const response = await axios.patch(ACCOUNTS_NOTIFICATION_SETTINGS_URL, data);
  return response.data;
}
