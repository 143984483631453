<template>
  <aside
    ref="root"
    role="dialog"
    aria-labelledby="kog-modal-title"
    aria-modal="true"
    class="KogModal"
    :class="{
      'KogModal--scrollingLongContent': scrollingLongContent,
    }"
  >
    <header
      v-if="hasHeaderSlot"
      v-kog-clickable="isHeaderClickable ? handleHeaderClick : null"
      class="padd-top-l padd-right-l padd-bottom-s padd-left-l"
    >
      <div class="flexContainer flexContainer-spaceBetween">
        <!-- @slot DEPRECATED slot for displaying modal title content -->
        <slot name="modalTitle" />
        <div
          v-if="!hasTitleSlot"
          class="flexContainer"
        >
          <div
            v-if="headerIcon"
            class="KogModal-headerIcon margin-right-s"
          >
            <img
              v-if="headerIcon.type === 'image'"
              :src="headerIcon.imgUrl"
              :alt="headerIcon.ariaLabel"
              class="KogModal-headerIconImg"
            />
          </div>
          <div>
            <h2
              id="kog-modal-title"
              class="heading-m"
              tabindex="-1"
            >
              {{ title }}
            </h2>
            <div
              v-if="subtitle"
              class="text-regular"
            >
              {{ subtitle }}
            </div>
            <kog-tag
              v-for="tag in subtitleTags"
              v-else
              :key="tag"
              class="margin-right-xxs margin-top-xxs ProductTour-modalSubtitleTag"
              :label="tag"
              type="warning"
            />
          </div>
        </div>

        <kog-round-button
          v-if="closeModal !== undefined"
          aria-label="Close modal"
          icon-class="fa-times"
          icon-style="solid"
          size="small"
          button-style="secondary-basic"
          @click.prevent="closeModal"
        />
      </div>
      <!-- @slot DEPRECATED slot for displaying information below the modal title & subtitle -->
      <slot name="subHeader" />
    </header>

    <!--Body-->
    <vertical-expand>
      <div
        v-if="hasBodySlot && !isCollapsed"
        class="KogModal-body"
        :class="{
          'padd-l': !maximize,
          'KogModal-body--borderRadius': maximize && !hasFooterSlot,
        }"
      >
        <!-- @slot Slot for displaying the main content of the modal -->
        <slot name="modalBody" />
      </div>
    </vertical-expand>
    <!--/Body-->

    <footer
      v-if="hasFooterSlot"
      class="KogModal-footer padd-top-s padd-right-l padd-bottom-l padd-left-l flexContainer flexContainer-flexEnd"
    >
      <!-- @slot Slot for displaying the footer content of the modal -->
      <slot name="modalFooter" />
    </footer>
  </aside>
</template>

<script>
// eslint-disable-next-line kognity/no-kog-prefix
import VerticalExpand from 'sharedApp/animations/vertical-expand.vue';
import KogRoundButton from 'sharedApp/components/base/buttons/kog-round-button.vue';
import KogTag from 'sharedApp/components/base/tags/kog-tag.vue';
import KogClickable from 'sharedApp/directives/kog-clickable.js';

export default {
  name: 'KogModal',
  components: {
    KogRoundButton,
    KogTag,
    VerticalExpand,
  },
  directives: { KogClickable },
  props: {
    /**
     * Title to be shown if `modalTitle` slot is not provided.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Subtitle to be shown if `modalTitle` slot is not provided.
     */
    subtitle: {
      type: String,
      default: '',
    },
    /**
     * When provided, renders KogTags with 'warning' styling.
     * Tags can't be rendered if subtitle is present.
     */
    subtitleTags: {
      type: Array,
      default: () => [],
    },
    /**
     * When `true`, removes padding of modal body.
     */
    maximize: {
      type: Boolean,
      default: false,
    },
    /**
     * When provided, a close icon will be shown in upper right corner of modal
     * which will trigger the provided function when clicked.
     */
    closeModal: {
      type: Function,
      default: undefined,
    },
    /**
     * When `true`, makes the modal content take up its full height.<br>
     * This will cause the modal to not have a scrollbar making the user have to scroll the
     * page to get to the bottom of the modal.
     */
    scrollingLongContent: {
      type: Boolean,
      default: false,
    },
    /**
     * Icon that is shown to the left of title if `modalTitle` slot is empty.
     */
    headerIcon: {
      type: Object,
      default: () => {},
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    /* DEPRECATED: this is used only to support the double-modal usecase, currently unused itself;
     * If the clickable-header variant is not required in additional scenarios, remove by 2025.
     */
    onHeaderClick: {
      type: Function,
      default: undefined,
    },
  },
  emits: ['header-click'],
  computed: {
    hasFooterSlot() {
      return Boolean(this.$slots.modalFooter);
    },
    hasBodySlot() {
      return Boolean(this.$slots.modalBody);
    },
    hasTitleSlot() {
      return Boolean(this.$slots.modalTitle);
    },
    hasHeaderSlot() {
      return (
        Boolean(this.$slots.modalTitle) ||
        Boolean(this.$slots.subHeader) ||
        this.closeModal ||
        this.title
      );
    },
    isHeaderClickable() {
      return Boolean(this.onHeaderClick);
    },
  },
  methods: {
    handleHeaderClick(_, e) {
      if (!e.defaultPrevented) {
        this.$emit('header-click');
      }
    },
  },
};
</script>

<style scoped>
.KogModal {
  overflow: hidden;
  display: flex;
  flex-basis: 0;
  flex-direction: column;

  width: 748px;
  max-width: 95svw !important;
  max-height: 95vh;
  max-height: 95svh !important;
  margin: 10px auto;

  background-color: var(--kogPlatformWhite);
  border-radius: 12px;
  box-shadow: 0 12px 24px -6px var(--kogShadow080);
}

.KogModal-body {
  overflow-y: auto;
  overscroll-behavior: contain;
  flex-grow: 1;
  min-height: 100px;
}

.KogModal-body--borderRadius {
  border-radius: 0 0 12px 12px;
}

.KogModal-footer {
  flex: 0 0 50px;
}

.KogModal-headerIcon {
  width: 40px;
  height: 40px;
}

.KogModal-headerIconImg {
  max-width: 100%;
  max-height: 100%;
}

.KogModal--scrollingLongContent {
  max-height: 100%;
}

@media (--viewport-s) {
  .KogModal {
    width: 95%;
  }
}
</style>
