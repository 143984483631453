import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const baseApiUrl = '/api/activity-sheets/';
const activitySheetUrlToPath = compile(`${baseApiUrl}:activitySheetId/`);

export default {
  async createInstance({ featureTitle: activitySheetTitle }) {
    const response = await axios.post(baseApiUrl, {
      title: activitySheetTitle,
    });
    return response.data;
  },
  async removeInstance(activitySheetId) {
    const url = activitySheetUrlToPath({ activitySheetId });
    const response = await axios.delete(url);
    return response.data;
  },
  async fetchInstance(activitySheetId) {
    const url = activitySheetUrlToPath({ activitySheetId });
    const response = await axios.get(url);
    return response.data;
  },
};
