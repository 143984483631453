import { nextTick } from 'vue';

import RoutesMixin from 'sharedApp/mixins/routes-mixin.js';

export const LAST_PAGE_OFFSET_KEY = 'last-page-offset';
export const setLastPageOffset = (path, top = 0, left = 0) => {
  if (!path) return;

  sessionStorage.setItem(
    LAST_PAGE_OFFSET_KEY,
    JSON.stringify({
      path,
      top,
      left,
    }),
  );
};

const ScrollPositionMixin = {
  mixins: [RoutesMixin],
  created() {
    window.addEventListener('beforeunload', this.storeLastPageOffset);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.storeLastPageOffset);
  },
  methods: {
    storeLastPageOffset() {
      const normalizedPath = this.$route.path.replace(/\/+$/, '');
      setLastPageOffset(normalizedPath, window.scrollY, window.scrollX);
    },
    tryRestoreLastPageOffset() {
      const offsetJson = sessionStorage.getItem(LAST_PAGE_OFFSET_KEY);

      try {
        const offsetData = JSON.parse(offsetJson);

        if (this.$route.path === offsetData.path) {
          sessionStorage.removeItem(LAST_PAGE_OFFSET_KEY);
          nextTick(() => {
            window.scrollTo({
              top: offsetData.top,
              left: offsetData.left,
            });
          });
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
  },
};

export default ScrollPositionMixin;
