import axios from 'sharedApp/vue-utils/kog-axios.ts';

const apiBaseUrl = '/api/subject-nodes/';

export async function createTeacherNote({
  subjectClassId,
  subjectNodeId,
  studentId,
  questionId,
  text,
}) {
  const url = `${apiBaseUrl}${subjectNodeId}/questions/${questionId}/notes/`;
  const response = await axios.post(url, {
    text,
    student_id: studentId,
    subject_class_id: subjectClassId,
  });
  return response.data;
}

export async function updateTeacherNote({
  noteId,
  subjectClassId,
  subjectNodeId,
  questionId,
  studentId,
  text,
}) {
  const url = `${apiBaseUrl}${subjectNodeId}/questions/${questionId}/notes/${noteId}/`;
  const response = await axios.put(url, {
    text,
    student_id: studentId,
    subject_class_id: subjectClassId,
  });
  return response.data;
}

export async function fetchTeachersNotes({ subjectClassId, subjectNodeId, questionId }) {
  const url = `${apiBaseUrl}${subjectNodeId}/questions/${questionId}/notes/`;
  const response = await axios.get(url, {
    params: { subject_class_id: subjectClassId },
  });
  return response.data;
}
