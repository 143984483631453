import axios from 'sharedApp/vue-utils/kog-axios.ts';

const apiBaseUrl = '/api/subject-nodes/';

export async function createFeedbackItem(
  subjectClassId,
  subjectNodeId,
  questionId,
  studentId,
  text,
  operation,
) {
  const url = `${apiBaseUrl}${subjectNodeId}/questions/${questionId}/feedback-items/`;
  const response = await axios.post(url, {
    text,
    operation,
    student_id: studentId,
    subject_class_id: subjectClassId,
  });
  return response.data;
}

export async function fetchFeedbackItem(subjectNodeId, questionId, feedbackItemId) {
  const url = `${apiBaseUrl}${subjectNodeId}/questions/${questionId}/feedback-items/${feedbackItemId}/`;
  const result = await axios.get(url);
  return result.data;
}

export async function fetchFeedbackItems(subjectClassId, subjectNodeId, questionId) {
  const url = `${apiBaseUrl}${subjectNodeId}/questions/${questionId}/feedback-items/`;
  const result = await axios.get(url, { params: { subject_class_id: subjectClassId } });
  return result.data;
}

export async function updateFeedbackItem(
  feedbackItemId,
  subjectClassId,
  subjectNodeId,
  questionId,
  studentId,
  text,
  operation,
) {
  const url = `${apiBaseUrl}${subjectNodeId}/questions/${questionId}/feedback-items/${feedbackItemId}/`;
  const response = await axios.put(url, {
    text,
    operation,
    student_id: studentId,
    subject_class_id: subjectClassId,
  });
  return response.data;
}
