import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const classesApiUrl = '/api/schools/:school_id/classes/';
const classesApiUrlToPath = compile(classesApiUrl);
const SCHOOL_API_URLS = {
  'school-summary': '/api/schoolstaff/school_summary/',
  'demo-school-extend': '/api/schools/demo-school-extend/',
  'schools-with-alerts': '/api/kogadmin/schools_with_alerts/',
};

export async function importClasses(schoolId, integration, classes) {
  const url = classesApiUrlToPath({ school_id: `${schoolId}` });
  const result = await axios.post(url, { classes, integration_slug: integration });
  return result.data;
}

export async function fetchSchoolSummary() {
  const path = SCHOOL_API_URLS['school-summary'];
  const schoolSummaryResponse = await axios.get(path);
  return schoolSummaryResponse.data;
}

export function extendDemoSchoolAccess() {
  return axios.post(SCHOOL_API_URLS['demo-school-extend']);
}

export async function fetchSchoolsWithAlerts(page, ordering, pageSize) {
  const path = SCHOOL_API_URLS['schools-with-alerts'];
  const result = await axios.get(path, {
    params: { page, ordering, page_size: pageSize },
  });
  return result.data;
}
