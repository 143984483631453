import { createVNode, render } from 'vue';

import type { ComponentProps, RawChildren, RawSlots } from 'sharedApp/types.ts';
import type { AppContext, VNode, VNodeArrayChildren, VNodeTypes } from 'vue';

type MountComponentOptions = {
  component: VNodeTypes;
  props?: ComponentProps | null;
  children?: VNodeArrayChildren | RawChildren | RawSlots;
  element?: Element | string | null;
  elementCssClasses?: string[];
  appContext?: AppContext | null;
};

export default function mountComponent({
  component,
  props,
  children,
  element = null,
  elementCssClasses = [],
  appContext = null,
}: MountComponentOptions) {
  let el: Element | null = null;
  let vNode: VNode | null = createVNode(component, props, children);

  if (element instanceof Element) {
    el = element;
  } else if (typeof document !== 'undefined') {
    if (typeof element === 'string') {
      el = document.createElement(element);
    } else {
      el = document.createElement('div');
    }
  }

  if (elementCssClasses.length > 0 && el) {
    el.classList.add(...elementCssClasses);
  }

  if (appContext) {
    vNode.appContext = appContext;
  }

  if (el) {
    render(vNode, el);
  }

  const destroy = () => {
    if (el) {
      render(null, el);
    }
    el = null;
    vNode = null;
  };

  return { vNode, destroy, el };
}
