import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_PATHS = {
  'subject-node-section-activity': compile(
    '/api/study/subjectnode/:subjectNodeId/subject_node_activity/',
  ),
  'subject-last-activity': compile('/api/study/subjects/:subjectId/last_activity/'),
};

export async function registerSectionActivity(subjectNodeId) {
  const path = API_PATHS['subject-node-section-activity']({ subjectNodeId });
  const response = await axios.patch(path);
  return response.data;
}

export async function registerLastActivity(subjectId) {
  const path = API_PATHS['subject-last-activity']({ subjectId });
  const response = await axios.patch(path);
  return response.data;
}
