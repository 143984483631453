import Rox from 'rox-browser';

import type { User } from 'learning/common/api/user.types.ts';
import type { Data } from 'sharedApp/types.ts';
import type { PartialDeep } from 'type-fest';

import DjangoSettings from 'sharedApp/django-settings-module.ts';

let initialized = false;

export function featureFlags() {
  function hasKey() {
    return DjangoSettings.ROLLOUT.AUTH_KEY && DjangoSettings.ROLLOUT.AUTH_KEY.length > 0;
  }

  async function setupRoxSdk() {
    if (!initialized) {
      if (DjangoSettings.ROLLOUT.AUTH_KEY !== null) {
        try {
          await Rox.setup(DjangoSettings.ROLLOUT.AUTH_KEY, {});
          initialized = true;
        } catch (e) {
          console.warn('Failed to setup Rollout SDK', e); // eslint-disable-line no-console
        }
      } else {
        console.warn('ROLLOUT.AUTH_KEY is not set'); // eslint-disable-line no-console
      }
    }
  }

  function init() {
    setupRoxSdk();
  }

  async function isFeatureEnabled<C = Data>(
    featureName: string,
    context: { user: PartialDeep<User> } & C,
    defaultValue = false,
  ) {
    if (!hasKey()) {
      console.warn('ROLLOUT.AUTH_KEY is not set, returning default value'); // eslint-disable-line no-console
      return defaultValue;
    }

    await setupRoxSdk();
    const flatContext = {
      school_id: context.user.school?.id,
      school_id_str: `${context.user.school?.id}`,
      user_id: context.user.id,
      user_id_str: `${context.user.id}`,
      ...context,
    };
    return Rox.dynamicApi.isEnabled(featureName, defaultValue, flatContext);
  }

  function isEnabled(featureName: string, userId: number, schoolId: number) {
    const context = {
      user: {
        id: userId,
        school: {
          id: schoolId,
        },
      },
    };
    return isFeatureEnabled(featureName, context);
  }

  return {
    init,
    isEnabled,
    isFeatureEnabled,
  };
}
