import SpriteLoader from './sprite-loader.js';

import 'flatpickr/dist/flatpickr.css';
import 'floating-vue/dist/style.css';

SpriteLoader();

function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context('./', true, /\.css$/));
