import { getLeafNodeIds, getLeafNodes } from 'sharedApp/libs/subject-tree-functions.js';

import {
  getArrayIntersection,
  getCompletedNodeIdsPerStudent,
  getLatestAttempt,
  getUserAnswerList,
  groupPracticeItemsByStudent,
  sortPracticeItemsBySubmissionDate,
} from './utils.js';

const getters = {
  studentsProgressForNodes: state => nodes => {
    const { questionProgress } = state;

    if (!questionProgress) {
      return {};
    }

    return groupPracticeItemsByStudent(nodes, questionProgress);
  },
  getStudentPracticeItemsByQuestionForNode: state => {
    const { practiceItems } = state;
    const practiceItemsCopy = [...practiceItems];
    const sortedPracticeItems = sortPracticeItemsBySubmissionDate(practiceItemsCopy);

    const practiceItemsByQuestion = new Map();
    sortedPracticeItems.forEach(practiceItem => {
      const attemptToAdd = {
        id: practiceItem.id,
        correct: practiceItem.correct,
        submitted_at: practiceItem.submitted_at,
        user_answer: getUserAnswerList(practiceItem.question, practiceItem.user_answer),
      };

      if (!practiceItemsByQuestion.has(practiceItem.question.id)) {
        practiceItemsByQuestion.set(practiceItem.question.id, {
          question: practiceItem.question,
          attempts: [attemptToAdd],
        });
      } else {
        const itemToUpdate = practiceItemsByQuestion.get(practiceItem.question.id);
        itemToUpdate.attempts = [...itemToUpdate.attempts, attemptToAdd];
        practiceItemsByQuestion.set(practiceItem.question.id, itemToUpdate);
      }
    });

    return Array.from(practiceItemsByQuestion.values());
  },
  getStudentsBookProgressForNodes: state => nodes => {
    const { bookProgress, studentsList } = state;

    const bookProgressPerNode = {};
    nodes.forEach(node => {
      const nodeProgress = {};
      const nodeLeafIds = getLeafNodeIds(node);
      const nodeTotalLeafNodes = nodeLeafIds.length;
      const studentProgress = getCompletedNodeIdsPerStudent(bookProgress);
      const studentIds = studentsList.map(student => student.user_id);

      studentIds.forEach(studentId => {
        const completedSectionsCount = getArrayIntersection(
          nodeLeafIds,
          studentProgress[studentId] ?? [],
        ).length;

        nodeProgress[studentId] = {
          total: nodeTotalLeafNodes,
          completed: completedSectionsCount,
        };
      });

      bookProgressPerNode[node.id] = nodeProgress;
    });

    return bookProgressPerNode;
  },
  getStudentSectionQuestionProgressForNode: state => {
    const { currentNodeSectionQuestions, sectionPracticeItems } = state;

    const questionProgress = [];
    currentNodeSectionQuestions.forEach(question => {
      const practiceItemsForQuestion = sectionPracticeItems.filter(
        pi => pi.question.id === question.id,
      );
      const latestAttempt = getLatestAttempt(practiceItemsForQuestion);
      const attempts = sortPracticeItemsBySubmissionDate(practiceItemsForQuestion).map(
        practiceItem => ({
          ...practiceItem,
          user_answer: getUserAnswerList(practiceItem.question, practiceItem.user_answer),
        }),
      );

      const questionWithPracticeItems = {
        ...question,
        attempts,
        latestAttempt,
      };
      questionProgress.push(questionWithPracticeItems);
    });

    return questionProgress;
  },
  getReflectionAnswersByUsers: state => userIds => {
    const reflectionAnswersByUsers = {};
    userIds.forEach(id => {
      reflectionAnswersByUsers[id] = {};
    });

    const { reflectionQuestionPracticeItems } = state;
    reflectionQuestionPracticeItems.forEach(studentAnswer => {
      if (!reflectionAnswersByUsers[studentAnswer.user_id]) {
        return;
      }
      const userId = studentAnswer.user_id;
      const subjectNodeId = studentAnswer.subjectnode_id;

      reflectionAnswersByUsers[userId][subjectNodeId] = studentAnswer;
    });

    return reflectionAnswersByUsers;
  },
  getNodesWithReflectionSections: state => subjectnodes => {
    const { subjectReflectionSections } = state;
    const subjectReflectionSubjectnodeIds = subjectReflectionSections.map(node => node.id);
    const subjectNodesWithReflectionLeaves = subjectnodes.map(node => {
      const leaves = getLeafNodes(node);
      const reflectionQuestionLeaves = leaves.filter(leaf =>
        subjectReflectionSubjectnodeIds.includes(leaf.id),
      );
      return { ...node, reflectionQuestionLeaves };
    });

    return subjectNodesWithReflectionLeaves.filter(
      node => node.reflectionQuestionLeaves.length > 0,
    );
  },
  getStudentLastActivity: state => userId => state.studentLastActivity[userId],
  getStudentLastOpenSections: state => userId => state.studentLastOpenSections[userId] || [],
  getStudentLatestAssignments: state => userId => state.studentLatestAssignments[userId] || [],
};

export default getters;
