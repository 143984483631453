import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_PATHS = {
  'mcq-battle-setup': compile('/api/study/subjects/:subjectId/mcq_battles/setup/'),
  'subject-mcq-battle-list': compile('/api/study/subjects/:subjectId/mcq_battles/'),
  'mcq-battle-detail': compile('/api/study/mcq_battles/:battleId/'),
  'mcqbattle-get-questions': compile('/study/mcqbattle/play/:battleId/get_questions/'),
  'mcqbattle-submit-question-answer': compile(
    '/study/mcqbattle/play/:classId/:battleId/answer_question/:questionId/',
  ),
};

export const BATTLE_TYPES = {
  random: 'RANDOM',
  classmate: 'CLASSMATE',
};

export async function fetchSetupInfo({ subjectId, subjectClassId }) {
  const path = API_PATHS['mcq-battle-setup']({ subjectId });
  const response = await axios.get(path, { params: { subject_class_id: subjectClassId } });
  return response.data;
}

export async function fetchBattle(battleId) {
  const path = API_PATHS['mcq-battle-detail']({ battleId });
  const response = await axios.get(path);
  return response.data;
}

export async function createNewBattle(subjectId, subjectNodeId, opponent) {
  const path = API_PATHS['subject-mcq-battle-list']({ subjectId });
  const data = {
    subjectnode_id: subjectNodeId,
    opponent_type: opponent.type,
  };
  if (opponent.type === BATTLE_TYPES.classmate) {
    data.classmate_user_id = opponent.classmateId;
  }
  const response = await axios.post(path, data);
  return response.data;
}

export async function fetchBattleQuestions(battleId) {
  const path = API_PATHS['mcqbattle-get-questions']({ battleId });
  const response = await axios.get(path);
  return response.data;
}

export async function answerQuestion(classId, battleId, questionId, userResponse) {
  const path = API_PATHS['mcqbattle-submit-question-answer']({ classId, battleId, questionId });
  const response = await axios.post(path, {
    user_response: userResponse,
  });
  return response.data;
}
