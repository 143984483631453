import DrivingQuestionBoardApi from 'sharedApp/apis/features/driving-question-board.js';
import QuizApi from 'sharedApp/apis/features/quiz.js';
import StandardContentPageApi from 'sharedApp/apis/features/standard-content-page.js';
import {
  ACTIVITY_SHEET,
  DRIVING_QUESTION_BOARD,
  MULTI_PART_QUESTION,
  PERFORMANCE_TASK,
  QUIZ,
  STANDARD_CONTENT_PAGE,
} from 'sharedApp/const/features.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

import ActivitySheetApi from './features/activity-sheet.js';
import MultiPartQuestionApi from './features/multi-part-question.js';
import PerformanceTaskApi from './features/performance-task.js';

const featureUrl = '/api/activities/';
const featureMappingsUrl = '/api/activities/mappings/';

export async function updateFeature(featureId, updatedTitle) {
  const url = new URL(`${featureUrl}${featureId}/`, window.location.origin);
  const data = { title: updatedTitle };
  const response = await axios.patch(url.toString(), data);
  return response.data;
}

export async function fetchFeatureMappings(subjectNodeIds) {
  const url = new URL(featureMappingsUrl, window.location.origin);
  url.searchParams.append('subject_node__id__in', subjectNodeIds);
  const response = await axios.get(url.toString());
  return response.data;
}

const FEATURE_API = {
  [STANDARD_CONTENT_PAGE]: StandardContentPageApi,
  [DRIVING_QUESTION_BOARD]: DrivingQuestionBoardApi,
  [ACTIVITY_SHEET]: ActivitySheetApi,
  [QUIZ]: QuizApi,
  [PERFORMANCE_TASK]: PerformanceTaskApi,
  [MULTI_PART_QUESTION]: MultiPartQuestionApi,
};

export function createFeatureInstance({
  featureType,
  featureTitle,
  subjectNodeId,
  featureSpecificParams,
}) {
  const featureApi = FEATURE_API[featureType];
  return featureApi.createInstance({ featureTitle, subjectNodeId, ...featureSpecificParams });
}

export function fetchFeatureInstance({ featureId, featureType }) {
  const featureApi = FEATURE_API[featureType];
  return featureApi.fetchInstance(featureId);
}

export async function mapFeatureToSubjectNode({ subjectNodeId, featureId, order }) {
  const response = await axios.post(featureMappingsUrl, {
    subject_node_id: subjectNodeId,
    feature_id: featureId,
    order,
  });
  return response.data;
}

export async function moveFeatureMapping(mappingId, targetSubjectNodeId) {
  const url = new URL(`${featureMappingsUrl}${mappingId}/`, window.location.origin);
  const data = { subject_node_id: targetSubjectNodeId };
  const response = await axios.patch(url.toString(), data);
  return response.data;
}

export async function setFeatureMappingOrder(subjectNodeId, newOrder) {
  const featureMappingUrl = new URL(
    `${featureMappingsUrl}order/${subjectNodeId}/`,
    window.location.origin,
  );
  const response = await axios.put(featureMappingUrl.toString(), newOrder);
  return response.data;
}

export async function deleteFeatureMapping(mappingId) {
  const response = await axios.delete(`${featureMappingsUrl}${mappingId}`);
  return response.data;
}
