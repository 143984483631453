import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

const namespaced = true;

const state = {
  studentsList: [],
  studentDetails: null,
  studentLevel: null,
  studentLastActivity: {},
  questionProgress: null,
  questionTabParams: {},
  textbookTabParams: {},
  subjectTree: {},
  subjectNodeMap: {},
  subjectClass: {},
  educationSystem: '',
  practiceItems: [],
  bookProgress: [],
  currentNodeSectionQuestions: [],
  sectionPracticeItems: [],
  reflectionQuestionPracticeItems: [],
  subjectReflectionSections: [],
  useReflectionQuestionProgressCache: true,
  studentLastOpenSections: {},
  studentSections: [],
  studentSectionsCompleted: {},
  studentSectionsPagination: {
    currentPage: 1,
    pageSize: 5,
    totalItems: 0,
  },
  studentLatestAssignments: {},
  studentAssignments: [],
  studentCompletedAssignmentsCount: 0,
  studentAssignmentsPagination: {
    currentPage: 1,
    pageSize: 5,
    totalItems: 0,
  },
  studentBattlesCount: 0,
  studentBattles: [],
  studentBattlesPagination: {
    currentPage: 1,
    pageSize: 5,
    totalItems: 0,
  },
  studentStrengthTestsCount: 0,
  studentStrengthTests: [],
  studentStrengthTestsPagination: {
    currentPage: 1,
    pageSize: 5,
    totalItems: 0,
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
