<template>
  <kog-modal
    :title="title"
    :subtitle="subtitle"
    maximize
  >
    <template #modalBody>
      <div class="padd-right-l padd-bottom-l padd-left-l">
        <onboarding-actions-step
          v-if="step === 'action'"
          :is-reonboarding="isReonboarding"
          @close="handleClose"
          @show-video="step = 'video'"
        />
        <onboarding-video-step
          v-else
          :is-reonboarding="isReonboarding"
          :education-system-name="educationSystemName"
          :next-button-text="videoStepNextButtonText"
          @next="handleVideoStepNext"
        />
      </div>
    </template>
  </kog-modal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { OnboardingVersionEnumEnum } from '@apis/generated';

import KogModal from 'sharedApp/components/modals/kog-modal.vue';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';
import OnboardingActionsStep from 'studyApp/components/onboarding-modal/steps/onboarding-actions-step.vue';
import OnboardingVideoStep from 'studyApp/components/onboarding-modal/steps/onboarding-video-step.vue';

const props = defineProps<{
  closeModal: () => void;
}>();

const route = useRoute();
const isReonboarding = route.query.version === OnboardingVersionEnumEnum.REONBOARDING;

const store = useStore();
const educationSystemName = computed(() => store.state.subjectModule.subject.educationsystem.name);

const isOnlyVideo = computed(() => isNgss(educationSystemName.value));
const step = ref<'action' | 'video'>(isOnlyVideo.value ? 'video' : 'action');

const title = isReonboarding
  ? 'Explore Kognity with a quick tour'
  : 'Explore Kognity with a class tour';
const subtitle = isReonboarding
  ? "We’re glad to see you again! Since teaching and learning activities on Kognity happen through classes, we've put together a quick tour to refresh your knowledge on using your resources."
  : "Welcome to your class! Your account is now activated and we're here to help you discover your new resources. Teaching and learning on Kognity happen through classes, so let's begin with a quick tour of what you can do.";

const videoStepNextButtonText = computed(() => (isOnlyVideo.value ? 'Explore on my own' : 'Next'));

const handleClose = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('show_onboarding_modal');
  window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
  props.closeModal();
};

const handleVideoStepNext = () => {
  if (isOnlyVideo.value) {
    handleClose();
  } else {
    step.value = 'action';
  }
};
</script>
