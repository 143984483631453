<template>
  <div class="KogButtonLoadingSpinner-fadingCircle">
    <div class="KogButtonLoadingSpinner-circle" />
    <div class="KogButtonLoadingSpinner-circle2 KogButtonLoadingSpinner-circle" />
    <div class="KogButtonLoadingSpinner-circle3 KogButtonLoadingSpinner-circle" />
    <div class="KogButtonLoadingSpinner-circle4 KogButtonLoadingSpinner-circle" />
    <div class="KogButtonLoadingSpinner-circle5 KogButtonLoadingSpinner-circle" />
    <div class="KogButtonLoadingSpinner-circle6 KogButtonLoadingSpinner-circle" />
    <div class="KogButtonLoadingSpinner-circle7 KogButtonLoadingSpinner-circle" />
  </div>
</template>

<script>
export default {
  name: 'KogButtonLoadingSpinner',
};
</script>

<style scoped>
.KogButtonLoadingSpinner-fadingCircle {
  position: relative;
  width: 24px;
  height: 24px;
}

.KogButtonLoadingSpinner-circle {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.KogButtonLoadingSpinner-circle::before {
  content: '';

  display: block;

  width: 27%;
  height: 27%;
  margin: 0 auto;

  background: var(--kogPlatformGray077);
  border-radius: 100%;

  animation: KogButtonLoadingSpinner-circleFadeDelay 0.7s infinite ease-in-out both;
}

.KogButtonLoadingSpinner-circle2 {
  transform: rotate(51deg);
}

.KogButtonLoadingSpinner-circle3 {
  transform: rotate(102deg);
}

.KogButtonLoadingSpinner-circle4 {
  transform: rotate(153deg);
}

.KogButtonLoadingSpinner-circle5 {
  transform: rotate(204deg);
}

.KogButtonLoadingSpinner-circle6 {
  transform: rotate(255deg);
}

.KogButtonLoadingSpinner-circle7 {
  transform: rotate(306deg);
}

.KogButtonLoadingSpinner-circle2::before {
  animation-delay: -0.6s;
}

.KogButtonLoadingSpinner-circle3::before {
  animation-delay: -0.5s;
}

.KogButtonLoadingSpinner-circle4::before {
  animation-delay: -0.4s;
}

.KogButtonLoadingSpinner-circle5::before {
  animation-delay: -0.3s;
}

.KogButtonLoadingSpinner-circle6::before {
  animation-delay: -0.2s;
}

.KogButtonLoadingSpinner-circle7::before {
  animation-delay: -0.1s;
}

@keyframes KogButtonLoadingSpinner-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
</style>
