import { API_URLS } from 'schoolAdminApp/urls.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

async function fetchSchoolDetails(schoolId, params = {}) {
  const url = API_URLS.SCHOOL_DETAIL_URL({ schoolId });
  const result = await axios.get(url, { params });
  return result.data;
}

export default {
  fetchSchoolDetails,
};
