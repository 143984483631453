import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_PATHS = {
  'glossary-definitions': compile('/api/study/subjects/:subjectId/glossary_definitions/'),
};

export async function fetchGlossaryDefinitions(subjectId) {
  const path = API_PATHS['glossary-definitions']({ subjectId });
  const response = await axios.get(path);
  return response.data;
}
