export default {
  SHOW_STUDENT_QUESTION_PROGRESS: 'Show student question progress bar',
  ENABLE_SUBJECT_NODE_TO_SYLLABUS_NODE_MAPPING: 'Enable subject node to syllabus node mapping',
  ENABLE_STUDENT_ASSIGNMENT_BASKET: 'Enable student assignment basket',
  ENABLE_EDLINK_ASSIGNMENT_INTEGRATION: 'Enable edlink assignment integration',
  SHOW_ACTIVITY_SHEET_ANSWER_KEY: 'Show Activity Sheet Answer Keys',
  PLAIN_TEXT_TEACHER_QUESTIONS_CREATION: 'Plain Text Teacher Questions Creation',
  ENABLE_MULTIPLE_CLASS_ASSIGNMENT: 'Enable multi class assignment',
  SHOW_FILTERS_ON_CLASS_OVERVIEW: 'Show filters on class overview',
  ENABLE_MULTIPART_QUESTION_ACTIVITY: 'Enable Multipart Question Activity',
  SUBJECTS_WITH_DIFFICULTY_TAGS: [
    65, 48, 117, 134, 122, 132, 177, 181, 189, 183, 186, 187, 261, 294, 295, 296, 297, 300, 351,
    352, 87, 425, 424, 430, 428, 422, 426, 423, 435,
  ],
};
