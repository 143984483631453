import { xor } from 'lodash';

import mcqServices from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';
import { isMCQ } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import {
  createStrengthOccasion,
  fetchPracticeOccasion,
  savePracticeOccasion,
} from 'studyApp/api/practice-occasion.js';

const namespaced = true;

function initMcqChoices(occasion) {
  occasion.practice_items
    .filter(i => isMCQ(i.question))
    .forEach(practiceItem => {
      mcqServices().updateMultipleChoiceQuestionWithNumbering(practiceItem.question, true);
      const item = practiceItem;
      item.answerToSubmit = undefined;
    });
}

const mutations = {
  setPracticeOccasion(state, practiceOccasion) {
    state.practiceOccasion = practiceOccasion;
  },
  setCurrentPracticeItem(state, index) {
    if (state.practiceOccasion && state.practiceOccasion.practice_items.length > index) {
      state.currentPracticeItem = state.practiceOccasion.practice_items[index];
    }
  },
  setCurrentAnswer(state, answer) {
    if (state.currentPracticeItem) {
      state.currentPracticeItem.answerToSubmit = answer;
    }
  },
  setIsOccasionTaken(state, isTaken) {
    state.isOccasionTaken = isTaken;
  },
};

const actions = {
  createSubjectStrengthOccasion(_, { subjectId, selectedNodes, questionQuantity }) {
    return createStrengthOccasion(subjectId, selectedNodes, questionQuantity);
  },
  async fetchPracticeOccasion({ commit }, occasionId) {
    commit('setPracticeOccasion', null);
    commit('setCurrentPracticeItem', 0);
    commit('setIsOccasionTaken', false);
    const occasion = await fetchPracticeOccasion(occasionId);
    initMcqChoices(occasion);
    commit('setPracticeOccasion', occasion);
    commit('setCurrentPracticeItem', 0);
    commit('setIsOccasionTaken', !!occasion.done_at);
  },
  async saveOccasionAnswers({ commit }, occasion) {
    const practiceItemsToAdd = occasion.practice_items.map(item => ({
      id: item.id,
      user_answer: item.answerToSubmit,
    }));
    const data = { practice_items: practiceItemsToAdd };
    try {
      const practiceOccasion = await savePracticeOccasion(occasion.id, data);
      practiceOccasion.practice_items = practiceOccasion.practice_items.map(item => {
        if (!isMCQ(item.question)) return item;

        // Copy the old MCQ question which contain information about numbering
        const oldItem = occasion.practice_items.find(opi => opi.id === item.id);
        return {
          ...item,
          question: oldItem.question,
        };
      });
      commit('setPracticeOccasion', practiceOccasion);
      commit('setIsOccasionTaken', true);
      return { isTaken: false };
    } catch (e) {
      const errorResponse = e.response;
      if (
        errorResponse.status === 400 &&
        errorResponse.data.non_field_errors &&
        errorResponse.data.non_field_errors[0] === 'TEST_TAKEN'
      ) {
        return { isTaken: true };
      }
      throw new Error('Failed to save answer(s)');
    }
  },
  updateCurrentAnswer({ commit, state }, answer) {
    if (state.currentPracticeItem) {
      const { answerToSubmit = [], question } = state.currentPracticeItem;
      let newAnswer;
      if (isMCQ(question)) {
        if (question.is_multi_select) {
          newAnswer = xor(answerToSubmit, [answer]);
        } else {
          newAnswer = [answer];
        }
      } else {
        newAnswer = answer;
      }
      commit('setCurrentAnswer', newAnswer);
    }
  },
};

const state = {
  practiceOccasion: null,
  currentPracticeItem: null,
  isOccasionTaken: false,
};

export default {
  namespaced,
  actions,
  mutations,
  state,
};
