import { debounceTime, filter, map, merge, takeUntil, tap } from 'rxjs';

const isPrintable = key => key.length === 1;

export function initTypingBuffer(keyEvent$, endListen$, callbackFunc) {
  let buffer = [];

  const printableKeyPressed$ = keyEvent$.pipe(
    filter(event => {
      const { key } = event;
      return isPrintable(key);
    }),
    tap(event => {
      event.preventDefault();
      event.stopPropagation();
    }),
    map(event => event.key),
    takeUntil(endListen$),
  );

  merge(
    printableKeyPressed$.pipe(
      map(key => {
        buffer.push(key);
        return true;
      }),
    ),
    printableKeyPressed$.pipe(
      debounceTime(1000),
      map(() => {
        buffer = [];
        return false;
      }),
    ),
  )
    .pipe(takeUntil(endListen$))
    .subscribe(shouldCallback => {
      if (shouldCallback) {
        callbackFunc(buffer.join(''));
      }
    });
}
