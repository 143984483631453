import { compile } from 'path-to-regexp';
import qs from 'qs';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const SUBJECT_NODE_LEVEL = 3;
const API_PATHS = {
  'subjectnodes-with-questions': compile('/api/study/subjects/:subjectId/nodes_with_questions/'),
  'subject-nodes-strength-statistics': compile(
    '/api/study/subjects/:subjectId/strength_statistics/',
  ),
  'subject-nodes-progress-statistics': compile(
    '/api/study/subjects/:subjectId/progress_statistics/',
  ),
};

export async function fetchSubjectNodesStrength(subjectId) {
  const path = API_PATHS['subject-nodes-strength-statistics']({ subjectId });
  const response = await axios.get(path, {
    params: {
      max_level: SUBJECT_NODE_LEVEL,
    },
  });
  return response.data;
}

export async function fetchSubjectNodesProgress(subjectId) {
  const path = API_PATHS['subject-nodes-progress-statistics']({ subjectId });
  const response = await axios.get(path);
  return response.data;
}

export async function fetchSubjectNodeIDsWithAvailableStrengthQuestions(subjectId, subjectNodeIds) {
  const path = API_PATHS['subjectnodes-with-questions']({ subjectId });
  const response = await axios.get(path, {
    cache: true,
    params: {
      subjectnode_ids: subjectNodeIds.join(','),
      question_type: 'strength_question',
    },
  });
  return response.data;
}

export async function fetchSubjectNodeIDsWithAvailableStrengthBattleQuestions(
  subjectId,
  subjectNodeIds,
) {
  const path = API_PATHS['subjectnodes-with-questions']({ subjectId });
  const response = await axios.get(path, {
    cache: true,
    params: {
      subjectnode_ids: subjectNodeIds.join(','),
      question_type: 'strength_battle_question',
    },
  });
  return response.data;
}

export async function updateOrCreateNodeProgress(
  subjectNodeId,
  isCompleted,
  sectionCheckpointOccasionId,
) {
  const path = `api/subject-nodes/${subjectNodeId}/node-progress/`;
  const response = await axios.put(path, {
    completed: isCompleted,
    occasion_id: sectionCheckpointOccasionId,
  });
  return response.data;
}

function paramsSerializer(params) {
  return qs.stringify(params, { arrayFormat: 'repeat' });
}

export async function fetchSubjectNodeQuestionCompletionProgress(subjectNodeIds) {
  const path = '/api/progress-bars/question-completion-progress/';
  const { data } = await axios.get(path, {
    params: {
      subjectnodes: subjectNodeIds,
    },
    paramsSerializer,
  });
  return data;
}
