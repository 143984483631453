import debounce from 'lodash/debounce.js';

import { searchInAnnotations, searchInBook } from 'learning/study/api/search.js';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const namespaced = true;

const searchMinSymbols = 3;

const initialState = {
  results: [],
};

const mutations = {
  setSearchResults(state, searchResults) {
    state.searchResults = searchResults;
  },
  setLoading(state, status) {
    state.loading = status;
  },
};

const debouncedSearch = debounce(async (commit, subjectId, query, callback, searchOn) => {
  commit('setLoading', true);
  const shouldSearchInBook = searchOn === 'book';
  let data = null;
  if (shouldSearchInBook) {
    data = await searchInBook(subjectId, query);
  } else {
    data = await searchInAnnotations(subjectId, query);
  }

  commit('setSearchResults', data);
  commit('setLoading', false);
  callback();
}, 750);

const actions = {
  search({ commit, dispatch }, { subjectId, query, callback, searchOn }) {
    if (query.length <= searchMinSymbols) {
      dispatch('clearResults');
      return;
    }
    commit('setLoading', true);
    debouncedSearch(commit, subjectId, query, callback, searchOn);
  },
  async nextPage({ commit, state }) {
    if (state.searchResults.next) {
      commit('setLoading', true);
      const response = await axios.get(state.searchResults.next);
      commit('setSearchResults', response.data);
      commit('setLoading', false);
    }
  },
  async previousPage({ commit, state }) {
    if (state.searchResults.previous) {
      commit('setLoading', true);
      const response = await axios.get(state.searchResults.previous);
      commit('setSearchResults', response.data);
      commit('setLoading', false);
    }
  },
  clearResults({ commit }) {
    commit('setSearchResults', initialState);
  },
};

const state = {
  searchResults: initialState,
  loading: true,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
};
