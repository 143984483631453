import * as Sentry from '@sentry/vue';
import pathToRegexp from 'path-to-regexp';

import studyRoutes from 'studyApp/routes.js';

function createToPathFunctions(routeDir) {
  const newRouteDir = { ...routeDir };
  Object.keys(newRouteDir.urls).forEach(endpointName => {
    let jsRegex = newRouteDir.urls[endpointName].js;
    if (jsRegex === undefined) {
      jsRegex = newRouteDir.urls[endpointName].path;
    }
    const toPath = pathToRegexp.compile(jsRegex);
    newRouteDir.urls[endpointName].toPath = (params, withBase) => {
      if (withBase) {
        return `${newRouteDir.base}${toPath(params)}`;
      }
      return toPath(params);
    };
  });
  return newRouteDir;
}

const apps = {
  studyApp: createToPathFunctions(studyRoutes),
};

const reverse = (appName, routeName, paramsObj, withBase = true) => {
  try {
    const app = apps[appName];
    return app.urls[routeName].toPath(paramsObj, withBase);
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export default reverse;
