import { API_URLS } from '@apis/users.js';

import { saveNotificationSettings } from 'accountsApp/api/user-profile.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const namespaced = true;

const mutations = {
  setIsGoogleClassroomTeacher(state, isGoogleClassroomTeacher) {
    state.userProfile.isGoogleClassroomTeacher = isGoogleClassroomTeacher;
  },
  setShowTeacherOverviewWelcomeMessage(state, show) {
    state.userProfile.showTeacherOverviewWelcomeMessage = show;
  },
};

const actions = {
  async getUserProfile({ commit }) {
    const response = await axios.get(API_URLS['user-profile']);
    const {
      is_google_classroom_teacher: isGoogleClassroomTeacher,
      show_teacher_overview_welcome_message: showTeacherOverviewWelcomeMessage,
    } = response.data.user_profile;
    commit('setIsGoogleClassroomTeacher', isGoogleClassroomTeacher);
    commit('setShowTeacherOverviewWelcomeMessage', showTeacherOverviewWelcomeMessage);
    return true;
  },
  async updateShowTeacherOverviewWelcomeMessage({ commit }, show) {
    const data = {
      show_teacher_overview_welcome_message: show,
    };
    await saveNotificationSettings(data);
    commit('setShowTeacherOverviewWelcomeMessage', show);
    return true;
  },
};

const state = {
  userProfile: {
    isGoogleClassroomTeacher: false,
    showTeacherOverviewWelcomeMessage: false,
  },
};

export default {
  namespaced,
  actions,
  state,
  mutations,
};
