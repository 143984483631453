import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

export const API_PATHS = {
  'assignment-detail': compile('/api/study/assignments/:assignmentId/'),
  'assignment-start': compile('/api/study/assignments/:assignmentId/start/'),
  'assignment-submit-answer': compile('/api/study/assignments/:assignmentId/submit-answer/'),
  'esq-assignment-detail': compile('/api/study/assignments/:assignmentId/esq_assignment_detail/'),
  'reading-assignment-nodeprogress': compile('/api/study/assignments/:assignmentId/node_progress/'),
  'ongoing-assignment-list': '/api/study/assignments/ongoing/',
  'ongoing-assignment-list-for-subject': compile(
    '/api/study/subjects/:subjectId/assignments/ongoing/',
  ),
  'subject-assignments-past-list': compile('/api/study/subjects/:subjectId/assignments/past/'),
};

export async function fetchOngoingSubjectAssignments(subjectId, page, subjectClassId) {
  const path = API_PATHS['ongoing-assignment-list-for-subject']({ subjectId });
  const requestOptions = {
    params: {
      subject_class_id: subjectClassId || null,
      page: page || null,
    },
  };

  const response = await axios.get(path, requestOptions);
  return response.data;
}

export async function fetchPastSubjectAssignments(subjectId, page, subjectClassId) {
  const path = API_PATHS['subject-assignments-past-list']({ subjectId });
  const requestOptions = {
    params: {
      page: page || null,
      subject_class_id: subjectClassId || null,
    },
  };

  const response = await axios.get(path, requestOptions);
  return response.data;
}

export async function fetchOngoingAssignments() {
  const path = API_PATHS['ongoing-assignment-list'];
  const response = await axios.get(path);
  return response.data;
}

export async function fetchReadingAssignmentsForSection(sectionNodeId) {
  const params = {
    section_node_id: sectionNodeId,
  };
  const path = API_PATHS['ongoing-assignment-list'];
  const response = await axios.get(path, { params });
  return response.data;
}

export async function getAssignmentDetails(assignmentId) {
  const path = API_PATHS['assignment-detail']({ assignmentId });
  const response = await axios.get(path);
  return response.data;
}

export async function startQuestionAssignment(assignmentId) {
  const path = API_PATHS['assignment-start']({ assignmentId });
  const response = await axios.put(path);
  return response.data;
}

export function updateEsqAssignmentDetail(assignmentId, payload) {
  const path = API_PATHS['esq-assignment-detail']({ assignmentId });
  return axios.put(path, payload);
}

export async function getNodeProgress(assignmentId) {
  const path = API_PATHS['reading-assignment-nodeprogress']({ assignmentId });
  const response = await axios.get(path);
  return response.data;
}

export async function submitQuestionAnswer(assignmentId, practiceItems) {
  const path = API_PATHS['assignment-submit-answer']({ assignmentId });
  const response = await axios.put(path, {
    practice_items: practiceItems,
  });
  return response.data;
}

export async function saveAssignment(assignmentId, practiceItems, markAsDone) {
  const path = API_PATHS['assignment-detail']({ assignmentId });
  const response = await axios.put(path, {
    practice_items: practiceItems,
    mark_as_done: markAsDone,
  });
  return response.data;
}

export async function getAssignmentList({
  page,
  pageSize,
  subjectClassId,
  filter,
  sortOrder,
  sortBy,
  deadlineFromDate,
  subjectId,
}) {
  const sortPrefix = sortOrder === 'desc' ? '-' : '';
  const sortString = sortBy ? `${sortPrefix}${sortBy}` : null;
  const path = '/api/assignments/';
  const params = {
    page: page || 1,
    page_size: pageSize || 10,
    sort_by: sortString,
    deadline_from_date: deadlineFromDate,
    subject_id: subjectId,
    filter,
  };
  if (subjectClassId) {
    params.subject_class_id = subjectClassId;
  }
  const result = await axios.get(path, { params });
  return result.data;
}
