import Vue from 'vue';
import keyBy from 'lodash/keyBy.js';

import * as drivingQuestionsApi from '@apis/driving-question.js';

export default {
  namespaced: true,
  state: {
    drivingQuestions: [],
  },
  getters: {
    drivingQuestionsById(state) {
      return keyBy(state.drivingQuestions, 'id');
    },
    drivingQuestionsByDqbId(state) {
      return keyBy(state.drivingQuestions, 'driving_question_board');
    },
    getDrivingQuestionByDqbId(state, getters) {
      return dqbId => getters.drivingQuestionsByDqbId[dqbId];
    },
  },
  mutations: {
    setDrivingQuestions(state, drivingQuestions) {
      state.drivingQuestions = drivingQuestions;
    },
  },
  actions: {
    async fetchDrivingQuestions({ commit }, subjectClassId) {
      const drivingQuestionForClassAndBoard =
        await drivingQuestionsApi.fetchDrivingQuestions(subjectClassId);
      commit('setDrivingQuestions', drivingQuestionForClassAndBoard);
    },
    async createDrivingQuestion(
      { dispatch },
      { subjectClassId, featureId, questionText, externalUrl },
    ) {
      await drivingQuestionsApi.createDrivingQuestion({
        subjectClassId,
        featureId,
        questionText,
        externalUrl,
      });
      await dispatch('fetchDrivingQuestions', subjectClassId);
    },
    async updateDrivingQuestion(
      { dispatch },
      { subjectClassId, featureId, drivingQuestionId, questionText, externalUrl },
    ) {
      await drivingQuestionsApi.updateDrivingQuestion({
        subjectClassId,
        featureId,
        drivingQuestionId,
        questionText,
        externalUrl,
      });
      await dispatch('fetchDrivingQuestions', subjectClassId);
    },
    async uploadDrivingQuestionImages(
      { dispatch },
      { subjectClassId, featureId, drivingQuestionId, images },
    ) {
      await drivingQuestionsApi.uploadDrivingQuestionImages({
        subjectClassId,
        featureId,
        drivingQuestionId,
        images,
      });
      await dispatch('fetchDrivingQuestions', subjectClassId);
    },
    async deleteDrivingQuestionImages(
      { dispatch },
      { subjectClassId, featureId, drivingQuestionId, imageIds },
    ) {
      await drivingQuestionsApi.deleteDrivingQuestionImages({
        subjectClassId,
        featureId,
        drivingQuestionId,
        imageIds,
      });
      await dispatch('fetchDrivingQuestions', subjectClassId);
    },
  },
};
