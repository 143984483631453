function getSubjectTreeObject(subjectTree) {
  const subjectSectionReducer = (acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  };

  const topicArray = subjectTree.reduce((acc, curr) => acc.concat(curr.children), []);
  const subtopicArray = topicArray.reduce((acc, curr) => acc.concat(curr.children), []);
  const sectionArray = subtopicArray.reduce((acc, curr) => acc.concat(curr.children), []);
  const subsectionArray = sectionArray.reduce((acc, curr) => acc.concat(curr.children), []);
  const sectionMap = sectionArray.reduce(subjectSectionReducer, {});
  const subsectionMap = subsectionArray.reduce(subjectSectionReducer, {});

  return {
    topicArray,
    subtopicArray,
    sectionArray,
    subsectionArray,
    sectionMap,
    subsectionMap,
  };
}

function getSubjectNodeMap(subTree) {
  const partialMap = {};
  if (!subTree.id) {
    return partialMap;
  }
  partialMap[subTree.id] = subTree;
  if (subTree.children) {
    return subTree.children.reduce((acc, childTree) => {
      const result = { ...acc, ...getSubjectNodeMap(childTree) };
      return result;
    }, partialMap);
  }
  return partialMap;
}

function getFirstSection(subtree) {
  if (subtree.children.length > 0) {
    return getFirstSection(subtree.children[0]);
  }
  return subtree;
}

function getFirstSectionFromSlugs(
  subjectTree,
  topicSlug,
  subtopicSlug,
  sectionSlug,
  subsectionSlug,
) {
  const topic = subjectTree.children.find(s => s.slug === topicSlug);
  if (!topic) {
    return getFirstSection(subjectTree);
  }
  const subtopic = topic.children.find(s => s.slug === subtopicSlug);
  if (!subtopic) {
    return getFirstSection(topic);
  }
  const section = subtopic.children.find(s => s.slug === sectionSlug);
  if (!section) {
    return getFirstSection(subtopic);
  }
  const subsection = section.children.find(s => s.slug === subsectionSlug);
  if (!subsection) {
    return getFirstSection(section);
  }
  return subsection;
}

function getSubjectNodeFromSlugs(
  subjectTree,
  topicSlug,
  subtopicSlug,
  sectionSlug,
  subsectionSlug,
) {
  const topic = subjectTree.children.find(s => s.slug === topicSlug);
  if (!topic) {
    return subjectTree;
  }
  const subtopic = topic.children.find(s => s.slug === subtopicSlug);
  if (!subtopic) {
    return topic;
  }
  const section = subtopic.children.find(s => s.slug === sectionSlug);
  if (!section) {
    return subtopic;
  }
  const subsection = section.children.find(s => s.slug === subsectionSlug);
  if (!subsection) {
    return section;
  }
  return subsection;
}

const PREV = -1;
const NEXT = 1;

function getLevelName(level) {
  switch (level) {
    case 0:
      return 'subject';
    case 1:
      return 'topic';
    case 2:
      return 'subtopic';
    case 3:
      return 'section';
    case 4:
      return 'subsection';
    default:
      throw new Error('Invalid node level');
  }
}

function getAllDescendantsNodeIdsIncludingSelf(subjectNode, accumulatedDescendants = new Set()) {
  const { children, id } = subjectNode;
  accumulatedDescendants.add(id);
  if (children.length === 0) {
    return accumulatedDescendants;
  }

  children.forEach(descendant => {
    getAllDescendantsNodeIdsIncludingSelf(descendant, accumulatedDescendants);
  });

  return accumulatedDescendants;
}

function getSubjectTreeTopicLevelNodeIds(subjectTree) {
  if (subjectTree.level !== 0) {
    return [];
  }
  return subjectTree.children.map(child => child.id);
}

function getLeafNodes(tree) {
  const isTreeWithChildren = tree.children.length > 0;
  return isTreeWithChildren ? tree.children.flatMap(getLeafNodes) : [tree];
}

function getLeafNodeIds(tree) {
  const leafNodes = getLeafNodes(tree);
  return leafNodes.map(leaf => leaf.id);
}

function isSubSection(sn) {
  return sn.level === 4;
}
function isSection(sn) {
  return sn.level === 3;
}
function isSubTopic(sn) {
  return sn.level === 2;
}
function isTopic(sn) {
  return sn.level === 1;
}
function isSubject(sn) {
  return sn.level === 0;
}
function isContentNode(sn) {
  return !!sn.section_id;
}

const subjectTreeFunctions = {
  getSubjectTreeObject,
  getSubjectNodeMap,
  getFirstSection,
  isContentNode,
  isSubSection,
  isSection,
  isSubTopic,
  isTopic,
  isSubject,
  getLevelName,
  getFirstSectionFromSlugs,
  getSubjectNodeFromSlugs,
  PREV,
  NEXT,
  getAllDescendantsNodeIdsIncludingSelf,
  getSubjectTreeTopicLevelNodeIds,
  getLeafNodes,
  getLeafNodeIds,
};

module.exports = subjectTreeFunctions;
