import { addClassTeachers, getClassTeachers, removeClassTeacher } from '@apis/subject-class.js';

const namespaced = true;

const mutations = {
  setClassTeachers(state, teachers) {
    state.classTeachers = teachers;
  },
};

const actions = {
  async fetchClassTeachers({ commit }, { subjectClassId, params, useCache = true }) {
    const classTeachers = await getClassTeachers(subjectClassId, params, useCache);
    commit('setClassTeachers', classTeachers);
  },

  async removeTeacherFromClass({ dispatch }, { subjectClassId, teacherId, removedSelf }) {
    await removeClassTeacher(subjectClassId, teacherId);
    const useCache = false;
    if (!removedSelf) {
      dispatch('fetchClassTeachers', { subjectClassId, useCache });
      dispatch(
        'schoolstaffSubjectclassModule/fetchSubjectClass',
        { subjectClassId, useCache },
        { root: true },
      );
    }
  },

  async addTeachersToClass({ dispatch }, { subjectClassId, teacherIds }) {
    await addClassTeachers(subjectClassId, teacherIds);
    const useCache = false;
    dispatch('fetchClassTeachers', { subjectClassId, useCache });
    dispatch(
      'schoolstaffSubjectclassModule/fetchSubjectClass',
      { subjectClassId, useCache },
      { root: true },
    );
  },
};

const state = {
  classTeachers: null,
};

export default {
  namespaced,
  mutations,
  actions,
  state,
};
