<template>
  <div
    :id="dotId"
    ref="root"
    class="KogPulsingDot"
  >
    <div
      v-tooltip="{
        theme: 'kog-tooltip',
        content: tooltipText,
        popperClass: 'text-center',
        boundary: 'document.body',
      }"
      class="KogPulsingDot-dot"
    />
  </div>
</template>

<script>
import { VTooltip } from 'floating-vue';

export default {
  name: 'KogPulsingDot',
  directives: {
    tooltip: VTooltip,
  },
  props: {
    dotId: {
      type: String,
      required: true,
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.KogPulsingDot {
  position: relative;
}

.KogPulsingDot-dot {
  position: absolute;

  width: 10px;
  height: 10px;

  background-color: var(--kogPlatformRedBase);
  box-shadow: 1px 1px 4px 1px color-mod(var(--kogPlatformGray000) alpha(34%));

  animation-name: stretch;
  animation-duration: 1.5s;
  animation-play-state: running;
  animation-timing-function: ease-out;
  animation-fill-mode: none;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes stretch {
  0% {
    transform: scale(0.7);
    border-radius: 100%;
  }

  50% {
    transform: scale(1);
    border-radius: 100%;
  }

  100% {
    transform: scale(0.7);
    border-radius: 100%;
  }
}
</style>
