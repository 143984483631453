import { getAllFocusableElements, getUserFocusableElements } from 'sharedApp/utils/dom-utils.js';

function handleTabKeyPressed(el, event) {
  if (!el.KogTrapFocusContext.isTrapActive) {
    return;
  }

  const userFocusableElements = getUserFocusableElements(el);
  if (userFocusableElements.length === 0) {
    // even if there are no focusable elements inside the modal,
    // we still don't want to focus elements outside of the modal
    event.preventDefault();
    return;
  }

  const focusableElements = getAllFocusableElements(el);
  const [firstUserFocusableElement] = userFocusableElements;
  const lastUserFocusableElement = userFocusableElements.at(-1);
  const currentFocusedElement = document.activeElement;

  if (event.shiftKey) {
    const isFocusBeforeFirst =
      focusableElements.indexOf(currentFocusedElement) <=
      focusableElements.indexOf(firstUserFocusableElement);

    if (isFocusBeforeFirst) {
      // if shift + tab pressed and
      // focus was outside the modal or lower than the first user focusable element in tabbing order
      lastUserFocusableElement.focus();
      event.preventDefault();
    }
  } else {
    const isFocusAfterLast =
      focusableElements.indexOf(currentFocusedElement) >=
      focusableElements.indexOf(lastUserFocusableElement);
    if (!focusableElements.includes(currentFocusedElement) || isFocusAfterLast) {
      // if tab key is pressed and
      // focus was outside the modal or higher than the last user focusable element in tabbing order
      firstUserFocusableElement.focus();
      event.preventDefault();
    }
  }
}

function keyEvent(event) {
  const el = this;
  const isTabPressed = event.key === 'Tab' || event.keyCode === 9;
  if (!isTabPressed) {
    return;
  }
  handleTabKeyPressed(el, event);
}

function isTrapActive(binding) {
  if (binding.value === undefined) {
    return true;
  }
  return !!binding.value;
}

export default {
  beforeMount(el, binding) {
    const keyDownHandler = keyEvent.bind(el);
    window.addEventListener('keydown', keyDownHandler);
    // eslint-disable-next-line no-param-reassign
    el.KogTrapFocusContext = {
      isTrapActive: isTrapActive(binding),
      cleanup() {
        window.removeEventListener('keydown', keyDownHandler);
      },
    };
  },
  updated(el, binding) {
    // eslint-disable-next-line no-param-reassign
    el.KogTrapFocusContext.isTrapActive = isTrapActive(binding);
  },
  unmounted(el) {
    el.KogTrapFocusContext.cleanup();
  },
};
