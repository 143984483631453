import { onBeforeMount, ref } from 'vue';

const uidCounter = ref(0);

export default function useUniqueId({ uidPrefix = 'uid-' } = {}) {
  const uid = ref('');

  onBeforeMount(() => {
    uidCounter.value += 1;
    uid.value = uidPrefix + uidCounter.value;
  });

  function getUniqueId(id: string) {
    return `${uid.value}-${id}`;
  }

  function getUniqueIdRef(id: string) {
    return `#${getUniqueId(id)}`;
  }

  return {
    uidPrefix,
    uid,
    getUniqueId,
    getUniqueIdRef,
  };
}
