import type { ToasterOptions } from 'sharedApp/store/toaster.types.ts';

import useToasterStore from 'sharedApp/store/toaster.ts';

const DEFAULT_TIME = 5000;

export default function useToaster() {
  const { addToaster, closeToaster } = useToasterStore();

  function show(message: string, options: Partial<ToasterOptions> = {}) {
    return addToaster({
      props: {
        toasterId: options.toasterId,
        toasterHtml: message,
        toasterType: options.toasterType ?? 'success',
        actionText: options.actionText,
        postActionText: options.postActionText,
      },
      events: {
        toasterActionPerformed: options.toasterActionPerformed,
        toasterClosedNoActionPressed: options.toasterClosedNoActionPressed,
      },
      toasterId: options.toasterId,
      time: options.time ?? DEFAULT_TIME,
    });
  }

  function showSuccess(message: string, options: Partial<ToasterOptions> = {}) {
    return show(message, { ...options, toasterType: 'success' });
  }

  function showError(message: string, options: Partial<ToasterOptions> = {}) {
    return show(message, { ...options, toasterType: 'error' });
  }

  function showInfo(message: string, options: Partial<ToasterOptions> = {}) {
    return show(message, { ...options, toasterType: 'info' });
  }

  function close(toasterId: string) {
    closeToaster(toasterId);
  }

  return {
    show,
    showSuccess,
    showError,
    showInfo,
    close,
  };
}
