import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_URLS = {
  'student-user-details': '/api/accounts/school_student/',
};
const subjectClassBaseURL = '/api/schools/:schoolId/subject_class/:subjectClassId/';
const schoolStudentURL = `${subjectClassBaseURL}students/:studentUserId/`;
const schoolStudentListURL = `${subjectClassBaseURL}students/`;
const schoolStudentToPath = compile(schoolStudentURL);
const schoolStudentListToPath = compile(schoolStudentListURL);

export async function fetchSchoolStudentList(schoolId, subjectClassId, studentUserIds = []) {
  const url = schoolStudentListToPath({ schoolId, subjectClassId });
  const params = new URLSearchParams();
  if (studentUserIds.length > 0) {
    params.set('student__user_id__in', studentUserIds.join(','));
  }
  const result = await axios.get(url, { params });
  return result.data;
}

export async function fetchSubjectClassStudent(schoolId, subjectClassId, studentUserId) {
  const url = schoolStudentToPath({ schoolId, subjectClassId, studentUserId });
  const result = await axios.get(url);
  return result.data;
}

export async function updateSubjectClassStudent(schoolId, subjectClassId, studentUserId, data) {
  const url = schoolStudentToPath({ schoolId, subjectClassId, studentUserId });
  const result = await axios.put(url, data);
  return result.data;
}

export async function fetchStudentDetails() {
  const result = await axios.get(API_URLS['student-user-details']);
  return result.data;
}
