/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-template */

function fetchIntercomScript() {
  const APP_ID = window.KOG.INTERCOM_APP_ID;

  // Script supplied by Intercom, slightly rewritten to fetch immediately
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://widget.intercom.io/widget/' + APP_ID;
    var x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
}

export default fetchIntercomScript;
