import { isEmpty } from 'lodash';

import {
  createTeacherNote,
  fetchTeachersNotes,
  updateTeacherNote,
} from '@apis/teacher-ltq-notes.js';

const namespaced = true;

const mutations = {
  setTeacherNote(state, { studentUserId, noteItem }) {
    if (isEmpty(state.teachersNotes) || isEmpty(state.teachersNotes[studentUserId])) {
      state.teachersNotes[studentUserId] = [noteItem];
      return;
    }
    const notesForStudent = state.teachersNotes[studentUserId];
    const teacherNoteIndex = notesForStudent.findIndex(note => note.id === noteItem.id);
    if (teacherNoteIndex === -1) {
      notesForStudent.push(noteItem);
    } else {
      notesForStudent[teacherNoteIndex] = noteItem;
    }
    state.teachersNotes[studentUserId] = notesForStudent;
  },
  setTeachersNotes(state, teachersNotes) {
    const indexByStudentUserId = (dict, note) => {
      if (!(note.student_user_id in dict)) {
        // eslint-disable-next-line no-param-reassign
        dict[note.student_user_id] = [];
      }
      dict[note.student_user_id].push(note);
      return dict;
    };
    state.teachersNotes = teachersNotes?.reduce(indexByStudentUserId, {});
  },
};

const getters = {
  getNoteForStudent: state => studentId => state.teachersNotes[studentId],
};

const actions = {
  async fetchTeachersNotes({ commit }, { subjectClassId, subjectNodeId, questionId }) {
    const teachersNotes = await fetchTeachersNotes({ subjectClassId, subjectNodeId, questionId });
    commit('setTeachersNotes', teachersNotes);
  },
  async createTeacherNote(
    { commit },
    { subjectClassId, subjectNodeId, questionId, studentId, text },
  ) {
    const noteItem = await createTeacherNote({
      subjectClassId,
      subjectNodeId,
      questionId,
      studentId,
      text,
    });
    commit('setTeacherNote', { studentUserId: noteItem.student_user_id, noteItem });
  },
  async updateTeacherNote(
    { commit },
    { noteId, subjectClassId, subjectNodeId, questionId, studentId, text },
  ) {
    const noteItem = await updateTeacherNote({
      noteId,
      subjectClassId,
      subjectNodeId,
      questionId,
      studentId,
      text,
    });
    commit('setTeacherNote', { studentUserId: noteItem.student_user_id, noteItem });
  },
};

const state = {
  teachersNotes: {},
};

export default {
  namespaced,
  actions,
  mutations,
  state,
  getters,
};
