import { fetchSchoolSummary } from '@apis/schools.js';

import reverse from 'learning/common/libs/kog-router.js';

import schoolAdminApi from 'schoolAdminApp/api/index.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const namespaced = true;

function getSubjectBySlug(slug) {
  return window.KOG.STAFF_CONSTANTS.SUBJECTS_BY_SLUG[slug];
}

const mutations = {
  setSubject(state, subject) {
    state.subject = subject;
  },
};

const actions = {
  fetchSubject({ commit }, { subjectSlug }) {
    const subject = getSubjectBySlug(subjectSlug);
    commit('setSubject', subject);
  },
  async saveClassDetails({ dispatch, commit }, { schoolId, subjectClassId, name, endDate }) {
    const data = { name, end_date: endDate };
    const result = await schoolAdminApi.updateSubjectClass(schoolId, subjectClassId, data);
    dispatch(
      'schoolstaffSubjectclassModule/fetchSubjectClass',
      { subjectClassId, useCache: false },
      { root: true },
    );
    commit('subjectClassModule/setSubjectClassName', { subjectClassId, name }, { root: true });
    return result;
  },
  async fetchSchoolSummary() {
    const data = await fetchSchoolSummary();
    return data;
  },
};

const state = {
  subject: null,
};

export default {
  namespaced,
  mutations,
  actions,
  state,
};
