import { compile } from 'path-to-regexp';

import { AccountsService, IntegrationsService } from '@apis/generated/services.ts';
import { makePoller } from '@apis/polling.ts';

import { NO_ACCESS_TOKEN_ERROR_MESSAGE, NoAccessTokenError } from 'sharedApp/const/integrations.ts';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const schoolstaffIntegrationsBaseUrl =
  '/api/schoolstaff/lms_integration/:integrationSlug/classes/:subjectClassId/';
const SCHOOLSTAFF_API_URLS = {
  'lms-integration-classes': compile(schoolstaffIntegrationsBaseUrl),
  'lms-integration-class': compile(
    `${schoolstaffIntegrationsBaseUrl}upstream_classes/:upstreamClassId/`,
  ),
};
const integrationsBaseUrl = '/api/integrations/:integrationSlug/';

export async function updateIntegrationClass(
  integrationSlug,
  subjectClassId,
  upstreamClassId,
  data,
) {
  const url = SCHOOLSTAFF_API_URLS['lms-integration-class']({
    integrationSlug,
    subjectClassId,
    upstreamClassId,
  });
  const response = await axios.put(url, data);
  return response.data;
}

export async function associateIntegrationClasses(integrationSlug, subjectClassId, data) {
  const url = SCHOOLSTAFF_API_URLS['lms-integration-classes']({
    integrationSlug,
    subjectClassId,
  });
  const response = await axios.put(url, data);
  return response.data;
}

export async function disassociateIntegrationClasses(integrationSlug, subjectClassId, data) {
  const url = SCHOOLSTAFF_API_URLS['lms-integration-classes']({
    integrationSlug,
    subjectClassId,
  });
  const response = await axios.delete(url, data);
  return response.data;
}

export async function fetchLmsClassesCategories(integrationSlug, subjectId) {
  const pollIntegrationClassesCategories = makePoller(
    IntegrationsService.startIntegrationClassesCategoriesPoll,
    IntegrationsService.getIntegrationClassesCategories,
  );
  try {
    return await pollIntegrationClassesCategories({
      integrationSlug,
      subjectId,
    });
  } catch (error) {
    const apiErrorMessage = error?.body?.detail;
    if (apiErrorMessage === NO_ACCESS_TOKEN_ERROR_MESSAGE) {
      throw new NoAccessTokenError();
    }
    throw error;
  }
}

export async function fetchMyIntegrationData() {
  const integrationData = await AccountsService.accountsMeIntegrationDataRetrieve();
  return integrationData;
}
