import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_PATHS = {
  'student-subject-annotations-search': compile(
    '/api/study/subjects/:subjectId/annotations/search/',
  ),
  'search-subjectnode-in-subject': compile('/api/study/subjects/:subjectId/search/'),
};

export const searchInBook = async (subjectId, query) => {
  const path = API_PATHS['search-subjectnode-in-subject']({ subjectId });
  const response = await axios.get(`${path}?query=${query}`);
  return response.data;
};

export const searchInAnnotations = async (subjectId, query) => {
  const path = API_PATHS['student-subject-annotations-search']({ subjectId });
  const response = await axios.get(`${path}?query=${query}`);
  return response.data;
};
