<template>
  <div>
    <loader-indicator
      v-if="$wait.is('loading_user')"
      :loading="$wait.is('loading_user')"
      loading-msg="Loading your data..."
    />
    <template v-else>
      <router-view />
      <modals-container />
    </template>
  </div>
</template>

<script>
import 'sharedApp/libs/sentry-vue.js';
import 'sharedApp/styles/index.js';

import { mapWaitingActions } from 'vue-wait';
import { mapGetters, mapState } from 'vuex';

import { initHelpHero } from 'learning/common/services/helphero/help-hero-service.js';

import ModalsContainer from 'sharedApp/components/modals/modals-container.vue';
import DjangoSettings from 'sharedApp/django-settings-module.ts';
import brazeModule from 'sharedApp/libs/braze.js';
import intercomModule from 'sharedApp/libs/intercom.js';
import posthog, { FEATURE_FLAGS } from 'sharedApp/libs/posthog.ts';
import { identifyUser as sentryIdentifyUser } from 'sharedApp/libs/sentry-utils.js';
import { registerApiErrorHandler } from 'sharedApp/utils/api-error-handler.ts';
import { registerNewVersionNotifications } from 'sharedApp/utils/new-version-notifier.ts';
import { popPosthogToContinueRecording } from 'sharedApp/utils/posthog-utils.js';

import '../../vendor.js';

import { onMounted } from 'vue';

import LoaderIndicator from 'sharedApp/components/base/indicators/kog-loader.vue';
import useToaster from 'sharedApp/composables/use-toaster.ts';
import { sendClassicLoginTracking } from 'sharedApp/utils/login-local-storage.js';

import useOnboardingTours from './composables/use-onboarding-tours.ts';

export default {
  name: 'App',
  components: { LoaderIndicator, ModalsContainer },
  setup() {
    onMounted(() => {
      useOnboardingTours();
    });
  },
  computed: {
    ...mapState({
      user: state => state.userModule.user,
    }),
    ...mapGetters('userModule', ['isDemoUser', 'demoType', 'enableTrialCTA']),
  },
  async created() {
    await this.fetchUser();
    sentryIdentifyUser(this.user.id);
    this.initMixpanel();
    this.displayDjangoMessages();
    this.initHelpHeroTours();
    this.initPosthog();
    intercomModule.start(this.user);
    brazeModule.init();
    registerNewVersionNotifications(useToaster());
    registerApiErrorHandler();
    sendClassicLoginTracking(this.$mixpanel);
  },
  methods: {
    ...mapWaitingActions('userModule', {
      fetchUser: 'loading_user',
    }),
    initHelpHeroTours() {
      if (this.user.isTeacher() && this.isDemoUser) {
        initHelpHero(this.user.id, {
          role: this.user.isTeacher() ? 'teacher' : 'student',
          is_demo: this.isDemoUser,
          enable_trial_cta: this.enableTrialCTA,
        });
      }
    },
    initMixpanel() {
      const yearOfGraduation = window.KOG.USER_CONSTANTS.User.year_of_graduation;
      this.$mixpanel.init(DjangoSettings.MIXPANEL.AUTH_TOKEN, window.KOG.USER_CONSTANTS);
      this.$mixpanel.setupUserProperties({
        year_of_graduation: yearOfGraduation,
        school_is_demo: this.isDemoUser,
        demo_type: this.demoType,
      });
      this.$mixpanel.registerCounterProperty('eventcounter_startstrengthtest');
      if (this.user.school) {
        this.$mixpanel.setupUserProperties({
          school_id: this.user.school.id,
          school_name: this.user.school.name,
        });
      }
    },
    initPosthog() {
      posthog.init(
        this.user.id,
        {
          role: this.user.isTeacher() ? 'teacher' : 'student',
          is_demo: this.isDemoUser,
          is_internal_user: posthog.isInternalUser(this.user.email, this.user.school?.name || ''),
          created_at: this.user.date_joined,
        },
        {
          disable_session_recording: true,
        },
      );
      if (
        popPosthogToContinueRecording() ||
        posthog.isFeatureEnabled(FEATURE_FLAGS.studentSessionReplayEnabled)
      ) {
        posthog.startSessionRecording();
      }
    },
    displayDjangoMessages() {
      const toastDelayMs = 30000;
      if ('DJANGO_MESSAGES' in window.KOG) {
        window.KOG.DJANGO_MESSAGES.forEach(message => {
          this.$toast.showError(message.message, null, {
            time: toastDelayMs,
          });
        });
      }
    },
  },
};
</script>
