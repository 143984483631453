import * as Sentry from '@sentry/vue';

import type { InternalAxiosRequestConfig } from 'axios';

export function externalRequestInterceptor(request: InternalAxiosRequestConfig) {
  if (!request.url) return request;
  const internalOrigin = new URL(document.baseURI).origin;
  const requestOrigin = new URL(request.url, document.baseURI).origin;
  if (requestOrigin !== internalOrigin) {
    Sentry.captureMessage(
      `Security: external request using kog-axios detected. Migrate usage to external-axios. URL=${request.url}`,
    );
  }
  return request;
}
