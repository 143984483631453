import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_PATHS = {
  activity: '/api/study/activity/',
  aggregated_activity: '/api/study/activity/aggregated/',
};

export const fetchAggregatedActivity = async () => {
  const url = API_PATHS.aggregated_activity;
  const response = await axios.get(url);
  return response.data;
};

export const fetchActivity = async () => {
  const url = API_PATHS.activity;
  const response = await axios.get(url);
  return response.data;
};
