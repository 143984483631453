import {
  getFtbUIDsInOrder,
  isFBQ,
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';

function hasUserAnswerObject(practiceItem) {
  return practiceItem && practiceItem.user_answer;
}

export function isAnswered(practiceItem, question) {
  if (!practiceItem) {
    return false;
  }
  if (isMCQ(question)) {
    return !!practiceItem?.answerToSubmit && practiceItem.answerToSubmit?.length > 0;
  }
  if (isSTQ(question)) {
    return !!practiceItem?.answerToSubmit && practiceItem.answerToSubmit?.length > 0;
  }
  if (isFBQ(question)) {
    return (
      !!practiceItem.answerToSubmit &&
      Object.keys(practiceItem.answerToSubmit).every(
        blank => practiceItem.answerToSubmit[blank].length > 0,
      )
    );
  }
  return false;
}

export function isSubmitted(practiceItem) {
  if (!practiceItem) {
    return false;
  }
  return practiceItem.is_submitted === true;
}

export function getAnswer(practiceItem, question) {
  if (isMCQ(question)) {
    return practiceItem.user_answer?.user_response;
  }

  if (isSTQ(question)) {
    return practiceItem.user_answer.user_text;
  }

  if (isFBQ(question)) {
    return practiceItem.user_answer.user_answers;
  }

  return null;
}

export function studentAnswer(practiceItem) {
  const noAnswerGivenText = 'No answer given';

  if (isFBQ(practiceItem.question)) {
    const orderedBlanks = getFtbUIDsInOrder(practiceItem.question.question_html);
    let formattedAnswer = '';

    orderedBlanks.forEach((blankId, index) => {
      const isFirst = index === 0;

      const hasUserAnswered = Boolean(practiceItem.user_answer.user_answers[blankId]);
      const userAnswer = hasUserAnswered
        ? practiceItem.user_answer.user_answers[blankId]
        : noAnswerGivenText;

      if (isFirst) {
        formattedAnswer = `Blank #${index + 1}: ${userAnswer}`;
      } else {
        formattedAnswer = `${formattedAnswer}\nBlank #${index + 1}: ${userAnswer}`;
      }
    });

    return formattedAnswer;
  }
  if (isMCQ(practiceItem.question)) {
    const hasUserAnswered = practiceItem.user_answer?.user_response?.length > 0;
    if (!hasUserAnswered) {
      return noAnswerGivenText;
    }

    const userResponse = practiceItem.user_answer.user_response;
    const choiceAnswers = userResponse.map(
      userChoiceId =>
        practiceItem.question.multiplechoicequestionchoice_set.find(
          choice => choice.id === userChoiceId,
        ).answer_html,
    );

    return choiceAnswers.length > 0 ? choiceAnswers.join('') : noAnswerGivenText;
  }
  if (isSTQ(practiceItem.question)) {
    const hasUserAnswered = Boolean(practiceItem.user_answer.user_text);
    return hasUserAnswered ? practiceItem.user_answer.user_text : noAnswerGivenText;
  }

  return null;
}
