import type { ModalOptions } from 'learning/common/store-modules/modal.types.ts';
import type { ComponentProps } from 'sharedApp/types.ts';
import type { Component, Plugin } from 'vue';

import useModal from 'sharedApp/composables/use-modal.ts';

const ModalPlugin: Plugin<Partial<ModalOptions>> = {
  install(app, options) {
    const { openModal } = useModal(options);

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$modal = (
      componentInstance: Component,
      props: ComponentProps = {},
      modalOptions: Partial<ModalOptions> = {},
    ) => {
      openModal(componentInstance, props, modalOptions);
    };
  },
};

export default ModalPlugin;
