import type { AxiosError } from 'axios';

export const API_ERROR_EVENT_NAME = 'apierror';

export class ApiErrorEvent extends CustomEvent<AxiosError> {
  constructor(error: AxiosError) {
    super(API_ERROR_EVENT_NAME, {
      detail: error,
    });
  }
}

export function dispatchApiErrorInterceptor(error: AxiosError): Promise<never> {
  const event = new ApiErrorEvent(error);
  document.dispatchEvent(event);

  return Promise.reject(error);
}
