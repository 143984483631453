import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const baseApiUrl = '/api/performance-tasks/';
const performanceTaskUrlToPath = compile(`${baseApiUrl}:performanceTaskId/`);

export default {
  async createInstance({ featureTitle: performanceTaskTitle }) {
    const response = await axios.post(baseApiUrl, {
      title: performanceTaskTitle,
    });
    return response.data;
  },
  async removeInstance(performanceTaskId) {
    const url = performanceTaskUrlToPath({ performanceTaskId });
    const response = await axios.delete(url);
    return response.data;
  },
  async fetchInstance(performanceTaskId) {
    const url = performanceTaskUrlToPath({ performanceTaskId });
    const response = await axios.get(url);
    return response.data;
  },
};
