function getFocusableElements(parent, selector) {
  let focusableElements = parent.querySelectorAll(selector);
  if (focusableElements.length > 0) {
    focusableElements = Array.from(focusableElements).filter(
      el =>
        !el.hasAttribute('disabled') &&
        el.offsetParent &&
        !['hidden', 'collapse'].includes(el.style.visibility),
    );
  }
  return focusableElements;
}

function getUserFocusableElements(parent) {
  const selector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  return getFocusableElements(parent, selector);
}

function getAllFocusableElements(parent, specificElementSelector = '') {
  const elementsToSelect = ['button', '[href]', 'input', 'select', 'textarea', '[tabindex]'];
  const selector = elementsToSelect.map(
    elementSelector => `${elementSelector}${specificElementSelector}`,
  );
  return getFocusableElements(parent, selector);
}

function getFirstFocusableElement(parent) {
  const focusableElements = getAllFocusableElements(parent);
  let firstFocusableElement;
  if (focusableElements.length > 0) {
    [firstFocusableElement] = focusableElements;
  }

  return firstFocusableElement;
}

function getMaxZIndexFromAncestors(element) {
  let parent = element;
  let maxZIndex = -999;

  while (parent) {
    try {
      const parentZIndexString = getComputedStyle(parent).zIndex;
      const isANumber = !Number.isNaN(Number(parentZIndexString));
      if (isANumber) {
        const parentZIndex = Number(parentZIndexString);
        maxZIndex = Math.max(maxZIndex, parentZIndex);
      }
    } catch {
      /* In case getComputedStyle is called on Window or
         other non-DOM element, return max so far */
      return maxZIndex;
    }
    parent = parent.parentElement;
  }

  return maxZIndex;
}

/* eslint-disable no-param-reassign */
function setWidth(element, width) {
  element.style.width = width;
}

function setHeight(element, height) {
  element.style.height = height;
}
/* eslint-enable no-param-reassign */

function setSize(element, width, height) {
  setWidth(element, width);
  setHeight(element, height);
}

export {
  getUserFocusableElements,
  getAllFocusableElements,
  getFirstFocusableElement,
  getMaxZIndexFromAncestors,
  setSize,
  setWidth,
  setHeight,
};
