import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_PATHS = {
  'strength-occasion-detail': compile('/api/study/strength_occasions/:occasionId/'),
  'subject-strength-occasion-list': compile('/api/study/subjects/:subjectId/strength_occasions/'),
};

export async function createStrengthOccasion(subjectId, selectedNodes, questionQuantity) {
  const url = API_PATHS['subject-strength-occasion-list']({ subjectId });
  const response = await axios.post(url, {
    selected_nodes: selectedNodes,
    question_quantity: questionQuantity,
  });
  return response.data;
}

export async function fetchPracticeOccasion(occasionId) {
  const url = API_PATHS['strength-occasion-detail']({ occasionId });
  const response = await axios.get(url);
  return response.data;
}

export async function savePracticeOccasion(occasionId, data) {
  const url = API_PATHS['strength-occasion-detail']({ occasionId });
  const response = await axios.put(url, data);
  return response.data;
}
