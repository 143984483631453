import * as Sentry from '@sentry/vue';

import { registerLastActivity, registerSectionActivity } from 'studyApp/api/activity.js';

export default {
  namespaced: true,
  actions: {
    async registerSectionActivity(context, subjectNodeId) {
      try {
        await registerSectionActivity(subjectNodeId);
      } catch (error) {
        Sentry.addBreadcrumb({
          level: 'warning',
          type: 'error',
          category: 'activity.error',
          message: 'Failed to register section activity',
          data: error,
          timestamp: Date.now() / 1000,
        });
      }
    },
    registerLastActivity(context, subjectId) {
      try {
        registerLastActivity(subjectId);
      } catch (error) {
        Sentry.captureMessage(`registerLastActivity: ${error}`);
      }
    },
  },
};
