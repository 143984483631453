import KogClickable from './kog-clickable.js';
import KogDescription from './kog-description.js';
import KogFocusable from './kog-focusable.js';
import KogMathjax from './kog-mathjax.js';
import kogNewDot from './kog-new-dot.js';
import KogTrapFocus from './kog-trap-focus.js';

const install = app => {
  app.directive('kog-clickable', KogClickable);
  app.directive('kog-description', KogDescription);
  app.directive('kog-focusable', KogFocusable);
  app.directive('kog-new-dot', kogNewDot);
  app.directive('kog-trap-focus', KogTrapFocus);
  app.directive('kog-mathjax', KogMathjax);
};

export default install;
