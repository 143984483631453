<script setup lang="ts">
import { storeToRefs } from 'pinia';

import KogDefaultToaster from 'sharedApp/components/base/toasters/kog-default-toaster.vue';
import useToasterStore from 'sharedApp/store/toaster.ts';

const { toastersArray } = storeToRefs(useToasterStore());
const { delayToaster, clearToasterTimeout, removeToaster } = useToasterStore();
</script>

<template>
  <div class="KogToasterContainer z-index-20">
    <div class="KogToasterContainer__visibleArea">
      <kog-default-toaster
        v-for="toaster in toastersArray"
        :key="toaster.toasterId"
        :class="['slideIn', { shake: toaster.shake }]"
        v-bind="toaster.props"
        v-on="toaster.events"
        @toaster-destroy="removeToaster(toaster.toasterId)"
        @toaster-mouse-enter="clearToasterTimeout(toaster)"
        @toaster-mouse-leave="delayToaster(toaster)"
      />
    </div>
  </div>
</template>

<style scoped>
.KogToasterContainer {
  position: fixed;
  top: 0;
  width: 100%;
  /* setting height to 0 to avoid the container blocking part of the page */
  height: 0;
}

.KogToasterContainer__visibleArea {
  max-width: 600px;
  height: 0;
  margin: auto;
}
</style>

<style>
.KogToasterContainer__visibleArea > div {
  position: relative;
  top: 55px;
  margin: 10px;
  box-shadow: 0 16px 32px -8px var(--kogShadow020);
}

.KogToasterContainer__visibleArea > div.slideIn {
  animation-name: slideIn;
  animation-duration: 0.8s;
  animation-timing-function: cubic-bezier(0.34, 1.19, 1, 1);
}

@keyframes slideIn {
  from {
    top: -50px;
  }

  to {
    top: 55px;
  }
}

/* inspired by
https://css-tricks.com/snippets/css/shake-css-keyframe-animation/ */

.KogToasterContainer__visibleArea > div.shake {
  animation-name: slideIn, shake;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }

  40%,
  60% {
    transform: translateX(4px);
  }
}
</style>
