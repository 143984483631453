const {
  isSTQ,
  isMCQ,
  isFBQ,
  isLTQ,
} = require('sharedApp/services/questions/questionUtilityService/question-utility-service.js');

export const QUESTION_MAPPING_LEVEL_LIMIT = 4;
export const NGSS_EDUCATION_SYSTEM = 'NGSS';
export const IBDP_EDUCATION_SYSTEM = 'IBDP';
export const IGCSE_EDUCATION_SYSTEM = 'IGCSE';

const TERM_LIBRARIES = {
  [NGSS_EDUCATION_SYSTEM]: {
    topic: 'unit',
    level1: 'unit',
    subtopic: 'module',
    level2: 'module',
    section: 'lesson',
    level3: 'lesson',
    subsection: 'content option',
    level4: 'content option',
    'exam-style': 'Exam-style',
  },
  [IBDP_EDUCATION_SYSTEM]: {
    topic: 'topic',
    level1: 'topic',
    subtopic: 'subtopic',
    level2: 'subtopic',
    section: 'section',
    level3: 'section',
    subsection: 'subsection',
    level4: 'subsection',
    core: 'SL',
    extended: 'HL',
    'exam-style': 'Exam-style',
  },
  [IGCSE_EDUCATION_SYSTEM]: {
    topic: 'topic',
    level1: 'topic',
    subtopic: 'subtopic',
    level2: 'subtopic',
    section: 'section',
    level3: 'section',
    subsection: 'subsection',
    level4: 'subsection',
    core: 'Core',
    extended: 'Extended',
    'exam-style': 'Test practice',
  },
  default: {
    topic: 'topic',
    level1: 'topic',
    subtopic: 'subtopic',
    level2: 'subtopic',
    section: 'section',
    level3: 'section',
    subsection: 'subsection',
    level4: 'subsection',
    core: 'Core',
    extended: 'Extended',
    'exam-style': 'Exam-style',
  },
};

export function isNgss(educationSystemName) {
  return educationSystemName === NGSS_EDUCATION_SYSTEM;
}

export function isIBDP(educationSystemName) {
  return educationSystemName === IBDP_EDUCATION_SYSTEM;
}

export function isIGCSE(educationSystemName) {
  return educationSystemName === IGCSE_EDUCATION_SYSTEM;
}

export function hasEducationSystemMarks(educationSystemName) {
  return !isNgss(educationSystemName);
}

export function hasEducationSystemMarkscheme(educationSystemName) {
  return !isNgss(educationSystemName);
}

export function hasEducationSystemPapertype(educationSystemName) {
  return !isNgss(educationSystemName);
}

export function hasFeatureExerciseQuestion(educationSystemName, question) {
  return isNgss(educationSystemName) || isSTQ(question) || isMCQ(question) || isFBQ(question);
}

export function getTerm(term, educationSystem) {
  let termLibrary = TERM_LIBRARIES.default;
  if (Object.keys(TERM_LIBRARIES).includes(educationSystem)) {
    termLibrary = TERM_LIBRARIES[educationSystem];
  }

  return termLibrary[term.toLowerCase()];
}

export function filterCompatibleSubjectsForMapping(questions, currentSubject, subjects) {
  if (!questions.some(q => isLTQ(q))) {
    return subjects;
  }

  const educationSystemName = currentSubject.educationsystem.name;
  if (isNgss(educationSystemName)) {
    return subjects.filter(subject => isNgss(subject.educationsystem_name));
  }

  return subjects.filter(subject => !isNgss(subject.educationsystem_name));
}
