import type { Plugin } from 'vue';

import KogToasterContainer from 'sharedApp/components/base/toasters/kog-toaster-container.vue';
import useToaster from 'sharedApp/composables/use-toaster.ts';
import mountComponent from 'sharedApp/utils/mount-component.ts';

const ToasterPlugin: Plugin = {
  install(app) {
    const existingToasterContainer = document.getElementsByClassName('KogToasterContainer');

    const { el } = mountComponent({
      component: KogToasterContainer,
    });

    if (el && existingToasterContainer.length === 0) {
      switch (document.readyState) {
        case 'loading':
          document.addEventListener('DOMContentLoaded', () => {
            document.body.appendChild(el);
          });
          break;
        case 'interactive':
        case 'complete':
        default:
          document.body.appendChild(el);
          break;
      }
    }

    const { show, showSuccess, showError, showInfo, close } = useToaster();

    /**
     * Plugin Composition API
     */

    app.provide('toast', {
      showSuccess,
      showError,
      showInfo,
      show,
      close,
    });

    /**
     * Plugin Options API
     */

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$toast = {
      showSuccess,
      showError,
      showInfo,
      show,
      close,
    };
  },
};

export default ToasterPlugin;
