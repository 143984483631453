import isNil from 'lodash/isNil.js';
import keyBy from 'lodash/keyBy.js';

import * as api from '@apis/performance-tasks.js';

import { postImageToS3SignedUrl, UPLOAD_STATUS } from 'learning/study/utils/image-upload-utils.js';

import { BLOCK_TYPE } from 'publishingApp/store/modules/feature/activity-constants.ts';
import { FILE_STATUS } from 'sharedApp/components/image-upload/file-status.ts';
import RESPONSE_TYPES from 'sharedApp/const/response-types.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const defaultState = {
  performanceTask: null,
  ownOccasion: null,
  isCreatingOccasion: false,
  classOccasions: [],
  selectedStudentId: null,
  responseQueueProcesses: [],
};

const RESPONSE_EVENT_TYPE = Object.freeze({
  CREATE: 'create',
  UPDATE: 'update',
});

const EVENT_TIMER_DELAY_MS = 100;
const createProcessingResponseTimer = (commit, delayMS = 0) => {
  const eventId = window.crypto.randomUUID();
  const timer = setTimeout(() => {
    commit('addResponseQueueProcessEventId', eventId);
  }, delayMS);
  const clear = () => {
    clearTimeout(timer);
    commit('removeResponseQueueProcessEventId', eventId);
  };

  return clear;
};

const asyncResponseMap = new Map();
const addToAsyncResponseMap = data => {
  if (!asyncResponseMap.has(data.response.questionId)) {
    asyncResponseMap.set(data.response.questionId, {});
  }

  const existing = asyncResponseMap.get(data.response.questionId);
  asyncResponseMap.set(data.response.questionId, {
    ...data,
    event: existing?.event ? existing.event : data.event,
  });
};

const processAsyncResponseMap = async dispatch => {
  await Promise.all(
    [...asyncResponseMap.keys()].map(questionId => {
      const responseData = asyncResponseMap.get(questionId);
      asyncResponseMap.delete(questionId);
      if (responseData.event === RESPONSE_EVENT_TYPE.UPDATE) {
        return dispatch('updateOccasionResponse', responseData.response);
      }

      return dispatch('createOccasionResponse', responseData.response);
    }),
  );
};

const populateStateResponse = async (response, handleCommit) => {
  if (response.response_type !== RESPONSE_TYPES.IMR) {
    handleCommit(response);
    return;
  }

  handleCommit({
    ...response,
    user_response: {
      file: null,
      status: FILE_STATUS.DOWNLOADING,
    },
  });

  let file;
  let status;
  try {
    const imageResponse = await axios.get(response.signed_image_url, { responseType: 'blob' });
    file = new File([imageResponse.data], response.user_response);
    status = FILE_STATUS.DONE;
  } catch {
    file = null;
    status = FILE_STATUS.ERROR;
  }

  const responseWithFetchedImage = {
    ...response,
    user_response: {
      file,
      status,
    },
  };

  handleCommit(responseWithFetchedImage);
};

const extractPayloadByResponseType = clientResponse => {
  const responseToSave = {
    ...clientResponse,
    question_id: clientResponse.questionId,
  };

  if (clientResponse.response_type === RESPONSE_TYPES.IMR) {
    responseToSave.s3_object_type = clientResponse?.user_response?.file?.type;
    responseToSave.user_response = clientResponse?.user_response?.file?.name;
  }

  return responseToSave;
};

const uploadPendingImageResponse = async ({ signedUrl, file, onProgress, onError }) => {
  let hasError = false;

  if (signedUrl && file instanceof File) {
    try {
      await postImageToS3SignedUrl(file, signedUrl, onProgress);
    } catch {
      onError();
      hasError = true;
    }
  }

  return [hasError];
};

export const PT_GRADING_STATUS = Object.freeze({
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  NOT_GRADED: 'NOT_GRADED',
  GRADES_NOT_SENT: 'GRADES_NOT_SENT',
  GRADES_UPDATED_NOT_SENT: 'GRADES_UPDATED_NOT_SENT',
  GRADES_SENT: 'GRADES_SENT',
});

function ptGradingStatus(occasion) {
  const {
    latest_question_graded_at: gradedAt,
    grade_sent_at: gradeSentAt,
    submitted_at: submittedAt,
  } = occasion;
  if (gradeSentAt) {
    if (gradedAt > gradeSentAt) {
      return PT_GRADING_STATUS.GRADES_UPDATED_NOT_SENT;
    }
    return PT_GRADING_STATUS.GRADES_SENT;
  }
  if (gradedAt) {
    return PT_GRADING_STATUS.GRADES_NOT_SENT;
  }
  if (submittedAt) {
    return PT_GRADING_STATUS.NOT_GRADED;
  }
  return PT_GRADING_STATUS.NOT_SUBMITTED;
}

const actions = {
  async fetchPerformanceTask({ commit }, performanceTaskId) {
    const performanceTask = await api.fetchPerformanceTask(performanceTaskId);
    commit('setPerformanceTask', performanceTask);
  },
  async fetchOwnOccasion({ commit }, { performanceTaskId, subjectClassId }) {
    commit('setClassOccasions', []);
    const occasions = await api.fetchPerformanceTaskOccasions(performanceTaskId, subjectClassId);
    const occasion = { ...(occasions?.[0] ?? { responses: [] }) };

    commit('setOwnOccasion', {
      ...occasion,
      responses: [],
    });

    if (occasion?.responses?.length > 0) {
      const handleCommit = responseToCommit => commit('setOwnOccasionResponse', responseToCommit);
      occasion.responses.forEach(response => {
        populateStateResponse(response, handleCommit);
      });
    }
  },
  async fetchClassOccasions({ commit }, { performanceTaskId, subjectClassId }) {
    commit('setOwnOccasion', null);
    const occasions = await api.fetchPerformanceTaskOccasions(
      performanceTaskId,
      subjectClassId,
      true,
    );

    commit(
      'setClassOccasions',
      occasions.map(occasion => ({
        ...occasion,
        responses: occasion.responses.map(response => ({
          id: response.id,
        })),
      })),
    );

    const commiter = responseToCommit => commit('partialUpdateOccasionResponse', responseToCommit);
    occasions.forEach(occasion => {
      if (occasion.responses.length === 0) return;
      occasion.responses.forEach(response => populateStateResponse(response, commiter));
    });
  },
  async createOccasion(
    { commit, state, dispatch },
    { performanceTaskId, subjectClassId, userId = null },
  ) {
    if (state.isCreatingOccasion) {
      return null;
    }
    if (!userId) {
      commit('setOwnOccasion', { id: 'temp', responses: [] });
    }

    let occasion = null;
    try {
      commit('setIsCreatingOccasion', true);
      occasion = await api.createPerformanceTaskOccasion(performanceTaskId, subjectClassId, userId);
      if (!userId) {
        commit('setOwnOccasion', {
          ...occasion,
          responses: [...state.ownOccasion.responses, ...occasion.responses],
        });
      } else {
        commit('addClassOccasion', occasion);
      }
    } finally {
      commit('setIsCreatingOccasion', false);
    }

    processAsyncResponseMap(dispatch);
    return occasion.id;
  },

  async createOccasionResponseForStudent({ commit }, userResponse) {
    const { occasionId, questionId } = userResponse;
    const responseToSave = extractPayloadByResponseType(userResponse);
    delete responseToSave.occasionId;

    commit('setStudentOccasionResponse', {
      occasionId,
      newResponse: { ...responseToSave, id: 'temp' },
    });

    const response = await api.createPerformanceTaskOccasionResponse(
      occasionId,
      questionId,
      responseToSave,
    );

    commit('setStudentOccasionResponse', { occasionId, newResponse: response });
    return response.id;
  },

  async createOccasionResponse({ commit, state, dispatch, getters }, userResponse) {
    const responseToSave = extractPayloadByResponseType(userResponse);

    commit('setOwnOccasionResponse', {
      question_id: userResponse.questionId,
      ...userResponse,
    });

    if (state.isCreatingOccasion) {
      addToAsyncResponseMap({
        event: RESPONSE_EVENT_TYPE.CREATE,
        response: userResponse,
      });
      return;
    }

    const clear = createProcessingResponseTimer(commit, EVENT_TIMER_DELAY_MS);
    try {
      const apiResponse = await api.createPerformanceTaskOccasionResponse(
        state.ownOccasion.id,
        userResponse.questionId,
        responseToSave,
      );

      const existingResponse = getters.currentOccasionResponseByQuestionId[userResponse.questionId];
      commit('setOwnOccasionResponse', {
        ...existingResponse,
        id: apiResponse.id,
      });

      if (userResponse.response_type === RESPONSE_TYPES.IMR) {
        await dispatch('handleImageUpload', {
          clientResponse: userResponse,
          apiResponse,
        });
        return;
      }

      commit('setOwnOccasionResponse', {
        ...existingResponse,
        ...apiResponse,
        user_response: userResponse.user_response,
      });
    } finally {
      clear();
      processAsyncResponseMap(dispatch);
    }
  },

  async handleImageUpload({ dispatch }, { clientResponse, apiResponse }) {
    const [hasError] = await uploadPendingImageResponse({
      signedUrl: apiResponse?.signed_url,
      file: clientResponse.user_response?.file,
      onProgress: clientResponse?.onProgress,
      onError: clientResponse?.onError,
    });

    if (hasError) {
      await dispatch('deleteOccasionResponse', { questionId: clientResponse.questionId });
      return;
    }

    const updatedResponse = {
      ...apiResponse,
      status: UPLOAD_STATUS.UPLOADED,
      user_response: {
        ...clientResponse.user_response,
        status: FILE_STATUS.DONE,
      },
    };
    dispatch('updateOccasionResponse', {
      questionId: clientResponse.questionId,
      ...updatedResponse,
    });
  },
  async updateOccasionResponse({ commit, state, getters, dispatch }, userResponse) {
    const oldResponse = getters.currentOccasionResponseByQuestionId[userResponse.questionId];
    if (!oldResponse) {
      throw new Error('Invalid response to update');
    }
    const responseData = {
      ...oldResponse,
      question_id: userResponse.questionId,
      ...userResponse,
    };
    commit('setOwnOccasionResponse', responseData);

    if (
      state.isCreatingOccasion ||
      !oldResponse.id ||
      asyncResponseMap.has(userResponse.questionId)
    ) {
      addToAsyncResponseMap({
        event: RESPONSE_EVENT_TYPE.UPDATE,
        response: userResponse,
      });
      return;
    }

    if (!userResponse.user_response && userResponse.response_type === RESPONSE_TYPES.IMR) {
      dispatch('deleteOccasionResponse', { questionId: userResponse.questionId });
      return;
    }

    const responsePayloadToUpdate = extractPayloadByResponseType(userResponse);

    const clear = createProcessingResponseTimer(commit, EVENT_TIMER_DELAY_MS);
    try {
      const newResponse = await api.updatePerformanceTaskOccasionResponse(
        state.ownOccasion.id,
        oldResponse.id,
        userResponse.questionId,
        responsePayloadToUpdate,
      );

      const existingResponse = getters.currentOccasionResponseByQuestionId[userResponse.questionId];
      commit('setOwnOccasionResponse', {
        ...existingResponse,
        id: newResponse.id,
      });
    } finally {
      clear();
    }
  },
  async deleteOccasionResponse({ commit, state, getters }, { questionId, useBeacon = false }) {
    const response = getters.currentOccasionResponseByQuestionId[questionId];
    if (!response) {
      return;
    }

    if (useBeacon) {
      api.beaconDeletePerformanceTaskOccasionResponse(state.ownOccasion.id, response.id);
      return;
    }

    await api.deletePerformanceTaskOccasionResponse(state.ownOccasion.id, response.id);
    commit('clearOccasionResponse', questionId);
  },

  async markOccasionResponse({ commit }, { performanceTaskOccasionId, responseId, marks }) {
    const newResponse = await api.markPerformanceTaskOccasionResponse(
      performanceTaskOccasionId,
      responseId,
      marks,
    );

    commit('partialUpdateOccasionResponse', {
      id: newResponse.response_id,
      grading: newResponse.grading,
    });
    commit('updateOccasion', {
      id: performanceTaskOccasionId,
      latest_question_graded_at: newResponse.response_graded_at,
    });
  },
  async submitPerformanceTaskOccasionForStudent({ commit }, { performanceTaskOccasionId }) {
    const occasion = await api.submitPerformanceTaskOccasion(performanceTaskOccasionId);
    commit('updateOccasion', { ...occasion, occasion });

    if (occasion?.responses?.length > 0) {
      const handleCommit = responseToCommit =>
        commit('partialUpdateOccasionResponse', responseToCommit);
      occasion.responses.forEach(response => {
        populateStateResponse(response, handleCommit);
      });
    }
  },
  async submitOwnOccasion({ commit, state }) {
    const occasion = await api.submitPerformanceTaskOccasion(state.ownOccasion.id);
    commit('setOwnOccasion', {
      ...occasion,
      responses: state.ownOccasion.responses,
    });
  },
  async sendGradesForOccasion(
    { commit },
    { performanceTaskId, subjectClassId, performanceTaskOccasionId },
  ) {
    const occasion = await api.sendPerformanceTaskOccasionGrades(
      performanceTaskId,
      subjectClassId,
      performanceTaskOccasionId,
    );
    commit('updateOccasion', occasion);
  },
  async sendAllGrades({ commit }, { performanceTaskId, subjectClassId }) {
    const occasions = await api.sendAllPerformanceTaskOccasionsGrades(
      performanceTaskId,
      subjectClassId,
    );
    commit('partialUpdateClassOccasions', occasions);
  },
  deletePendingImageUploadResponses({ state, dispatch }, useBeacon = false) {
    const pendingImageResponses = Object.values(state.ownOccasion?.responses || []).filter(
      response =>
        response.response_type === RESPONSE_TYPES.IMR &&
        response.user_response?.status === FILE_STATUS.UPLOADING,
    );
    pendingImageResponses.map(response =>
      dispatch('deleteOccasionResponse', { questionId: response.questionId, useBeacon }),
    );
  },
};

const mutations = {
  addResponseQueueProcessEventId(state, eventId) {
    state.responseQueueProcesses.push({ id: eventId });
  },
  removeResponseQueueProcessEventId(state, eventId) {
    state.responseQueueProcesses = state.responseQueueProcesses.filter(
      process => process.id !== eventId,
    );
  },
  setPerformanceTask(state, performanceTask) {
    state.performanceTask = performanceTask;
  },
  setOwnOccasion(state, occasion) {
    state.ownOccasion = occasion;
  },
  setClassOccasions(state, occasions) {
    state.classOccasions = occasions;
  },
  updateOccasion(state, occasion) {
    const occasionIndex = state.classOccasions.findIndex(
      classOccasion => classOccasion.id === occasion.id,
    );

    state.classOccasions[occasionIndex] = {
      ...state.classOccasions[occasionIndex],
      ...occasion,
    };
  },
  setIsCreatingOccasion(state, isCreating) {
    state.isCreatingOccasion = isCreating;
  },
  addClassOccasion(state, occasion) {
    const index = state.classOccasions.length;
    state.classOccasions[index] = occasion;
  },
  setOwnOccasionResponse(state, newResponse) {
    let index = state.ownOccasion.responses.findIndex(
      response => response.question_id === newResponse.question_id,
    );
    if (index === -1) {
      index = state.ownOccasion.responses.length;
    }

    state.ownOccasion.responses[index] = newResponse;
  },
  clearOccasionResponse(state, questionId) {
    const index = state.ownOccasion.responses.findIndex(
      response => response.question_id === questionId,
    );
    if (index === -1) return;

    const newStateResponses = [...state.ownOccasion.responses];
    newStateResponses.splice(index, 1);
    state.ownOccasion.responses = newStateResponses;
  },
  setStudentOccasionResponse(state, { occasionId, newResponse }) {
    const occasionIndex = state.classOccasions.findIndex(occasion => occasion.id === occasionId);
    const occasion = state.classOccasions[occasionIndex];

    let responseIndex = occasion.responses.findIndex(
      response => response.question_id === newResponse.question_id,
    );
    if (responseIndex === -1) {
      responseIndex = occasion.responses.length;
    }

    state.classOccasions[occasionIndex].responses[responseIndex] = newResponse;
  },
  partialUpdateOccasionResponse(state, newResponse) {
    let occasionIndex;
    let responseIndex;

    for (let i = 0; i < state.classOccasions?.length; i += 1) {
      const occasion = state.classOccasions[i];
      responseIndex = occasion.responses.findIndex(response => response.id === newResponse.id);
      if (responseIndex !== -1) {
        occasionIndex = i;
        break;
      }
    }

    const updatedResponse = {
      ...state.classOccasions[occasionIndex].responses[responseIndex],
      ...newResponse,
    };

    state.classOccasions[occasionIndex].responses[responseIndex] = updatedResponse;
  },
  partialUpdateClassOccasions(state, newOccasions) {
    const occasions = state.classOccasions;
    const updatedOccasions = occasions.map(occasion => {
      const newOccasionData = newOccasions.find(newOccasion => occasion.id === newOccasion.id);
      return {
        ...occasion,
        ...newOccasionData,
      };
    });
    state.classOccasions = updatedOccasions;
  },
  setSelectedStudentId(state, studentId) {
    state.selectedStudentId = studentId;
  },
};

const getters = {
  currentGradingState(state) {
    let responsesToGradeCount = 0;
    let gradesToSendCount = 0;
    let updatesToSendCount = 0;
    const submittedOccasions =
      state.classOccasions?.filter(({ submitted_at: submittedAt }) => submittedAt) ?? [];
    const studentsSubmittedCount = submittedOccasions.length;
    const responseIsNotGraded = response =>
      !Object.prototype.hasOwnProperty.call(response.grading, 'marks_draft');
    submittedOccasions.forEach(occasion => {
      const responsesWithoutMarks = occasion.responses.filter(responseIsNotGraded);
      responsesToGradeCount += responsesWithoutMarks.length;

      if (!occasion.grade_sent_at) {
        gradesToSendCount += 1;
      } else if (occasion.latest_question_graded_at) {
        const gradeSentAt = Date.parse(occasion.grade_sent_at);
        const latestQuestionGradedAt = Date.parse(occasion.latest_question_graded_at);
        if (gradeSentAt < latestQuestionGradedAt) {
          updatesToSendCount += 1;
        }
      }
    });
    return {
      responsesToGradeCount,
      gradesToSendCount,
      updatesToSendCount,
      studentsSubmittedCount,
    };
  },
  currentOccasion(state) {
    if (state.selectedStudentId) {
      return state.classOccasions?.find(occasion => occasion.user_id === state.selectedStudentId);
    }

    if (state.ownOccasion && state.ownOccasion.id) {
      return state.ownOccasion;
    }

    return null;
  },
  currentOccasionResponseByQuestionId(state, ptGetters) {
    return keyBy(ptGetters.currentOccasion?.responses || [], 'question_id');
  },
  scoredOccasionByStudentUserId(state) {
    const scoredOccasion = {};
    state.classOccasions.forEach(occasion => {
      let studentTotalScore = null;
      const studentGrading = {};
      occasion.responses.forEach(response => {
        if (!isNil(response.grading?.marks_draft)) {
          studentTotalScore += response.grading.marks_draft;
        }
        studentGrading[response.question_id] = response.grading;
      });
      studentGrading.status = ptGradingStatus(occasion);
      scoredOccasion[occasion.user_id] = {
        totalScore: studentTotalScore,
        grading: studentGrading,
        occasion,
      };
    });
    return scoredOccasion;
  },
  studentsMarksByQuestionId(state, ptGetters) {
    const questionsWithmarks = {};
    ptGetters.contentQuestions.forEach(question => {
      const questionGrading = [];
      state.classOccasions.forEach(occasion => {
        const studentResponseForQuestion = occasion.responses?.find(
          response => response.question_id === question.question_id,
        );
        const questionMarks = studentResponseForQuestion?.grading?.marks_draft;
        questionGrading.push({
          user_id: occasion.user_id,
          marks_draft: questionMarks,
        });
      });
      questionsWithmarks[question.question_id] = questionGrading;
    });
    return questionsWithmarks;
  },
  contentQuestions(state) {
    const questions = state.performanceTask?.content?.filter(
      content => content.block_type === BLOCK_TYPE.QUESTION,
    );
    return questions || [];
  },
  isAnyQuestionsMarkedForCurrentOccasion(state, ptGetters) {
    return ptGetters.contentQuestions?.some(element => {
      const marks =
        ptGetters.currentOccasionResponseByQuestionId[element.question_id]?.grading?.marks_draft;
      return !isNil(marks);
    });
  },
  isAllQuestionsMarkedForCurrentOccasion(state, ptGetters) {
    return ptGetters.contentQuestions?.every(element => {
      const marks =
        ptGetters.currentOccasionResponseByQuestionId[element.question_id]?.grading?.marks_draft;
      return !isNil(marks);
    });
  },
  isMarksSentForCurrentOccasion(state, ptGetters) {
    return !!ptGetters.currentOccasion?.grade_sent_at;
  },
  isMarksChangedAfterSendingForCurrentOccasion(state, ptGetters) {
    const userId = ptGetters.currentOccasion?.user_id;
    return ptGetters.isMarksChangedAfterSendingByUserId[userId];
  },
  isAllClassOccasionsGraded(state) {
    return state.classOccasions.every(occasion => !!occasion.latest_question_graded_at);
  },
  isNoneClassOccasionsGraded(state) {
    return state.classOccasions.every(occasion => !occasion.latest_question_graded_at);
  },
  isClassOccasionsPresent(state) {
    return state.classOccasions.length > 0;
  },
  isAllClassMarksSent(state) {
    return state.classOccasions.every(occasion => !!occasion.grade_sent_at);
  },
  isAnyMarksChangedAfterSending(state, ptGetters) {
    return Object.values(ptGetters.isMarksChangedAfterSendingByUserId).some(
      isMarksChangedForUser => isMarksChangedForUser,
    );
  },
  isProcessingResponses(state) {
    return state.responseQueueProcesses.length > 0;
  },
  isMarksChangedAfterSendingByUserId(state) {
    const status = {};
    state.classOccasions.forEach(occasion => {
      const isAnyQuestionGraded = occasion.latest_question_graded_at;
      const isGradingSent = occasion.grade_sent_at;

      if (!isAnyQuestionGraded || !isGradingSent) {
        status[occasion.user_id] = false;
        return;
      }
      const latestQuestionGradedTimestamp = new Date(occasion.latest_question_graded_at);
      const gradeSentTimestamp = new Date(occasion.grade_sent_at);
      status[occasion.user_id] = latestQuestionGradedTimestamp > gradeSentTimestamp;
    });
    return status;
  },
  isMarkSentByUserId(state) {
    const status = {};
    state.classOccasions.forEach(occasion => {
      status[occasion.user_id] = !!occasion.grade_sent_at;
    });
    return status;
  },
  gradedCountPerQuestion(state, ptGetters) {
    const gradedByQuestion = Object.fromEntries(
      ptGetters.contentQuestions?.map(q => [q.question_id, 0]),
    );
    state.classOccasions.forEach(occasion => {
      occasion.responses.forEach(response => {
        if (!response?.grading) {
          return;
        }
        const isQuestionGraded = Object.hasOwn(response.grading, 'marks_draft');
        if (isQuestionGraded) {
          gradedByQuestion[response.question_id] += 1;
        }
      });
    });

    const gradedPerQuestion = ptGetters.contentQuestions?.map(q => {
      return {
        questionId: q.question_id,
        studentsGradedCount: gradedByQuestion[q.question_id],
      };
    });
    return gradedPerQuestion;
  },
};

export const PERFORMANCE_TASK_MODULE = 'performanceTaskModule';
export default {
  namespaced: true,
  state: defaultState,
  actions,
  getters,
  mutations,
};
