import * as braze from '@braze/web-sdk';

const initializeBraze = (sdkApiKey, sdkUrl) =>
  new Promise(resolve => {
    resolve(braze.initialize(sdkApiKey, { baseUrl: sdkUrl }));
  });

const brazeModule = {
  init() {
    const BRAZE_SETTINGS = window.KOG?.DJANGO_SETTINGS?.BRAZE;
    const USER_CONSTANTS = window.KOG?.USER_CONSTANTS;

    const sdkApiKey = BRAZE_SETTINGS?.BRAZE_SDK_API_KEY;
    const sdkUrl = BRAZE_SETTINGS?.BRAZE_SDK_URL;
    const userId = USER_CONSTANTS?.User.id;

    return initializeBraze(sdkApiKey, sdkUrl).then(isInitialized => {
      if (isInitialized) {
        braze.automaticallyShowInAppMessages();
        braze.changeUser(userId);
        braze.openSession();
      }
    });
  },
  showContentCards(selector, placement = null) {
    let cardCounter = 0;

    const filterCards = cards => {
      let filteredCards;
      if (placement) {
        filteredCards = cards.filter(card => card.extras?.placement === placement);
      } else {
        filteredCards = cards;
      }
      cardCounter = filteredCards.length;
      return filteredCards;
    };

    braze.showContentCards(document.querySelector(selector), filterCards);
    return cardCounter;
  },
};

export default brazeModule;
