import * as Sentry from '@sentry/vue';

import fetchQuestionMappings from 'studyApp/api/exam-style-question.js';

export default {
  namespaced: true,
  state: {
    questionMappings: [],
  },
  mutations: {
    setQuestionMappings(state, mappings) {
      state.questionMappings = mappings;
    },
  },
  actions: {
    async fetchQuestionMappings({ commit }, subjectId) {
      try {
        commit('setQuestionMappings', await fetchQuestionMappings(subjectId));
      } catch (error) {
        Sentry.captureMessage(`fetchQuestionMappings: ${error}`);
      }
    },
  },
};
