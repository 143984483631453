import axios from 'sharedApp/vue-utils/kog-axios.ts';

const namespaced = true;

const mutations = {};

const actions = {
  async sendFeedback(
    _,
    {
      subjectNodeId,
      category,
      contentId = null,
      activityId = null,
      comment = '',
      studentAnswer = null,
    },
  ) {
    const data = {
      category,
      content: contentId,
      activity: activityId,
      comment,
      reported_from_url: window.location.href,
      student_answer: studentAnswer,
    };
    const response = await axios.post(`/api/subject-nodes/${subjectNodeId}/feedback/`, data);
    return response.data;
  },
};

const state = {};

export default {
  namespaced,
  state,
  actions,
  mutations,
};
