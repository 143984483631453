import { fetchSubjectList } from '@apis/subject.js';

const namespaced = true;

const mutations = {
  setAllSubjects(state, subjects) {
    state.allSubjects = subjects;
  },
};

const actions = {
  async fetchAllSubjects({ commit }) {
    const data = await fetchSubjectList();
    commit('setAllSubjects', data);
  },
};

const state = {
  allSubjects: [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
};
