<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { ActionEnumEnum } from '@apis/generated';

import KogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import KogRadioGroup from 'sharedApp/components/base/radio/kog-radio-group.vue';

const props = defineProps<{
  isReonboarding: boolean;
}>();

const emit = defineEmits(['showVideo', 'close']);
const router = useRouter();
const route = useRoute();

const selectedAction = ref<string>('null');

const options = [
  { text: 'Watch our introduction video', value: ActionEnumEnum.WELCOME_VIDEO },
  { text: 'Explore the assignments functionality', value: ActionEnumEnum.ASSIGNMENT_TOUR },
  {
    text: 'Explore the book structure and material (most people start here)',
    value: ActionEnumEnum.BOOK_TOUR,
  },
];

const handleRedirect = async (action: 'assignment_tour' | 'book_tour') => {
  const actionToRoute = {
    [ActionEnumEnum.ASSIGNMENT_TOUR]: 'teacherAssignmentOverview',
    [ActionEnumEnum.BOOK_TOUR]: 'subjectClassOverview',
  };
  const existingQuery = route.query;

  await router.push({
    name: actionToRoute[action],
    query: { ...existingQuery, onboardingAction: action },
  });
};

const handleContinue = async () => {
  switch (selectedAction.value) {
    case ActionEnumEnum.ASSIGNMENT_TOUR:
    case ActionEnumEnum.BOOK_TOUR:
      await handleRedirect(selectedAction.value);
      emit('close');
      break;
    case ActionEnumEnum.WELCOME_VIDEO:
      emit('showVideo');
      break;
    default:
      break;
  }
};

const questionText = props.isReonboarding
  ? 'Choose a tour for a quick refresher on how Kognity works:'
  : 'What would you like to do first?';
const closeButtonText = props.isReonboarding ? 'Skip' : 'Explore on my own';
</script>

<template>
  <label for="get-started-options"> {{ questionText }} </label>
  <kog-radio-group
    id="get-started-options"
    v-model:selectedValue="selectedAction"
    :options="options"
  />
  <kog-button
    class="width-full margin-top-xl"
    button-style="primary"
    label="Let's get started"
    :disabled="selectedAction === 'null'"
    @click="handleContinue"
  />
  <kog-button
    class="width-full margin-top-xs"
    button-style="basic"
    :label="closeButtonText"
    @click="$emit('close')"
  />
</template>
