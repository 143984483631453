import useToaster from 'sharedApp/composables/use-toaster.ts';
import {
  BACKEND_VERSION_LABEL_EVENT_NAME,
  BackendVersionLabelEvent,
} from 'sharedApp/vue-utils/interceptors/version-label-event-dispatcher.ts';

export class MissingFrontendVersionLabelError extends Error {
  constructor() {
    super('Frontend version label is missing');
    this.name = 'MissingFrontendVersionLabelError';
  }
}

function getFrontendVersionLabel() {
  const version = document
    .querySelector("head > meta[name='version-label']")
    ?.getAttribute('content');

  if (!version) {
    throw new MissingFrontendVersionLabelError();
  }
  return version;
}

export function registerNewVersionNotifications(toaster: ReturnType<typeof useToaster>) {
  let lastSeenVersionLabel = getFrontendVersionLabel();
  const message =
    'A new version of Kognity is available. The page needs to reload to apply the latest fixes and improvements.';

  const toastOptions = {
    actionText: 'Reload',
    time: 60 * 10 * 1000,
    toasterActionPerformed: () => {
      window.location.reload();
    },
  };

  function handleNewVersionEvent(e: Event) {
    if (!(e instanceof BackendVersionLabelEvent)) return;
    const backendVersionLabel = e.detail;
    const DISABLED_VERSION_LABEL = '-1';

    if (
      backendVersionLabel !== DISABLED_VERSION_LABEL &&
      lastSeenVersionLabel !== backendVersionLabel
    ) {
      toaster.showInfo(message, toastOptions);
      lastSeenVersionLabel = backendVersionLabel;
    }
  }

  document.addEventListener(BACKEND_VERSION_LABEL_EVENT_NAME, handleNewVersionEvent);

  const unregisterNewVersionHandler = () => {
    document.removeEventListener(BACKEND_VERSION_LABEL_EVENT_NAME, handleNewVersionEvent);
  };

  return unregisterNewVersionHandler;
}
