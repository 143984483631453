import { getAllDescendantsNodeIdsIncludingSelf } from 'sharedApp/libs/subject-tree-functions.js';
import {
  getFtbUIDsInOrder,
  isFBQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { getDateAsDateObject } from 'sharedApp/utils/time-utils.js';

export function emptyProgress() {
  return { total: 0, correct: 0 };
}

export function getPracticeItemsForSubjectNode(node, practiceItems) {
  const nodeIdWithDescendants = [...getAllDescendantsNodeIdsIncludingSelf(node)];
  const practiceItemsFiltered = practiceItems.filter(item =>
    item.subjectnodes.some(nodeId => nodeIdWithDescendants.includes(nodeId)),
  );
  return practiceItemsFiltered;
}

export function getEmptyProgressUserMapForNodes(nodes, practiceItems) {
  const uniqueUsers = new Set();
  practiceItems.forEach(pi => uniqueUsers.add(pi.user_id));

  return [...uniqueUsers].reduce((acc, uniqueUser) => {
    acc[uniqueUser] = {};
    nodes.forEach(node => {
      acc[uniqueUser][node.id] = emptyProgress();
    });
    return acc;
  }, {});
}

export function groupPracticeItemsByStudent(nodes, practiceItems) {
  const practiceItemsPerStudent = getEmptyProgressUserMapForNodes(nodes, practiceItems);
  nodes.forEach(node => {
    const nodeId = node.id;
    const nodePracticeItems = getPracticeItemsForSubjectNode(node, practiceItems);
    nodePracticeItems.forEach(pi => {
      practiceItemsPerStudent[pi.user_id][nodeId].total += 1;
      if (pi.correct) {
        practiceItemsPerStudent[pi.user_id][nodeId].correct += 1;
      }
    });
  });

  return practiceItemsPerStudent;
}

export function getUserAnswerList(question, userAnswer) {
  const { question_html: questionHtml } = question;

  if (isFBQ(question)) {
    const questionUids = getFtbUIDsInOrder(questionHtml);
    const userAnswers = [];
    questionUids.forEach(id => {
      userAnswers.push(userAnswer[id]);
    });

    return userAnswers;
  }

  return [userAnswer];
}

export function getCompletedNodeIdsPerStudent(bookProgress) {
  const progressPerUser = {};
  bookProgress.forEach(progress => {
    const { user_id: userId, subjectnode_id: subjectnodeId } = progress;

    if (progressPerUser[userId]) {
      progressPerUser[userId] = [...progressPerUser[userId], subjectnodeId];
    } else {
      progressPerUser[userId] = [subjectnodeId];
    }
  });

  return progressPerUser;
}

export function getArrayIntersection(array1, array2) {
  return array1.filter(x => array2.includes(x));
}

export function getLatestAttempt(practiceItems) {
  if (!practiceItems || practiceItems.length === 0) {
    return null;
  }

  let latestAttempt = null;
  practiceItems.forEach(pi => {
    if (!latestAttempt) {
      latestAttempt = pi.submitted_at;
      return;
    }

    const currentLatestAttemptTime = getDateAsDateObject(latestAttempt).getTime();
    const currentPracticeItemSubmissionTime = getDateAsDateObject(pi.submitted_at).getTime();
    const isLaterDate = currentPracticeItemSubmissionTime - currentLatestAttemptTime > 0;

    if (isLaterDate) {
      latestAttempt = pi.submitted_at;
    }
  });

  return latestAttempt;
}

export function sortPracticeItemsBySubmissionDate(practiceItems) {
  return practiceItems.sort((practiceItemA, practiceItemB) => {
    const practiceItemBTime = getDateAsDateObject(practiceItemB.submitted_at).getTime();
    const practiceItemATime = getDateAsDateObject(practiceItemA.submitted_at).getTime();
    return practiceItemBTime - practiceItemATime;
  });
}
