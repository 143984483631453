import axios from 'sharedApp/vue-utils/kog-axios.ts';

const baseUrl = '/api/syllabi/';

export async function fetchSyllabusDetails(syllabusId) {
  const response = await axios.get(`${baseUrl}${syllabusId}/`);
  return response.data;
}

export async function updateSyllabusDetails(syllabus) {
  const response = await axios.patch(`${baseUrl}${syllabus.id}/`, { ...syllabus });
  return response.data;
}

export async function fetchSyllabusNodeDetails(syllabusNodeId) {
  const response = await axios.get(`${baseUrl}node/${syllabusNodeId}/`);
  return response.data;
}

export async function deleteSyllabusNode(syllabusNodeId) {
  const response = await axios.delete(`${baseUrl}node/${syllabusNodeId}/`);
  return response.data;
}

export async function getSyllabusList() {
  const response = await axios.get(baseUrl);
  return response.data;
}

export async function createSyllabus(name) {
  const payload = {
    name,
  };
  const response = await axios.post(baseUrl, payload);
  return response.data;
}

export async function createSyllabusNode(parentNodeId, nodeData) {
  const response = await axios.post(`${baseUrl}node/${parentNodeId}/children/`, nodeData);
  return response.data;
}

export async function updateSyllabusNode(nodeData) {
  const response = await axios.patch(`${baseUrl}node/${nodeData.id}/`, nodeData);
  return response.data;
}
