import * as quizApi from '@apis/quiz.js';

export default {
  namespaced: true,
  state: {
    quiz: {},
    quizOccasion: {},
    quizQuestionResponses: [],
    studentOccasions: [],
    studentResponses: [],
  },
  getters: {
    getQuestionResponseByQuestionId(state) {
      return questionId => state.quizQuestionResponses.find(resp => questionId === resp.question);
    },
  },
  mutations: {
    setQuiz(state, quiz) {
      state.quiz = quiz;
    },
    setQuizOccasion(state, quizOccasion) {
      state.quizOccasion = quizOccasion;
    },
    addQuizQuestionResponse(state, quizQuestionResponse) {
      state.quizQuestionResponses.push(quizQuestionResponse);
    },
    setStudentOccasions(state, studentOccasions) {
      state.studentOccasions = studentOccasions;
    },
    setStudentResponses(state, studentResponses) {
      state.studentResponses = studentResponses;
    },
    setQuizQuestionResponses(state, quizQuestionResponses) {
      state.quizQuestionResponses = quizQuestionResponses;
    },
    resetQuizOccasion(state) {
      state.quizOccasion = {};
      state.quizQuestionResponses = [];
    },
  },
  actions: {
    async fetchQuiz({ commit }, quizId) {
      commit('setQuiz', await quizApi.fetchQuiz(quizId));
    },
    async createQuizOccasion({ commit }, quizId) {
      commit('setQuizOccasion', await quizApi.createQuizOccasion(quizId));
    },
    async submitQuestionOccasion({ commit }, { quizId, occasionId }) {
      const isSubmitted = true;
      const quizOccasion = await quizApi.updateQuizOccasion(quizId, occasionId, isSubmitted);
      commit('setQuizOccasion', quizOccasion);
    },
    async createQuizQuestionResponse({ commit }, { quizId, occasionId, questionId, userAnswer }) {
      const createdQuizQuestionResponse = await quizApi.createQuizQuestionResponse(
        quizId,
        occasionId,
        questionId,
        userAnswer,
      );
      commit('addQuizQuestionResponse', createdQuizQuestionResponse);
    },
    async fetchStudentOccasions({ commit }, { quizId, classId = undefined }) {
      const studentOccasions = await quizApi.fetchQuizOccassionList(quizId, classId);
      commit('setStudentOccasions', studentOccasions);
    },
    async fetchStudentResponses({ commit }, { quizId, classId = undefined }) {
      const studentResponses = await quizApi.fetchQuizResponsesList(quizId, classId);
      commit('setStudentResponses', studentResponses);
    },
    async fetchLastQuizOccasion({ commit }, { quizId, isSubmitted }) {
      const occasions = await quizApi.fetchQuizOccasions(quizId, isSubmitted);
      if (occasions.length > 0) {
        const latestOccasion = occasions[0];
        const quizResponses = await quizApi.fetchQuizQuestionResponses(quizId, latestOccasion.id);
        commit('setQuizOccasion', latestOccasion);
        commit('setQuizQuestionResponses', quizResponses);
      } else {
        commit('resetQuizOccasion');
      }
    },
  },
};
