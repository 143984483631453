import { compile } from 'path-to-regexp';

import { getCsrfToken } from 'sharedApp/utils/csrf.ts';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

export const performanceTaskBaseApiUrl = '/api/performance-tasks';
const performanceTaskUrl = compile([performanceTaskBaseApiUrl, ':performanceTaskId/'].join('/'));
const occasionListUrl = compile(
  [performanceTaskBaseApiUrl, ':performanceTaskId/occasions/'].join('/'),
);
const occasionSendGradesUrl = compile(
  [
    performanceTaskBaseApiUrl,
    ':performanceTaskId/occasions/:performanceTaskOccasionId/send-grades/',
  ].join('/'),
);
const sendAllGradesUrl = compile(
  [performanceTaskBaseApiUrl, ':performanceTaskId/occasions/send-grades/'].join('/'),
);

const performanceTaskOccasionsBaseUrl = '/api/performance-task-occasions';
const occasionResponseListUrl = compile(
  [performanceTaskOccasionsBaseUrl, ':performanceTaskOccasionId/responses/'].join('/'),
);
const occasionResponseUrl = compile(
  [performanceTaskOccasionsBaseUrl, ':performanceTaskOccasionId/responses/:responseId/'].join('/'),
);
const occasionResponseMarkUrl = compile(
  [performanceTaskOccasionsBaseUrl, ':performanceTaskOccasionId/responses/:responseId/mark/'].join(
    '/',
  ),
);
const cleanupOccasionResponseUrl = compile(
  [
    performanceTaskOccasionsBaseUrl,
    ':performanceTaskOccasionId/responses/:responseId/cleanup/',
  ].join('/'),
);
const occasionSubmissionUrl = compile(
  [performanceTaskOccasionsBaseUrl, ':performanceTaskOccasionId/submission/'].join('/'),
);

export async function fetchPerformanceTask(performanceTaskId) {
  const url = performanceTaskUrl({ performanceTaskId });
  const response = await axios.get(url);
  return response.data;
}

export async function createPerformanceTaskOccasion(
  performanceTaskId,
  subjectClassId,
  studentUserId = null,
) {
  const url = occasionListUrl({ performanceTaskId });
  const response = await axios.post(url, {
    subject_class_id: subjectClassId,
    user_id: studentUserId,
  });
  return response.data;
}

export async function fetchPerformanceTaskOccasions(
  performanceTaskId,
  subjectClassId,
  all = false,
) {
  const url = occasionListUrl({ performanceTaskId });
  const response = await axios.get(url, {
    params: { subject_class_id: subjectClassId, all },
  });
  return response.data;
}

export async function createPerformanceTaskOccasionResponse(
  performanceTaskOccasionId,
  questionId,
  userResponse,
) {
  const url = occasionResponseListUrl({ performanceTaskOccasionId });
  const response = await axios.post(url, {
    question_id: questionId,
    ...userResponse,
  });
  return response.data;
}

export async function updatePerformanceTaskOccasionResponse(
  performanceTaskOccasionId,
  responseId,
  questionId,
  userResponse,
) {
  const url = occasionResponseUrl({ performanceTaskOccasionId, responseId });
  const response = await axios.put(url, {
    question_id: questionId,
    ...userResponse,
  });
  return response.data;
}

export async function deletePerformanceTaskOccasionResponse(performanceTaskOccasionId, responseId) {
  const url = occasionResponseUrl({ performanceTaskOccasionId, responseId });
  await axios.delete(url);
}

export async function markPerformanceTaskOccasionResponse(
  performanceTaskOccasionId,
  responseId,
  marks,
) {
  const url = occasionResponseMarkUrl({ performanceTaskOccasionId, responseId });
  const response = await axios.put(url, { value: marks });
  return response.data;
}

export async function submitPerformanceTaskOccasion(performanceTaskOccasionId) {
  const url = occasionSubmissionUrl({ performanceTaskOccasionId });
  const response = await axios.post(url);
  return response.data;
}

export async function sendPerformanceTaskOccasionGrades(
  performanceTaskId,
  subjectClassId,
  performanceTaskOccasionId,
) {
  const url = occasionSendGradesUrl({ performanceTaskId, performanceTaskOccasionId });
  const response = await axios.post(url, {
    subject_class_id: subjectClassId,
  });
  return response.data;
}

export async function sendAllPerformanceTaskOccasionsGrades(performanceTaskId, subjectClassId) {
  const url = sendAllGradesUrl({ performanceTaskId });
  const response = await axios.post(url, {
    subject_class_id: subjectClassId,
  });
  return response.data;
}

export function beaconDeletePerformanceTaskOccasionResponse(performanceTaskOccasionId, responseId) {
  const url = cleanupOccasionResponseUrl({ performanceTaskOccasionId, responseId });
  const formData = new FormData();
  formData.append('csrfmiddlewaretoken', getCsrfToken());

  navigator.sendBeacon(url, formData);
}
