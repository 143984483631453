import * as Sentry from '@sentry/vue';

export function getSentryConfig() {
  if (!(window.KOG && window.KOG.SENTRY_DSN_FRONTEND)) {
    return null;
  }

  return {
    dsn: window.KOG.SENTRY_DSN_FRONTEND,
    release: window.KOG.SENTRY_RELEASE,
    environment: window.KOG.SENTRY_ENVIRONMENT,
    maxBreadcrumbs: 50,
    allowUrls: [/kognity\.com/, /cloudfront\.net/, /kog-testing\.se/, /localhost/],
    integrations: [],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      /*
        These two errors are not reproducible and affect only a small number of users.
        We have decided to not report them to Sentry to avoid using up our quota.
        Read more on KOG-23414.
      */
      "Can't find variable: _chromeNamespace",
      "undefined is not an object (evaluating 'window._webkitNamespace.messageHandlers')",
    ],
  };
}

export function identifyUser(userId) {
  Sentry.getCurrentScope().setUser({ id: userId });
}
