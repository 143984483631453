/* eslint-disable no-param-reassign */
export default {
  beforeMount(el, binding) {
    if (binding.value === true) {
      el.tabIndex = 0;
      el.setAttribute('role', 'button');
      el.style.cursor = 'pointer';
      el.style.borderRadius = '4px';
    }
  },
};
