import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const root = '/api/subject-nodes-visibility';
const subjectNodeVisibilityListUrl = `${root}/subject-class/:subjectClassId/visibility/`;

const subjectNodeVisibilityListUrlToPath = compile(subjectNodeVisibilityListUrl);

export async function updateSubjectClassNodeVisibility(subjectClassId, subjectNodeVisibilityMap) {
  const url = subjectNodeVisibilityListUrlToPath({ subjectClassId });
  const result = await axios.put(url, subjectNodeVisibilityMap);
  return result.data;
}

export async function fetchSubjectNodesVisibility(subjectClassId) {
  const url = subjectNodeVisibilityListUrlToPath({ subjectClassId });
  const result = await axios.get(url);
  return result.data;
}
