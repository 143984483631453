import axios from 'sharedApp/vue-utils/kog-axios.ts';

const baseUrl = '/api/subject-classes/';
const sectionCheckpointOccasionsApiUrl = `${baseUrl}section-checkpoint-occasions/`;

export async function startSectionQuestion(subjectNodeId) {
  const response = await axios.post(sectionCheckpointOccasionsApiUrl, {
    subject_node_id: subjectNodeId,
  });
  return response.data;
}
export async function fetchSubjectClassDetails(subjectClassId) {
  const response = await axios.get(`${baseUrl}${subjectClassId}/`);
  return response.data;
}

export async function fetchSubjectClassList(useCache = undefined) {
  const response = await axios.get(baseUrl, {
    cache: useCache,
    params: {
      all: 'True',
    },
  });
  return response.data;
}

export async function createSubjectClass(subjectClassName, subjectId, endDate) {
  const response = await axios.post(baseUrl, {
    name: subjectClassName,
    subject_id: subjectId,
    end_date: endDate,
  });
  return response.data;
}
