const CONTINUE_RECORDING = 'ph_continue_rec';

export function setPosthogToContinueRecording() {
  localStorage.setItem(CONTINUE_RECORDING, 'true');
}

export function popPosthogToContinueRecording() {
  const value = localStorage.getItem(CONTINUE_RECORDING);
  if (value === 'true') {
    localStorage.removeItem(CONTINUE_RECORDING);
    return true;
  }
  return false;
}
