import isNil from 'lodash/isNil.js';
import keyBy from 'lodash/keyBy.js';

import { fetchSyllabusDetails, fetchSyllabusNodeDetails } from 'publishingApp/apis/syllabi.js';

function isContainerNode(node) {
  return node.is_container === true || isNil(node.parent);
}

function getParentContainer(node, syllabusNodesById) {
  if (isContainerNode(node)) {
    return node;
  }
  return getParentContainer(syllabusNodesById[node.parent], syllabusNodesById);
}

export function getNodeDescendants(node) {
  const { children } = node;
  const grandChildren = children.flatMap(childNode => getNodeDescendants(childNode));
  return children.concat(grandChildren);
}

export default {
  namespaced: true,
  state: {
    syllabusNodeRootDetails: null,
  },
  getters: {
    syllabusNodes(state) {
      if (isNil(state.syllabusNodeRootDetails)) {
        return [];
      }
      return [state.syllabusNodeRootDetails].concat(
        getNodeDescendants(state.syllabusNodeRootDetails),
      );
    },
    syllabusNodesById(state, getters) {
      return keyBy(getters.syllabusNodes, 'id');
    },
    getAncestorContainerNode(state, getters) {
      return nodeId => {
        const node = getters.syllabusNodesById[nodeId];
        return getParentContainer(node, getters.syllabusNodesById);
      };
    },
  },
  mutations: {
    setSyllabusNodeRootDetails(state, syllabusNodeRootDetails) {
      state.syllabusNodeRootDetails = syllabusNodeRootDetails;
    },
  },
  actions: {
    async fetchSyllabusDetails({ commit }, syllabusId) {
      const syllabus = await fetchSyllabusDetails(syllabusId);
      commit('setSyllabusNodeRootDetails', syllabus.syllabus_node_root);
    },
  },
};
