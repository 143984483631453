import { keyBy } from 'lodash';

export const STANDARD_CONTENT_PAGE = 'SCP';
export const DRIVING_QUESTION_BOARD = 'DQB';
export const ACTIVITY_SHEET = 'ATS';
export const PERFORMANCE_TASK = 'PT';
export const QUIZ = 'QUIZ';
export const REFLECTION = 'REFLECTION';
export const MULTI_PART_QUESTION = 'MPQ';

export const FEATURES = [
  {
    type: STANDARD_CONTENT_PAGE,
    title: 'Standard page',
    icon: 'fa-book',
    iconStyle: 'regular',
    completeEventName: 'complete_standard_content_page',
  },
  {
    type: DRIVING_QUESTION_BOARD,
    title: 'Driving question board',
    icon: 'fa-screen-users',
    iconStyle: 'regular',
    completeEventName: 'complete_driving_question_board',
  },
  {
    type: ACTIVITY_SHEET,
    title: 'Activity sheet',
    icon: 'fa-pencil',
    iconStyle: 'light',
  },
  {
    type: PERFORMANCE_TASK,
    title: 'Performance task',
    icon: 'fa-pen-ruler',
    iconStyle: 'regular',
  },
  {
    type: QUIZ,
    title: 'Quiz',
    icon: 'fa-block-question',
    iconStyle: 'light',
  },
  {
    type: REFLECTION,
    title: 'Reflection',
    icon: 'fa-thought-bubble',
    iconStyle: 'regular',
  },
  {
    type: MULTI_PART_QUESTION,
    title: 'Multi-part question',
    icon: 'fa-pencil',
    iconStyle: 'light',
  },
];

export const FEATURES_BY_TYPE = keyBy(FEATURES, 'type');

export function getFeatureTypeIcon(featureType) {
  return FEATURES_BY_TYPE[featureType]?.icon ?? '';
}

export function getFeatureTypeIconStyle(featureType) {
  return FEATURES_BY_TYPE[featureType]?.style ?? 'regular';
}

export function getFeatureTypeIconProps(featureType) {
  return {
    icon: getFeatureTypeIcon(featureType),
    iconStyle: getFeatureTypeIconStyle(featureType),
  };
}

export function getFeatureTypeTitle(featureType) {
  return FEATURES_BY_TYPE[featureType].title;
}
export function getFeatureCompleteEventName(featureType) {
  return FEATURES_BY_TYPE[featureType].completeEventName;
}

export const FEATURES_ENUM = Object.freeze({
  STANDARD_CONTENT_PAGE,
  DRIVING_QUESTION_BOARD,
  ACTIVITY_SHEET,
  PERFORMANCE_TASK,
  QUIZ,
  MULTI_PART_QUESTION,
});
