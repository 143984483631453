import { API_URLS } from 'schoolAdminApp/urls.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

export async function fetchSubjectClassesList(schoolId, params = {}) {
  const url = API_URLS.SUBJECT_CLASSES_LIST_URL({ schoolId });
  const result = await axios.get(url, { params });
  return result.data;
}

export async function fetchSubjectClass(schoolId, subjectClassId, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_DETAILS_URL({ schoolId, subjectClassId });
  const result = await axios.get(url, { params });
  return result.data;
}

export async function deleteSubjectClass(schoolId, subjectClassId, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_DETAILS_URL({ schoolId, subjectClassId });
  const result = await axios.delete(url, { params });
  return result.data;
}

export async function updateSubjectClass(schoolId, subjectClassId, data, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_DETAILS_URL({ schoolId, subjectClassId });
  const result = await axios.put(url, data, { params });
  return result.data;
}

export async function createSubjectClass(schoolId, data, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_CREATE_URL({ schoolId });
  const result = await axios.post(url, data, { params });
  return result.data;
}

export async function fetchSubjectClassTeachers(schoolId, subjectClassId, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_TEACHERS_LIST_URL({ schoolId, subjectClassId });
  const result = await axios.get(url, { params });
  return result.data;
}

export async function fetchSubjectClassStudents(schoolId, subjectClassId, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_STUDENTS_LIST_URL({ schoolId, subjectClassId });
  const result = await axios.get(url, { params });
  return result.data;
}

export async function fetchSubjectClassAddableStudents(schoolId, subjectClassId, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_ADDABLE_STUDENTS_LIST_URL({ schoolId, subjectClassId });
  const result = await axios.get(url, { params });
  return result.data;
}

export async function deleteSubjectClassStudent(schoolId, subjectClassId, studentId, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_REMOVE_STUDENT_URL({ schoolId, subjectClassId, studentId });
  await axios.delete(url, { params });
}

export async function createSubjectClassStudent(schoolId, subjectClassId, data, params = {}) {
  const url = API_URLS.SUBJECT_CLASS_ADD_STUDENTS_URL({ schoolId, subjectClassId });
  const result = await axios.post(url, data, { params });
  return result.data;
}
