import * as Sentry from '@sentry/vue';

/*
This function makes the sprite svg inline.
Reason to inline svg is that in local env the sprite cannot be loaded as an external
resource due to different domains (port 8000 and 3000)
https://developer.mozilla.org/en-US/docs/Web/SVG/Element/use mentions
"For security reasons, browsers may apply the same-origin policy on use
elements and may refuse to load a cross-origin URL in the href attribute.
There is currently no defined way to set a cross-origin policy for use elements."
*/

const spriteUrl = require('sharedApp/img/sprite.svg');

async function spriteLoader() {
  let div = document.getElementById('spriteDiv');
  if (!div) {
    let response;
    try {
      response = await fetch(spriteUrl, { method: 'get' });
      div = document.createElement('div');
      div.setAttribute('id', 'spriteDiv');
      div.innerHTML = await response.text();
      document.body.appendChild(div);
    } catch (error) {
      Sentry.captureException(error, scope => {
        scope.addBreadcrumb({
          type: 'error',
          category: 'error',
          level: 'error',
          message: 'spriteLoader did not execute properly',
          data: {
            name: error.name,
            message: error.message,
            response_status: response?.status,
          },
        });
      });
    }
  }
}

export default spriteLoader;
