import helpHero from 'sharedApp/libs/helphero.js';

const mixpanelModule = require('sharedApp/libs/mixpanel.js').default;

export const helpHeroInstance = helpHero;

function trackTourEvent(eventName, info, properties = {}) {
  mixpanelModule.trackEventViaBackend(eventName, {
    tour_name: info.tour.name,
    ...properties,
  });
}

function addMixpanelEvents() {
  helpHero.on('tour_started', (_, info) => {
    trackTourEvent('Product Tour - Start Tour', info);
  });

  helpHero.on('tour_completed', (event, info) => {
    if (event.details !== 'CANCEL') {
      trackTourEvent('Product Tour - Complete Tour', info);
    }
  });

  helpHero.on('tour_cancelled', (_, info) => {
    trackTourEvent('Product Tour - Cancel Tour', info);
  });

  helpHero.on('tour_interrupted', (event, info) => {
    if (event?.reason === 'element') {
      trackTourEvent('Product Tour - Element not found', info, {
        selector: info.step.selector,
        path: window.location.pathname,
      });
    }
  });
}

export function initHelpHero(userId, properties = {}) {
  helpHero.identify(userId, properties);
  addMixpanelEvents(helpHero);
  return helpHero;
}
