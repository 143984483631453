import {
  createFeedbackItem,
  fetchFeedbackItem,
  fetchFeedbackItems,
  updateFeedbackItem,
} from '@apis/feedback-item.js';

const namespaced = true;

const mutations = {
  setStudentFeedbackItems(state, { studentUserId, feedbackItem }) {
    state.feedbackItems[studentUserId] = [feedbackItem];
  },
  setClassFeedbackItems(state, feedbackItems) {
    const initialState = feedbackItems.reduce(
      (dict, feedbackItem) => ({
        ...dict,
        [feedbackItem.student_user_id]: [],
      }),
      {},
    );

    const indexByStudentUserId = (dict, feedbackItem) => {
      const studentFeedback = [...dict[feedbackItem.student_user_id], feedbackItem];
      return {
        ...dict,
        [feedbackItem.student_user_id]: studentFeedback,
      };
    };
    state.feedbackItems = feedbackItems.reduce(indexByStudentUserId, initialState);
  },
};

const actions = {
  async fetchFeedbackItem(
    { commit },
    { studentUserId, subjectNodeId, questionId, feedbackItemId },
  ) {
    const feedbackItem = await fetchFeedbackItem(subjectNodeId, questionId, feedbackItemId);
    commit('setStudentFeedbackItems', { studentUserId, feedbackItem });
  },
  async fetchFeedbackItems({ commit }, { subjectNodeId, questionId, subjectClassId }) {
    const feedbackItems = await fetchFeedbackItems(subjectClassId, subjectNodeId, questionId);
    commit('setClassFeedbackItems', feedbackItems);
  },
  async createFeedbackItem(
    { commit },
    { subjectClassId, subjectNodeId, questionId, studentId, text, operation },
  ) {
    const feedbackItem = await createFeedbackItem(
      subjectClassId,
      subjectNodeId,
      questionId,
      studentId,
      text,
      operation,
    );
    commit('setStudentFeedbackItems', {
      studentUserId: feedbackItem.student_user_id,
      feedbackItem,
    });
  },
  async updateFeedbackItem(
    { commit },
    { feedbackItemId, subjectClassId, subjectNodeId, questionId, studentId, text, operation },
  ) {
    const feedbackItem = await updateFeedbackItem(
      feedbackItemId,
      subjectClassId,
      subjectNodeId,
      questionId,
      studentId,
      text,
      operation,
    );
    commit('setStudentFeedbackItems', {
      studentUserId: feedbackItem.student_user_id,
      feedbackItem,
    });
  },
  clearFeedbackItems({ commit }) {
    commit('setClassFeedbackItems', []);
  },
};

const state = {
  feedbackItems: {},
};

export default {
  namespaced,
  actions,
  mutations,
  state,
};
