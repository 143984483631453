import { fetchNotifications, markNotificationsAsSeen } from '@apis/notifications.js';

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications.map(n => ({ ...n, new: !n.seen }));
    },
    updateNotificationsSeenStatus(state, ids) {
      const setOfIds = new Set(ids);
      state.notifications = state.notifications.map(n =>
        !setOfIds.has(n.id) ? n : { ...n, seen: true },
      );
    },
  },
  actions: {
    async fetchNotifications({ commit }) {
      commit('setNotifications', await fetchNotifications());
    },
    async markNotificationsAsSeen({ commit }, ids) {
      if (ids.length > 0) {
        await markNotificationsAsSeen(ids);
        commit('updateNotificationsSeenStatus', ids);
      }
    },
  },
};
