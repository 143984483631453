import Fuse from 'fuse.js';
import { groupBy, has } from 'lodash';

import { fetchGlossaryDefinitions } from 'studyApp/api/glossary.js';
import { TOOLS_DRAWER_GLOSSARY_TAB } from 'studyApp/utils/tools-drawer-tabs.js';

const FUSE_SEARCH_OPTIONS = {
  shouldSort: false,
  ignoreLocation: true,
  threshold: 0.2,
  keys: ['term', 'definitions.definition_html'],
};

function getLettersAndTermsMarkedFirstInLetter(terms) {
  const lettersSet = new Set();
  const markedTerms = terms.map(term => {
    const symbols = '#';
    const alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    const termFirstLetter = term.term[0].toUpperCase();
    const termLetter = alphabet.includes(termFirstLetter) ? termFirstLetter : symbols;
    lettersSet.add(termLetter);
    return {
      ...term,
      termLetter,
    };
  });
  return {
    markedTerms,
    letters: [...lettersSet],
  };
}

export default {
  namespaced: true,
  state: {
    activeSubject: null,
    isTermsListLoading: false,
    terms: [],
    termsFilterString: '',
    existingTermsLetters: {},
    definitionsById: {},
    currentLetter: null,
    definitionToScrollTo: null,
    drawer: {
      isOpen: false,
      selectedTab: TOOLS_DRAWER_GLOSSARY_TAB,
    },
  },
  getters: {
    hasTerms: state => state.terms.length > 0,
    isDrawerOpen: state => state.drawer.isOpen,
    getDrawerSelectedTab: state => state.drawer.selectedTab,
    fuseSearch: state => new Fuse(state.terms, FUSE_SEARCH_OPTIONS),
    filteredTermLetters: (state, getters) => {
      const { existingTermsLetters, termsFilterString, activeSubject } = state;
      if (!termsFilterString) {
        return existingTermsLetters[activeSubject] || [];
      }
      const filteredLetters = [];
      getters.fuseSearch.search(termsFilterString).forEach(result => {
        const letter = result.item.termLetter;
        if (!filteredLetters.includes(letter)) {
          filteredLetters.push(letter);
        }
      });
      return filteredLetters;
    },
    filteredTerms: (state, getters) => {
      const { terms, termsFilterString } = state;
      if (!termsFilterString) {
        return terms;
      }
      const searchResults = getters.fuseSearch.search(termsFilterString);
      return searchResults.map(result => result.item);
    },
    termsByLetter: (state, getters) => groupBy(getters.filteredTerms, 'termLetter'),
    existingLetters: (state, getters) => Object.keys(getters.termsByLetter).sort(),
  },
  mutations: {
    setDefinitions(state, { subjectId, definitions }) {
      const { markedTerms, letters } = getLettersAndTermsMarkedFirstInLetter(definitions);
      state.terms = markedTerms;
      state.existingTermsLetters[subjectId] = letters;
      state.definitionsById[subjectId] = definitions.reduce((acc, definition) => {
        const definitionHtml = definition.definition_html;
        const definitionId = definition.id;
        return {
          ...acc,
          [definitionId]: {
            definitionHtml,
            termText: definition.term,
          },
        };
      }, {});
      state.isTermsListLoading = false;
    },
    setTermsFilterString(state, termsFilterString) {
      state.termsFilterString = termsFilterString;
    },
    setActiveSubject(state, subjectId) {
      state.activeSubject = subjectId;
    },
    setIsTermsListLoading(state, isTermsListLoading) {
      state.isTermsListLoading = isTermsListLoading;
    },
    setDrawerIsOpen(state, flag) {
      state.drawer.isOpen = flag;
    },
    setDrawerSelectedTab(state, tab) {
      state.drawer.selectedTab = tab;
    },
    setCurrentLetter(state, letter) {
      state.currentLetter = letter;
    },
    setDefinitionToScrollTo(state, definitionId) {
      state.definitionToScrollTo = definitionId;
    },
  },
  actions: {
    async fetchGlossaryDefinitions({ commit, state }, subjectId) {
      commit('setActiveSubject', subjectId);
      if (!has(state.definitionsById, subjectId)) {
        commit('setIsTermsListLoading', true);
        const definitions = await fetchGlossaryDefinitions(subjectId);
        commit('setDefinitions', { subjectId, definitions });
      }
    },
    scrollToLetter({ commit, getters }, letter) {
      const firstTermOfLetter = getters.termsByLetter[letter][0];
      commit('setDefinitionToScrollTo', firstTermOfLetter.id);
    },
  },
};
