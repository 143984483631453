import * as Sentry from '@sentry/vue';

import { fetchUserAssignments } from '@apis/assignments.js';
import { getSectionQuestions } from '@apis/questions.js';
import { fetchSubjectClassStudent } from '@apis/students.js';
import {
  fetchQuestionProgress,
  fetchReflectionQuestionProgress,
  fetchStudentPracticeItems as fetchStudentPracticeItemsApi,
  fetchStudentsList,
  fetchSubjectClass,
  fetchSubjectClassBookProgress,
  fetchSubjectClassStudentBattles,
  fetchSubjectClassStudentStrengthTests,
} from '@apis/subject-class.js';
import {
  fetchSubjectDetails,
  fetchSubjectReflectionSections,
  fetchSubjectSectionsCompleted,
  fetchSubjectUserLastActivity,
  fetchSubjectUserSectionActivity,
} from '@apis/subject.js';

import { getSubjectNodeMap } from 'sharedApp/libs/subject-tree-functions.js';
import { PRACTICE_OCCASION_TYPES } from 'sharedApp/services/assignment/assignment-utility-service.js';

const actions = {
  async fetchStudentsList({ commit }, subjectClassId) {
    try {
      const studentsListApiResponse = await fetchStudentsList(subjectClassId);
      commit('setStudentsList', studentsListApiResponse);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentsList: ${error}`);
    }
  },
  async fetchStudentDetails({ commit }, { schoolId, subjectClassId, userId }) {
    try {
      const studentDetailsApiResponse = await fetchSubjectClassStudent(
        schoolId,
        subjectClassId,
        userId,
      );
      commit('setStudentDetails', studentDetailsApiResponse.student);
      commit('setStudentLevel', studentDetailsApiResponse.level);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentDetails: ${error}`);
    }
  },
  async fetchStudentLastActivity({ commit }, { subjectId, userId }) {
    try {
      const lastActivityApiResponse = await fetchSubjectUserLastActivity(subjectId, userId);
      commit('setStudentLastActivity', {
        userId,
        lastActivity: lastActivityApiResponse.last_activity,
      });
    } catch (error) {
      Sentry.captureMessage(`fetchStudentLastActivity: ${error}`);
    }
  },
  async fetchSubjectDetails({ commit }, subjectId) {
    try {
      const subjectDetails = await fetchSubjectDetails(subjectId);
      commit('setSubjectTree', subjectDetails.subject_tree[0]);
      commit('setSubjectNodeMap', getSubjectNodeMap(subjectDetails.subject_tree[0]));
      commit('setEducationSystem', subjectDetails.education_system);
    } catch (error) {
      Sentry.captureMessage(`fetchSubjectDetails: ${error}`);
    }
  },
  async fetchQuestionProgress({ commit }, { subjectClassId, params }) {
    try {
      const questionProgress = await fetchQuestionProgress(subjectClassId, params);
      commit('setQuestionProgress', questionProgress);
      commit('setQuestionTabParams', params);
    } catch (error) {
      Sentry.captureMessage(`fetchQuestionProgress: ${error}`);
    }
  },
  async fetchSubjectClass({ commit }, subjectClassId) {
    try {
      const subjectClass = await fetchSubjectClass(subjectClassId);
      commit('setSubjectClass', subjectClass);
    } catch (error) {
      Sentry.captureMessage(`fetchSubjectClass: ${error}`);
    }
  },
  async fetchStudentPracticeItems({ commit }, { subjectClassId, userId, params }) {
    try {
      const practiceItems = await fetchStudentPracticeItemsApi(subjectClassId, userId, params);
      commit('setPracticeItems', practiceItems);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentPracticeItems: ${error}`);
    }
  },
  async fetchBookProgress({ commit }, { subjectClassId, params }) {
    try {
      const bookProgress = await fetchSubjectClassBookProgress(subjectClassId, params);
      commit('setBookProgress', bookProgress);
      commit('setTextbookTabParams', params);
    } catch (error) {
      Sentry.captureMessage(`fetchBookProgress: ${error}`);
    }
  },
  async fetchSectionQuestions({ commit }, { subjectNodeId, params }) {
    try {
      const sectionQuestions = await getSectionQuestions(subjectNodeId, {
        additionalParams: params,
        cache: true,
      });
      commit('setCurrentNodeSectionQuestions', sectionQuestions);
    } catch (error) {
      Sentry.captureMessage(`fetchSectionQuestions: ${error}`);
    }
  },
  async fetchSectionQuestionPracticeItems({ commit }, { subjectClassId, userId, params }) {
    try {
      const reqParams = {
        ...params,
        occasion_type: PRACTICE_OCCASION_TYPES.SECTION_CHECKPOINT,
      };
      const practiceItems = await fetchStudentPracticeItemsApi(subjectClassId, userId, reqParams);
      commit('setSectionQuestionPracticeItems', practiceItems);
    } catch (error) {
      Sentry.captureMessage(`fetchSectionQuestionPracticeItems: ${error}`);
    }
  },
  async fetchReflectionQuestionProgress({ commit, state }, { subjectClassId, params }) {
    try {
      const useCache = state.useReflectionQuestionProgressCache;
      const reflectionQuestionProgress = await fetchReflectionQuestionProgress(
        subjectClassId,
        useCache,
        params,
      );
      commit('setReflectionQuestionProgress', reflectionQuestionProgress);
      commit('setUseReflectionQuestionProgressCache', true);
    } catch (error) {
      Sentry.captureMessage(`fetchReflectionQuestionProgress ${error}`);
    }
  },
  async fetchSubjectReflectionQuestionSections({ commit }, { subjectId }) {
    try {
      const sections = await fetchSubjectReflectionSections(subjectId);
      commit('setSubjectReflectionSections', sections);
    } catch (error) {
      Sentry.captureMessage(`fetchSubjectReflectionQuestionSections: ${error}`);
    }
  },
  updateUseReflectionQuestionProgressCache({ commit }, { useCache }) {
    try {
      commit('setUseReflectionQuestionProgressCache', useCache);
    } catch (error) {
      Sentry.captureMessage(`updateUseReflectionQuestionProgressCache ${error}`);
    }
  },
  async fetchStudentLastOpenSections({ commit }, { subjectId, userId }) {
    try {
      const response = await fetchSubjectUserSectionActivity({
        subjectId,
        userId,
        params: { page_size: 5 },
      });
      commit('setStudentLastOpenSections', {
        userId,
        lastOpenSections: response.results,
      });
      commit('setStudentSections', response);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentLastOpenSections: ${error}`);
    }
  },
  async fetchStudentSectionActivity({ commit }, { subjectId, userId, page }) {
    try {
      const response = await fetchSubjectUserSectionActivity({
        subjectId,
        userId,
        params: { page, page_size: 5 },
      });
      commit('setStudentSections', response);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentSectionActivity: ${error}`);
    }
  },
  async fetchStudentSectionsCompleted({ commit }, { subjectId, userId }) {
    try {
      const response = await fetchSubjectSectionsCompleted({
        subjectId,
        userId,
      });
      commit('setStudentSectionsCompleted', response);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentSectionsCompleted: ${error}`);
    }
  },
  async fetchStudentLatestAssignments({ commit }, { userId, subjectClassId }) {
    try {
      const response = await fetchUserAssignments({
        userId,
        params: {
          page_size: 5,
          subject_class_id: subjectClassId,
        },
      });
      commit('setStudentLatestAssignments', {
        userId,
        latestAssignments: response.results,
      });
      commit('setStudentAssignments', response);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentLatestAssignments: ${error}`);
    }
  },
  async fetchStudentAssignments({ commit }, { userId, subjectClassId, page }) {
    try {
      const response = await fetchUserAssignments({
        userId,
        params: {
          page,
          page_size: 5,
          subject_class_id: subjectClassId,
        },
      });
      commit('setStudentAssignments', response);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentAssignments: ${error}`);
    }
  },
  async fetchStudentBattles({ commit }, { subjectClassId, userId, page = 1 }) {
    try {
      const response = await fetchSubjectClassStudentBattles({
        subjectClassId,
        userId,
        params: {
          page,
          page_size: 5,
        },
      });
      commit('setStudentBattlesCount', response.count);
      commit('setStudentBattles', response);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentBattles: ${error}`);
    }
  },
  async fetchStudentStrengthTests({ commit }, { subjectClassId, userId, page = 1 }) {
    try {
      const response = await fetchSubjectClassStudentStrengthTests({
        subjectClassId,
        userId,
        params: {
          page,
          page_size: 5,
        },
      });
      commit('setStudentStrengthTestsCount', response.count);
      commit('setStudentStrengthTests', response);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentStrengthTests: ${error}`);
    }
  },
};

export default actions;
