import { compile } from 'path-to-regexp';

import {
  associateIntegrationClasses,
  disassociateIntegrationClasses,
  updateIntegrationClass,
} from '@apis/integrations.js';

import asyncOpService from 'sharedApp/apis/async-operation-service.js';
import AsyncFetchStatus from 'sharedApp/apis/async-status.js';
import {
  GOOGLE_AUTH_CLASSROOM_API_DISABLED,
  GOOGLE_AUTH_ERROR_CODE,
  GOOGLE_AUTH_NO_CLASS_ACCESS,
  GOOGLE_CLASSES_URL,
  GOOGLE_CLASSROOM_API_UNAVAILABLE,
  INTEGRATION_CLASSES_URL,
  UNKNOWN_ERROR_CODE,
} from 'studyApp/constants/google-classroom-constants.js';

const namespaced = true;

const mutations = {
  setClasses(state, classes) {
    state.classes = classes;
  },
  setUpdatedClass(state, updatedClass) {
    state.updatedClass = updatedClass;
  },
  setRequestError(state, error) {
    state.requestError = error;
  },
};

export function getErrorCodeFromResponse(error) {
  const errorType = error?.response?.data?.error;
  let errorCode;
  switch (errorType) {
    case GOOGLE_AUTH_ERROR_CODE:
      errorCode = GOOGLE_AUTH_ERROR_CODE;
      break;
    case GOOGLE_AUTH_CLASSROOM_API_DISABLED:
      errorCode = GOOGLE_AUTH_CLASSROOM_API_DISABLED;
      break;
    case GOOGLE_AUTH_NO_CLASS_ACCESS:
      errorCode = GOOGLE_AUTH_NO_CLASS_ACCESS;
      break;
    case GOOGLE_CLASSROOM_API_UNAVAILABLE:
      errorCode = GOOGLE_CLASSROOM_API_UNAVAILABLE;
      break;
    default:
      errorCode = UNKNOWN_ERROR_CODE;
      break;
  }
  return errorCode;
}

const GOOGLE_CLASSROOM_URLS = {
  'google-classroom-classes': compile(`${GOOGLE_CLASSES_URL}:classId/`),
  'google-classroom-class': compile(`${GOOGLE_CLASSES_URL}:classId/google_class/:upstreamClassId/`),
};
export function constructGoogleClassUrl({ classId, upstreamClassId }) {
  if (upstreamClassId) {
    return GOOGLE_CLASSROOM_URLS['google-classroom-class']({ classId, upstreamClassId });
  }

  return GOOGLE_CLASSROOM_URLS['google-classroom-classes']({ classId });
}

const actions = {
  async fetchGoogleClasses(
    { commit },
    { classId, pollingIntervalMs, maxPollCount, timeoutMessage },
  ) {
    try {
      commit('setRequestError', false);
      const url = constructGoogleClassUrl({ classId });
      await asyncOpService.callAsyncOp(
        {
          method: 'get',
          url,
          pollingIntervalMs,
          maxPollCount,
          timeoutMessage,
        },
        ({ status, result }) => {
          if (status === AsyncFetchStatus.done) {
            const classes = result.data.google_classes;
            commit('setClasses', classes);
          }
        },
      );
    } catch (error) {
      const errorCode = getErrorCodeFromResponse(error);
      commit('setRequestError', errorCode);
    }

    return {
      status: AsyncFetchStatus.done,
    };
  },
  async fetchGoogleClass(
    { commit },
    { classId, upstreamClassId, pollingIntervalMs, maxPollCount, timeoutMessage },
  ) {
    commit('setRequestError', false);
    const url = constructGoogleClassUrl({ classId, upstreamClassId });

    try {
      await asyncOpService.callAsyncOp(
        {
          method: 'get',
          url,
          pollingIntervalMs,
          maxPollCount,
          timeoutMessage,
        },
        ({ status, result }) => {
          if (status === AsyncFetchStatus.done) {
            const updatedClass = result.data.google_class;
            commit('setUpdatedClass', updatedClass);
          }
        },
      );
    } catch (error) {
      const errorCode = getErrorCodeFromResponse(error);
      commit('setRequestError', errorCode);
    }

    return {
      status: AsyncFetchStatus.done,
    };
  },
  async updateGoogleClass({ dispatch, state, rootState }) {
    const { subjectClass } = rootState.schoolstaffSubjectclassModule;
    const data = {
      upstream_class_name: state.updatedClass.name,
      students: state.updatedClass.students,
    };
    await updateIntegrationClass('google_classroom', subjectClass.id, state.updatedClass.id, data);
    await dispatch(
      'schoolstaffSubjectclassModule/fetchSubjectClass',
      { subjectClassId: subjectClass.id, useCache: false },
      { root: true },
    );
  },
  async associateGoogleClasses(_, { classId, googleClasses }) {
    const data = { upstream_classes: googleClasses };
    await associateIntegrationClasses('google_classroom', classId, data);
  },
  async disassociateGoogleClass(_, { classId, googleClassId }) {
    const data = { data: { google_class_id: googleClassId } };
    await disassociateIntegrationClasses('google_classroom', classId, data);
  },
};

const state = {
  classes: [],
  requestError: false,
  updatedClass: {},
};

export default {
  namespaced,
  actions,
  state,
  mutations,
};
