import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import type { AddToasterOptions, Toaster } from 'sharedApp/store/toaster.types.ts';

const DELAY_TIME = 3000;

export default defineStore('toaster', () => {
  const toasters = ref(new Map<string, Toaster>());
  const toastersArray = computed(() => Array.from(toasters.value.values()).reverse());

  function clearToasterTimeout(toaster: Toaster) {
    window.clearTimeout(toaster.timeout);
    // eslint-disable-next-line no-param-reassign
    toaster.timeout = undefined;
  }

  function removeToaster(toasterId: string) {
    toasters.value.delete(toasterId);
  }

  function closeToaster(toasterId: string) {
    const toaster = toasters.value.get(toasterId);
    if (!toaster) return;
    clearToasterTimeout(toaster);
    if (!toaster.actionPerformed) {
      toaster.events.toasterClosedNoActionPressed?.();
    }
    removeToaster(toasterId);
  }

  function addToasterTimeout(toasterId: string, time: number) {
    return window.setTimeout(() => {
      closeToaster(toasterId);
    }, time);
  }

  function delayToaster(toaster: Toaster) {
    clearToasterTimeout(toaster);
    // eslint-disable-next-line no-param-reassign
    toaster.timeout = addToasterTimeout(toaster.toasterId, DELAY_TIME);
  }

  function resetToasterShake(toaster: Toaster) {
    // eslint-disable-next-line no-param-reassign
    toaster.shake = false;
  }

  function addToasterShakeTimeout(toaster: Toaster) {
    return window.setTimeout(() => {
      resetToasterShake(toaster);
    }, 3000);
  }

  function addToaster({ props, events = {}, time, toasterId: id }: AddToasterOptions) {
    const toasterId = id ?? crypto.randomUUID();
    let toaster = toasters.value.get(toasterId);

    if (toaster) {
      window.clearTimeout(toaster.shakeTimeout);
      toaster.props = { ...toaster.props, ...props };
      toaster.events = { ...toaster.events, ...events };
      toaster.shake = true;
      toaster.shakeTimeout = addToasterShakeTimeout(toaster);
      addToasterShakeTimeout(toaster);
      delayToaster(toaster);
      return toasterId;
    }

    toaster = {
      props: {
        ...props,
        toasterId,
      },
      events,
      toasterId,
      timeout: addToasterTimeout(toasterId, time),
      actionPerformed: false,
      shake: false,
    };
    toasters.value.set(toasterId, toaster);

    return toasterId;
  }

  return {
    toasters,
    toastersArray,
    addToaster,
    closeToaster,
    delayToaster,
    clearToasterTimeout,
    removeToaster,
  };
});
