import * as Sentry from '@sentry/vue';
import mapValues from 'lodash/mapValues.js';

import { fetchStudentsSubtopicProgress, fetchSubtopicStatistics } from '@apis/statistics.js';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const namespaced = true;

const mutations = {
  setSubtopicId(state, subTopicId) {
    state.subTopicId = subTopicId;
  },
  setSubtopicStats(state, statistics) {
    state.subTopicStats = statistics;
  },
};

const getters = {
  classStats: state =>
    state.subTopicStats.map(classStats => ({
      subject_class_id: classStats.subject_class_id,
      subject_class: classStats.subject_class,
      students_total: classStats.students_total,
      students_completed_nodes: mapValues(classStats.nodes, node => node.students_done),
    })),
};

const actions = {
  async fetchSubtopicStatistics({ commit, state }, { subjectId, subTopicId }) {
    if (state.subTopicId === subTopicId) {
      return;
    }
    commit('setSubtopicId', subTopicId);
    try {
      const subtopicStatistics = await fetchSubtopicStatistics(subjectId, subTopicId);
      commit('setSubtopicStats', subtopicStatistics);
    } catch (error) {
      Sentry.captureMessage(`fetchSubtopicStatistics: ${error}`);
      commit('setSubtopicStats', null);
    }
  },
  async fetchStudentsSectionCompletion(
    _,
    { subjectId, subTopicId, subjectClassId, subjectNodeId },
  ) {
    try {
      const subjectClassesStats = await fetchStudentsSubtopicProgress(subjectId, subTopicId);
      const subjectClass = subjectClassesStats.find(
        item => item.subject_class_id === subjectClassId,
      );
      if (!subjectClass) {
        return [];
      }
      return subjectClass.nodes[subjectNodeId] || [];
    } catch (error) {
      Sentry.captureMessage(`fetchStudentsSectionCompletion: ${error}`);
      return [];
    }
  },
};

const state = {
  subTopicId: null,
  subTopicStats: [],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
