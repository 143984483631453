// URL-based screens

const studyRoutes = {
  base: '/study/app',
  urls: {
    mediaLibrary: { path: '/:subject_slug/media-library/' },
    practiceTestSetup: { path: '/:subject_slug/practice/test/setup/' },
    practiceTest: { path: '/:subject_slug/practice/test/:occasion_id/' },
    practiceTestResult: { path: '/:subject_slug/practice/test/:occasion_id/result' },
    esqSetup: { path: '/:subject_slug/practice/exams/setup/:topicId?/' },
    examStyleQuestionsTake: { path: '/:subject_slug/practice/exams/take/:topic_id/:question_id/' },
    assignmentsOverview: { path: '/:subject_slug/assignments/overview/' },
    assignmentsTake: { path: '/:subject_slug/assignments/take/:assignment_id/' },
    readingAssignmentTake: { path: '/:subject_slug/reading_assignments/take/:assignment_id/' },
    esqAssignmentOverview: { path: '/:subject_slug/esq_assignments/overview/:assignment_id/' },
    esqAssignmentTake: { path: '/:subject_slug/esq_assignments/take/:assignment_id/' },
    esqAssignmentResult: { path: '/:subject_slug/esq_assignments/result/:assignment_id/' },
    questionAssignmentResult: { path: '/:subject_slug/assignments/result/:assignment_id/' },
    newSubjectClassOverview: { path: '/:classSlug/sid-:sid-cid-:cid/overview/' },
    newPracticeTestSetup: { path: '/:classSlug/sid-:sid-cid-:cid/practice/test/setup/' },
    newAssignmentTake: { path: '/:classSlug/sid-:sid-cid-:cid/assignments/:assignmentId/take/' },
    newReadingAssignmentTake: {
      path: '/:classSlug/sid-:sid-cid-:cid/assignments/reading/:assignmentId/take/',
    },
    newEsqAssignmentOverview: {
      path: '/:classSlug/sid-:sid-cid-:cid/assignments/esq/:assignmentId/overview/',
    },
    teacherAssignmentDetails: {
      path: '/:classSlug/sid-:sid-cid-:cid/assignments/:assignmentId/details',
    },
    manageClass: { path: '/:classSlug/sid-:sid-cid-:cid/manage/' },
    teacherAssignmentCreate: {
      path: '/:classSlug/sid-:sid-cid-:cid/assignments/create/:assignmentId/',
    },
    teacherAssignmentEdit: {
      path: '/:classSlug/sid-:sid-cid-:cid/assignments/edit/:assignmentId/',
    },
    newSectionUrl: { path: '/:classSlug/sid-:sid-cid-:cid/book/:nodeSlug-id-:nodeId/' },
    bookPrint: { path: '/:classSlug/sid-:sid-cid-:cid/book/:nodeSlug-id-:nodeId/print/' },
    teacherQuestionInsights: { path: '/:classSlug/sid-:sid-cid-:cid/insights/' },
    studentDetails: { path: '/:classSlug/sid-:sid-cid-:cid/insights/students/:studentId/' },
  },
};

module.exports = studyRoutes;
