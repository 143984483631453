import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

import type { InternalAxiosRequestConfig } from 'axios';

import { getCsrfToken } from 'sharedApp/utils/csrf.ts';

import { dispatchApiErrorInterceptor } from './interceptors/api-error-dispatcher.ts';
import { externalRequestInterceptor } from './interceptors/external-request-interceptor.ts';
import { dispatchBackendVersionLabelInterceptor } from './interceptors/version-label-event-dispatcher.ts';

const callAxiosAdapter = (config: InternalAxiosRequestConfig) => {
  const adapter = axios.getAdapter(axios.defaults.adapter);
  return adapter(config);
};

const kogAxios = axios.create({
  baseURL: '/',
  adapter: cacheAdapterEnhancer(callAxiosAdapter, { enabledByDefault: false }),
  headers: { 'X-CSRFToken': getCsrfToken() },
});

kogAxios.interceptors.request.use(externalRequestInterceptor);

kogAxios.interceptors.response.use(
  dispatchBackendVersionLabelInterceptor,
  dispatchApiErrorInterceptor,
);

export default kogAxios;

export const externalAxios = axios.create();
