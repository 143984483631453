import { getResources } from 'learning/common/store-modules/media-library/api.js';

const namespaced = true;

const mutations = {
  setResources(state, resourcesGroupedBySubjectNode) {
    state.resourcesGroupedBySubjectNode = resourcesGroupedBySubjectNode;
  },
};

const actions = {
  async fetchResources({ commit }, { subjectId, useCache = true }) {
    const resourcesGroupedBySubjectNode = await getResources(subjectId, useCache);
    commit('setResources', resourcesGroupedBySubjectNode);
  },
};

const state = {
  resourcesGroupedBySubjectNode: [],
};

export default {
  namespaced,
  mutations,
  actions,
  state,
};
