export const GOOGLE_CLASSROOM_PROVIDER_NAME = 'Google Classroom';

export const GOOGLE_CLASSES_URL = '/api/schoolstaff/google_classroom_classes/';
export const INTEGRATION_CLASSES_URL = '/api/schoolstaff/lms_integration/google_classroom/classes/';

export const GOOGLE_AUTH_ERROR_CODE = 'google_auth_error';
export const GOOGLE_AUTH_CLASSROOM_API_DISABLED = 'google_auth_classroom_api_disabled';
export const GOOGLE_AUTH_NO_CLASS_ACCESS = 'no_class_access';
export const GOOGLE_CLASSROOM_API_UNAVAILABLE = 'google_classroom_api_unavailable';
export const GOOGLE_CLASSROOM_CLASS_ARCHIVED = 'google_class_archived';
export const UNKNOWN_ERROR_CODE = 'unknown_error';

export const IMPORT_CLASSES_RETRY_RATE_MS = 5000;
export const IMPORT_CLASSES_RETRY_COUNT = 60;

export const STATUS_ARCHIVED = 'ARCHIVED';
