import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const baseApiUrl = '/api/activities/mpq/';
const multiPartQuestionUrlToPath = compile(`${baseApiUrl}:multiPartQuestionId/`);

export default {
  async createInstance({ featureTitle: multiPartQuestionTitle }) {
    const response = await axios.post(baseApiUrl, {
      title: multiPartQuestionTitle,
    });
    return response.data;
  },
  async fetchInstance(multiPartQuestionId) {
    const url = multiPartQuestionUrlToPath({ multiPartQuestionId });
    const response = await axios.get(url);
    return response.data;
  },
};
