/* eslint-disable no-param-reassign */
/**
 * Directive that makes DOM elements "clickable":
 *  - sets tab index
 *  - mouse cursor
 *  - listens to click, Space and Enter keys and fires a callback
 * Usage: v-kog-clickable:[callbackParameter]="callbackFunction"
 */

function turnClickable(el, binding) {
  const hasCallbackFunction = typeof binding.value === 'function';
  if (!hasCallbackFunction) {
    if (el.$cleanup) {
      el.$cleanup();
    }
    return;
  }
  el.style.cursor = 'pointer';
  el.tabIndex = 0;
  el.setAttribute('role', 'button');
  el.style.borderRadius = '4px';

  const clickHandler = clickEvent => {
    if (binding.modifiers.prevent) {
      clickEvent.preventDefault();
    }
    if (binding.modifiers.stop) {
      clickEvent.stopPropagation();
    }
    binding.value(binding.arg, clickEvent);
  };

  el.addEventListener('click', clickHandler);

  const keyDownHandler = keyEvent => {
    if (keyEvent.code === 'Enter' || keyEvent.code === 'Space') {
      keyEvent.preventDefault();
      binding.value(binding.arg, keyEvent);
    }
  };

  el.addEventListener('keydown', keyDownHandler);
  el.$cleanup = () => {
    el.style.cursor = 'inherit';
    el.removeAttribute('tabindex');
    el.removeAttribute('role');
    el.removeEventListener('click', clickHandler);
    el.removeEventListener('keydown', keyDownHandler);
  };
}

export default {
  beforeMount: turnClickable,
  updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      turnClickable(el, binding);
    }
  },
  unmounted(el) {
    if (el.$cleanup) {
      el.$cleanup();
    }
  },
};
