import { externalAxios } from 'sharedApp/vue-utils/kog-axios.ts';

export function postImageToS3SignedUrl(file, signedUrl, onProgress) {
  const config = {
    onUploadProgress: onProgress,
  };
  const formData = new FormData();
  Object.entries(signedUrl.fields).forEach(([field, value]) => {
    formData.append(field, value);
  });
  formData.append('file', file, file.name);

  return externalAxios.post(signedUrl.url, formData, config);
}

export const UPLOAD_STATUS = {
  DRAFT: 'Draft',
  UPLOADED: 'Uploaded',
  FAILED: 'Failed',
  DELETING: 'Deleting',
  DELETED: 'Deleted',
};
