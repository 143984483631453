import { BusinessValidationError } from 'schoolAdminApp/api/exceptions.js';
import { API_URLS } from 'schoolAdminApp/urls.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const addTeacher = async (schoolId, { firstName, lastName, email }) => {
  const data = {
    first_name: firstName,
    last_name: lastName,
    email,
  };

  const url = API_URLS.TEACHERS_IN_SCHOOL_URL(schoolId);
  try {
    const response = await axios.post(url, data);
    return response;
  } catch (err) {
    let errors = [];
    if (err.response) {
      errors = Object.values(err.response.data);
    } else {
      const fullName = `${firstName} ${lastName}`;
      const defaultErrorMessage = `${fullName} could not be added to the school`;
      errors.push(defaultErrorMessage);
    }
    throw new BusinessValidationError(errors);
  }
};

const parseTeacherDetailResponse = teacher => ({
  id: teacher.id,
  userId: teacher.user.id,
  email: teacher.user.email,
  firstName: teacher.user.first_name,
  lastName: teacher.user.last_name,
  dateJoined: new Date(teacher.user.date_joined),
  lastActive: teacher.user.last_active ? new Date(teacher.user.last_active) : null,
  roles: teacher.user.groups,
});

const fetchTeacher = async (schoolId, teacherId) => {
  const url = `${API_URLS.TEACHER_IN_SCHOOL_URL(schoolId, teacherId)}`;
  const response = await axios.get(url);

  return parseTeacherDetailResponse(response.data);
};

const parseTeacherListResponse = teacher => ({
  id: teacher.id,
  name: teacher.name,
  email: teacher.email,
  subjectClassCount: teacher.subject_class_count,
  active: teacher.active,
  lastActive: teacher.last_active,
  userId: teacher.user_id,
  avatarUrl: teacher.avatar_url,
  isAdmin: teacher.is_admin,
  isPrimaryContact: teacher.is_admin_primary_contact,
});

const fetchTeachers = async (schoolId, params, options = { formatResponse: true }) => {
  const url = API_URLS.TEACHERS_IN_SCHOOL_URL(schoolId);
  const response = await axios.get(url, {
    params: {
      ...params,
    },
  });

  if (options.formatResponse) {
    return response.data.map(teacher => parseTeacherListResponse(teacher));
  }

  return response.data;
};

const fetchTeachersSummary = async (schoolId, params) => {
  const url = `${API_URLS.TEACHERS_SUMMARY_URL(schoolId)}`;
  const response = await axios.get(url, {
    params: {
      ...params,
    },
  });
  return response.data;
};

const deleteTeacher = (schoolId, teacherId) => {
  const url = `${API_URLS.TEACHER_IN_SCHOOL_URL(schoolId, teacherId)}`;
  return axios.delete(url);
};

const assignSchoolAdministrator = (userId, schoolId, assign) => {
  const url = API_URLS.UPDATE_SCHOOL_ADMINISTRATOR_URL({ schoolId, userId });
  const payload = {
    is_admin: assign,
  };
  return axios.put(url, payload);
};

const assignSchoolPrimaryAdministrator = (userId, schoolId, assign) => {
  const url = API_URLS.UPDATE_SCHOOL_PRIMARY_ADMINISTRATOR_URL({ schoolId, userId });
  const payload = {
    is_primary_contact: assign,
  };
  return axios.put(url, payload);
};

export {
  addTeacher,
  fetchTeacher,
  fetchTeachers,
  fetchTeachersSummary,
  deleteTeacher,
  assignSchoolAdministrator,
  assignSchoolPrimaryAdministrator,
};
