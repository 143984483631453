import * as Sentry from '@sentry/vue';

import { fetchStudentsList } from '@apis/subject-class.js';

const defaultState = {
  studentsList: [],
};

const actions = {
  async fetchStudentsList({ commit }, subjectClassId) {
    try {
      const studentsListApiResponse = await fetchStudentsList(subjectClassId);
      commit('setStudentsList', studentsListApiResponse);
    } catch (error) {
      Sentry.captureMessage(`fetchStudentsList: ${error}`);
    }
  },
};

const mutations = {
  setStudentsList(state, studentsList) {
    state.studentsList = studentsList;
  },
};

export const SUBJECT_CLASS_STUDENTS_MODULE = 'subjectClassStudentsModule';
export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
};
