import { fetchAddableStudentsList, fetchStudentsList } from '@apis/subject-class.js';

import setClassStudentLevel from 'studyApp/api/class-students.js';

const namespaced = true;

const mutations = {
  updateClassStudentLevel(state, updatedStudent) {
    const index = state.addableClassStudents.findIndex(
      student => student.user.id === updatedStudent.student.user.id,
    );
    state.addableClassStudents[index].level = updatedStudent.level;
  },
  setAddableClassStudents(state, students) {
    state.addableClassStudents = students;
  },
  setSubjectClassStudentsList(state, studentsList) {
    state.subjectClassStudentsList = studentsList;
  },
};

const actions = {
  async fetchAddableClassStudents({ commit }, { subjectClassId, params, useCache = true }) {
    const students = await fetchAddableStudentsList(subjectClassId, params, useCache);
    commit('setAddableClassStudents', students);
  },

  async fetchSubjectClassStudentsList({ commit }, subjectClassId) {
    const subjectClassStudentsList = await fetchStudentsList(subjectClassId);
    commit('setSubjectClassStudentsList', subjectClassStudentsList);
  },

  async setClassStudentLevel({ commit }, { schoolId, subjectClassId, studentId, levelId }) {
    const updatedStudent = await setClassStudentLevel(schoolId, subjectClassId, studentId, levelId);
    commit('updateClassStudentLevel', updatedStudent);
  },
};

const state = {
  addableClassStudents: [],
  subjectClassStudentsList: [],
};

export default {
  namespaced,
  mutations,
  actions,
  state,
};
