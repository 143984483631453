import merge from 'lodash/merge.js';

import {
  fetchSubjectNodeIDsWithAvailableStrengthBattleQuestions,
  fetchSubjectNodeIDsWithAvailableStrengthQuestions,
  fetchSubjectNodeQuestionCompletionProgress,
  fetchSubjectNodesProgress,
  fetchSubjectNodesStrength,
  updateOrCreateNodeProgress,
} from 'studyApp/api/statistic.js';

const recursivelyCalculateNodeProgress = (
  { stats_by_subjectnode: statsBySubjectnode },
  nodeId,
  leafCompleted,
) => {
  const diffCompletedLeaves = leafCompleted ? 1 : -1;

  const currentNode = statsBySubjectnode[nodeId];

  const completedLeaves = currentNode.progress_stats.completed_leafs_quantity + diffCompletedLeaves;

  const totalLeavesQuantity = currentNode.progress_stats.total_leafs_quantity;

  const percent = Math.round((completedLeaves / totalLeavesQuantity) * 100);

  const isCompleted = completedLeaves === totalLeavesQuantity;

  const updatedProgressStats = {
    stats_by_subjectnode: {
      ...statsBySubjectnode,
      [nodeId]: {
        ...currentNode,
        is_completed: isCompleted,
        progress_stats: {
          ...currentNode.progress_stats,
          is_completed: isCompleted,
          percent,
          completed_leafs_quantity: completedLeaves,
        },
      },
    },
  };

  if (!currentNode.parent) {
    return updatedProgressStats;
  }

  return recursivelyCalculateNodeProgress(updatedProgressStats, currentNode.parent, leafCompleted);
};

export default {
  namespaced: true,
  state: {
    subjectNodesProgress: {},
    subjectNodesStrength: {},
    subjectNodesWithStrengthQuestions: [],
    subjectNodesWithStrengthBattleQuestions: [],
    subjectNodeQuestionCompletionProgress: [],
  },
  getters: {
    hasSubjectStats: (state, getters) => rootNodeId =>
      !!(
        getters.subjectStats(rootNodeId) &&
        getters.subjectStats(rootNodeId).progress_stats &&
        getters.subjectStats(rootNodeId).strength_stats
      ),
    subjectStats: (state, getters) => rootNodeId => getters.statsBySubjectNode[rootNodeId],
    progressStats(state) {
      return state.subjectNodesProgress.stats_by_subjectnode || {};
    },
    strengthStats(state) {
      return state.subjectNodesStrength.stats_by_subjectnode || {};
    },
    statsBySubjectNode(state) {
      const progressStats = state.subjectNodesProgress.stats_by_subjectnode || {};
      const strengthStats = state.subjectNodesStrength.stats_by_subjectnode || {};
      return merge(progressStats, strengthStats);
    },
    subjectNodeQuestionCompletionProgress(state) {
      return subjectNodeId =>
        (state.subjectNodeQuestionCompletionProgress || []).find(
          progress => progress.subjectNodeId === subjectNodeId,
        );
    },
  },
  mutations: {
    setSubjectNodesProgress(state, subjectNodesProgress) {
      state.subjectNodesProgress = subjectNodesProgress;
    },
    setSubjectNodesStrength(state, subjectNodesStrength) {
      state.subjectNodesStrength = subjectNodesStrength;
    },
    setSubjectNodesWithStrengthQuestions(state, nodes) {
      state.subjectNodesWithStrengthQuestions = nodes;
    },
    setSubjectNodeQuestionCompletionProgress(state, nodes) {
      state.subjectNodeQuestionCompletionProgress = nodes;
    },
    setSubjectNodesWithStrengthBattleQuestions(state, nodes) {
      state.subjectNodesWithStrengthBattleQuestions = nodes;
    },
  },
  actions: {
    async fetchSubjectNodeQuestionCompletionProgress({ commit }, subjectNodeIds) {
      function camelCase(progress) {
        return {
          subjectNodeId: progress.subjectnode_id,
          totalQuestions: progress.total_questions,
          correctlyAnsweredQuestions: progress.correctly_answered_questions,
          incorrectlyAnsweredQuestions: progress.incorrectly_answered_questions,
        };
      }
      const response = await fetchSubjectNodeQuestionCompletionProgress(subjectNodeIds);
      commit('setSubjectNodeQuestionCompletionProgress', response.map(camelCase));
    },
    async fetchSubjectNodesProgress({ commit }, subjectId) {
      commit('setSubjectNodesProgress', await fetchSubjectNodesProgress(subjectId));
    },
    async fetchSubjectNodesStrength({ commit }, subjectId) {
      commit('setSubjectNodesStrength', await fetchSubjectNodesStrength(subjectId));
    },
    async fetchSubjectNodesWithStrengthQuestions({ commit }, { subjectId, subjectNodeIds }) {
      const response = await fetchSubjectNodeIDsWithAvailableStrengthQuestions(
        subjectId,
        subjectNodeIds,
      );
      commit('setSubjectNodesWithStrengthQuestions', response);
    },
    async fetchSubjectNodesWithStrengthBattleQuestions({ commit }, { subjectId, subjectNodeIds }) {
      const response = await fetchSubjectNodeIDsWithAvailableStrengthBattleQuestions(
        subjectId,
        subjectNodeIds,
      );
      commit('setSubjectNodesWithStrengthBattleQuestions', response);
    },
    async updateOrCreateNodeProgress(
      { state, commit },
      { subjectNodeId, isCompleted, occasionId },
    ) {
      await updateOrCreateNodeProgress(subjectNodeId, isCompleted, occasionId);
      commit(
        'setSubjectNodesProgress',
        recursivelyCalculateNodeProgress(state.subjectNodesProgress, subjectNodeId, isCompleted),
      );
    },
    emulateSubjectNodesStrength({ state, commit }) {
      const nodeProgressStats = state.subjectNodesProgress.stats_by_subjectnode;
      const emulatedStats = Object.keys(nodeProgressStats).reduce((result, nodeId) => {
        const { parent } = nodeProgressStats[nodeId];
        // eslint-disable-next-line no-param-reassign
        result[nodeId] = {
          parent,
          strength_stats: {
            number_correct_questions: 0,
            number_questions: 0,
            percent: 0,
          },
        };
        return result;
      }, {});
      commit('setSubjectNodesStrength', {
        stats_by_subjectnode: emulatedStats,
      });
    },
  },
};
