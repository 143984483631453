import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const API_PATHS = {
  'subject-exam-style-questions': compile('/api/study/subjects/:subjectId/exam_style_questions/'),
};

export default async function fetchQuestionMappings(subjectId) {
  const path = API_PATHS['subject-exam-style-questions']({ subjectId });
  const response = await axios.get(path);
  return response.data;
}
